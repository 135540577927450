import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Layout from '../../product/components/templates/Layout'
import { Button } from '@material-ui/core'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
})

function createData(title, action) {
    return { title, action }
}

const rows = [
    createData(
        'HowItWorks',
        <Button color="primary" variant="outlined">
            Edit
        </Button>,
    ),
    createData(
        'Terms',
        <Button color="primary" variant="outlined">
            Edit
        </Button>,
    ),
    createData(
        'Privacy Policy',
        <Button color="primary" variant="outlined">
            Edit
        </Button>,
    ),
    createData(
        'Support',
        <Button color="primary" variant="outlined">
            Edit
        </Button>,
    ),
    createData(
        'FAQ',
        <Button color="primary" variant="outlined">
            Edit
        </Button>,
    ),
    createData(
        'About',
        <Button color="primary" variant="outlined">
            Edit
        </Button>,
    ),
]

const StaticPages = () => {
    const classes = useStyles()

    return (
        <Layout>
            <div className="dashboard staticPages">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Page Title</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.title}>
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">{row.action}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </Layout>
    )
}
export default StaticPages
