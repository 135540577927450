import {
    UPLOADED_IMAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_MAIN_DASHBOARD_DATA,
    BID_HISTORY_VALUE,
    CLEAR_BID_HISTORY,
    TTW_BID_HISTORY_VALUE,
    TTW_CLEAR_BID_HISTORY,
    GET_ALL_COUNTRIES,
    GET_ALL_STATES,
    GET_ALL_CITIES,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case UPLOADED_IMAGE:
            return {
                ...state,
                uploadedFiles: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        case GET_MAIN_DASHBOARD_DATA:
            return {
                ...state,
                mainDashboardData: action.payload.mainDashboardData,
            }
        case BID_HISTORY_VALUE:
            return {
                ...state,
                bidHistoryValue: action.payload,
            }
        case CLEAR_BID_HISTORY:
            return {
                ...state,
                bidHistoryValue: 0,
            }
        case TTW_BID_HISTORY_VALUE:
            return {
                ...state,
                ttwBidHistoryValue: action.payload,
            }
        case TTW_CLEAR_BID_HISTORY:
            return {
                ...state,
                ttwBidHistoryValue: 0,
            }
        case GET_ALL_COUNTRIES:
            return {
                ...state,
                allCountries: action.payload.records,
            }
        case GET_ALL_STATES:
            return {
                ...state,
                allStates: action.payload.records,
            }
        case GET_ALL_CITIES:
            return {
                ...state,
                allCities: action.payload.records,
            }
        default:
            return state
    }
}
