import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import AuctionContext from '../../product/context/auction/auctionContext'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDropzone } from 'react-dropzone'
import CustomTable from '../../product/components/molecules/CustomTable'
import CreditContext from '../../product/context/credit/creditContext'
import AlertContext from '../../product/context/alert/alertContext'

const WalletManage = (props) => {
    const creditContext = useContext(CreditContext)
    const alertContext = useContext(AlertContext)
    const { setAlert } = alertContext
    const {
        addCreditBalance,
        search_allusercredit,
        getCreditHistory,
        responseStatus,
    } = creditContext

    const [tableBody, setTableBody] = useState([])
    const [isLoading, setIsLoading] = useState(true)

    const data = props.data
    const toggleFullScreenPopup = props.function
    const validationArrayCredit = Yup.object({
        amount: Yup.number().min(0, 'Not valid!').required('Required!'),
        credit_notes: Yup.string().required('Required!'),
    })

    const validationArrayDebit = Yup.object({
        amount: Yup.number().min(0, 'Not valid!').required('Required!'),
        credit_notes: Yup.string().required('Required!'),
    })
    /*const resetDebitForm = () => {
        formikData.resetForm({
            amount: 0,
            user_id: 0,
            remove_credit: 0,
            balance_check: 0,
            credit_notes: '',
            creditor_id: 0,
            credit_type: 'admin',
        })
    }
    const resetCreditForm = () => {
        formikData.resetForm({
            amount: 0,
            user_id: 0,
            remove_credit: 1,
            balance_check: 1,
            credit_notes: '',
            creditor_id: 0,
            credit_type: 'admin',
        })
    }*/
    const initalValuesCreditAndDebit = (removeCredit, balanceCheck) => {
        return {
            amount: 0,
            user_id: 0,
            remove_credit: removeCredit,
            balance_check: balanceCheck,
            credit_notes: '',
            creditor_id: 0,
            credit_type: 'admin',
        }
    }
    const formikCredits = useFormik({
        initialValues: initalValuesCreditAndDebit(0, 0),
        validateOnBlur: false,
        validationSchema: validationArrayCredit,
        onSubmit: (values) => {
            setIsLoading(true)
            addCreditBalance(values)
            formikData.handleSubmit()
        },
    })

    const formikDebits = useFormik({
        initialValues: initalValuesCreditAndDebit(1, 1),
        validateOnBlur: false,
        validationSchema: validationArrayDebit,
        onSubmit: (values, { resetForm }) => {
            setIsLoading(true)
            console.log(values)
            addCreditBalance(values)
            formikData.handleSubmit()
        },
    })

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            user_id: 0,
            filters: {},
        },
        onSubmit: (values) => {
            getCreditHistory(values)
        },
    })

    useEffect(() => {
        if (responseStatus?.from === 'addCreditBalance') {
            setAlert(responseStatus.message)
            formikCredits.resetForm()
            formikDebits.resetForm()
        }
    }, [responseStatus])

    useEffect(() => {
        if (data.status === 'edit') {
            formikData.values.user_id = data.id
            formikCredits.values.user_id = data.id
            formikDebits.values.user_id = data.id
            // formikData.values.user_id = data.secondaryID
            getCreditHistory(formikData.values)
        }
    }, [data.id])

    useEffect(() => {
        setIsLoading(false)
        if (search_allusercredit) {
            setTableBody(search_allusercredit.records.length ? search_allusercredit.records : [])
        }
    }, [search_allusercredit])

    const addCredits = [
        {
            label: 'Add credits',
            type: 'number',
            placeholder: 'Enter amount',
            class: '',
            name: 'amount',
            formik: formikCredits,
        },
        {
            label: 'Notes',
            type: 'textarea',
            placeholder: 'Add notes',
            class: '',
            name: 'credit_notes',
            formik: formikCredits,
        },
    ]

    const removeCredits = [
        {
            label: 'Remove credits',
            type: 'number',
            placeholder: 'Enter amount',
            class: '',
            name: 'amount',
            formik: formikDebits,
        },
        {
            label: 'Notes',
            type: 'textarea',
            placeholder: 'Add notes',
            class: '',
            name: 'credit_notes',
            formik: formikDebits,
        },
    ]

    const tableValues = [
        {
            field: 'date_added',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'credit_notes',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Description',
        },
        {
            field: 'amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
        {
            field: 'remove_credit',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'remove_credit',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Credit',
                },
                false: {
                    type: 'text',
                    value: 'Debit',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        },
    ]

    const tableActions = []

    return (
        <FullScreenPopup
            modaltitle={`Manage Credits`}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="wallet">
                <div className="fspBody">
                    <div className="walletManageCnt mb-4">
                        <form onSubmit={formikCredits.handleSubmit} autoComplete="nofill">
                            <div className="walletManageCredits">
                                <h4>Add credits to this user</h4>
                                {Object.values(mapData(addCredits))}
                                <SecondaryButton type="submit" label="Add Credits" />
                            </div>
                        </form>
                        <form onSubmit={formikDebits.handleSubmit} autoComplete="nofill">
                            <div className="walletManageCredits">
                                <h4>Remove credits from this user</h4>
                                {Object.values(mapData(removeCredits))}
                                <SecondaryButton type="submit" label="Remove Credits" />
                            </div>
                        </form>
                    </div>
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <CustomTable
                            formik={formikData.values}
                            tableBody={tableBody}
                            tableValues={tableValues}
                            tableActions={tableActions}
                            allData={search_allusercredit}
                            changeTableFunction={getCreditHistory}
                        />
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default WalletManage
