import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../FullScreenPopup'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import CustomTable from '../../molecules/CustomTable'
import ProductContext from '../../../context/product/productContext'
import CommonContext from '../../../context/common/commonContext'
import ReportContext from '../../../context/report/reportContext'

const BidHistory = () => {
    const productContext = useContext(ProductContext)
    const {
        search_allbidhistory,
        getAllBidHistory,
        clearResponse: clearAllBidHistory,
    } = productContext

    const commonContext = useContext(CommonContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext

    const { getAllExportDownload } = useContext(ReportContext)

    const [tableBody, setTableBody] = useState([])
    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            get_proxy: false,
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
            },
        },
        onSubmit: (values) => {
            getCreditHistory(values)
        },
    })

    useEffect(() => {
        if (bidHistoryValue) {
            if (bidHistoryValue.id) {
                formikData.values.filters.project_id.value = bidHistoryValue.id
                formikData.values.get_proxy = bidHistoryValue.proxy
            } else {
                formikData.values.filters.project_id.value = bidHistoryValue
            }
            setData({ popup: true, id: bidHistoryValue })
            getAllBidHistory(formikData.values)
            clearBidHistoryValue()
            console.log(bidHistoryValue, 'bidHistoryValue')
        }
        return () => {
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (search_allbidhistory) {
            setTableBody(search_allbidhistory.records.length ? search_allbidhistory.records : [])
        }
    }, [search_allbidhistory])

    const tableValues = [
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
    ]

    if (formikData.values.get_proxy) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Max',
                },
                false: {
                    type: 'value',
                    value: 'bidText',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        })
    }
    tableValues.push({
        field: 'proposed_amount',
        type: 'price',
        sort: true,
        firstChild: false,
        disablePadding: false,
        label: 'Amount',
    })

    const tableActions = []

    return (
        <FullScreenPopup
            modaltitle={`Bid History`}
            open={data.popup}
            handleClose={() => setData({ ...data, popup: false })}
        >
            <div className="wallet">
                <Button
                    onClick={() =>
                        getAllExportDownload({
                            action: 'export',
                            order: 'asc',
                            orderby: '',
                            search: `b.project_id=${formikData.values.filters.project_id.value}`,
                            report: `bid_history`,
                        })
                    }
                >
                    {' '}
                    <span className="material-icons">get_app</span>Export All Data
                </Button>
                <div className="fspBody">
                    <CustomTable
                        formik={formikData.values}
                        tableBody={tableBody}
                        tableValues={tableValues}
                        tableActions={tableActions}
                        allData={search_allbidhistory}
                        changeTableFunction={getAllBidHistory}
                    />
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default BidHistory
