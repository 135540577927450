import React, { useReducer } from 'react'
import CommonContext from './commonContext'
import CommonReducer from './commonReducer'
import { apiCall } from '../../../product/common/api'
import { response } from '../../../product/context/common'

import { GET_PREVIOUS_DATA, RESPONSE_STATUS, CLEAR_RESPONSE } from './commonTypes'

const CommonState = (props) => {
    const initialState = {
        responseStatus: null,
        previous_data: {
            records: [],
            totalRecords: 0,
        },
    }

    const [state, dispatch] = useReducer(CommonReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getPreviousData = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getPreviousData', formData, '', 'user/common'),
            ])
            dispatch({
                type: GET_PREVIOUS_DATA,
                payload: {
                    records: res.data.data.responseData.records.length
                        ? res.data.data.responseData.records
                        : [],
                    totalRecords: res.data.data.responseData.totalRecords,
                },
            })
        } catch (err) {
            resp.commonErrorResponse('saved_search')
        }
    }

    const insertEntry = async (formData) => {
        const from = 'insertEntry'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'user/common')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const updateEntry = async (formData) => {
        const from = 'updateEntry'
        try {
            const [res] = await Promise.all([apiCall('post', from, formData, '', 'user/common')])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearPreviousData = async () => {
        dispatch({
            type: GET_PREVIOUS_DATA,
            payload: {
                records: [],
                totalRecords: 0,
            },
        })
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <CommonContext.Provider
            value={{
                responseStatus: state.responseStatus,
                previous_data: state.previous_data,
                updateEntry,
                insertEntry,
                getPreviousData,
                clearPreviousData,
                clearResponse,
            }}
        >
            {props.children}
        </CommonContext.Provider>
    )
}

export default CommonState
