import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import '../Dashboard/Dashboard.css'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import { Button } from '@material-ui/core'
import CustomDialog from '../../product/components/organisms/Dialog'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import UserContext from '../../product/context/user/userContext'

import LiveLots from './LiveLots'

const LiveAuction = (props) => {
    const alertContext = useContext(AlertContext)
    const auctionContext = useContext(AuctionContext)
    const userContext = useContext(UserContext)

    const { getAllBiddingUsers } = userContext

    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)

    const { setAlert } = alertContext
    const {
        all_auctions,
        getAllAuctions,
        changeAuctionStatus,
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext

    let action = props.match.params.action

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: 'open',
                    type: 'in',
                    field: 'ac.market_status',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'ac.title',
                },
                fromdate: {
                    value: '',
                    type: 'like',
                    field: 'ac.date_closed',
                },
                todate: {
                    value: '',
                    type: 'like',
                    field: 'ac.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllAuctions(values)
        },
    })
    useEffect(() => {
        if (all_auctions) {
            setTableBody(all_auctions.records.length ? all_auctions.records : [])
            setIsLoading(false)
        }
    }, [all_auctions])

    const activeBidders = useFormik({
        initialValues: {
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: 'active',
                    type: 'in',
                    field: 'u.status',
                },
            },
        },
    })

    useEffect(() => {
        getAllBiddingUsers(activeBidders.values)
    }, [])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.action = action
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        getAllAuctions(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])
    console.log(all_auctions, 'all_auctions')
    const onClickEdit = (id, type) => {
        console.log('onClickEdit', id, type)
        toggleFullScreenPopup(true, 'edit', id)
    }

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            auction_id: '',
        },
        onSubmit: (values) => {
            if (values.action === 'removed') {
                setIsLoading(true)
                changeAuctionStatus(values)
            }
        },
    })

    const [changeStatus, setChangeStatus] = React.useState(false)

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const onSelectMultiProducts = (data, action) => {
        console.log('data', data)
        console.log('action', action)
        formikSelection.values.action = action
        formikSelection.values.auction_id = data
        if (action === 'removed') {
            setChangeStatus(true)
        }
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionchange'
            ) {
                setChangeStatus(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    const tableValues = [
        {
            field: 'avatar',
            type: 'imagewithurl',
            folder: 'auction',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },
        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'date_added',
            sortField: 'ac.date_added',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Start date',
        },
        {
            field: 'date_closed',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'End date',
        },
        {
            field: 'projectcount',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'No of items',
        },
        {
            field: 'market_status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'view',
            buttonType: 'visibility',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'View auction',
        },
    ]

    const tableActions = [
        {
            label: 'Delete',
            icon: <span className="material-icons">delete</span>,
            onclick: onSelectMultiProducts,
            type: 'removed',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Auction Lot Title',
                placeholder: 'Enter auction lot title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Closed From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Closed To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionAction'
            ) {
                toggleFullScreenPopup(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    return (
        <Layout>
            <div className="dashboard auctionLots">
                <CustomSearch searchInfo={searchInfo} formik={formik} />

                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_auctions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllAuctions}
                />
            </div>
            <LiveLots data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change auction status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default LiveAuction
