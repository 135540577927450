import React, { useContext, useState, useEffect, useRef } from 'react'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CustomDialog from '../../product/components/organisms/Dialog'

import * as Yup from 'yup'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SettingContext from '../../product/context/setting/settingContext'
import AlertContext from '../../product/context/alert/alertContext'
import UserCommonContext from '../../product/context/userCommon/commonContext'

const BusinessCategory = () => {
    const settingcontext = useContext(SettingContext)
    const usercommoncontext = useContext(UserCommonContext)
    const alertContext = useContext(AlertContext)

    const { setAlert } = alertContext
    const { allCategory, getGlobalVariable } = useContext(CustomCommonContext)

    const [isLoading, setIsLoading] = useState(true)
    const {
        searchTableAction,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseCommuncation,
    } = settingcontext

    const { getPreviousData, clearPreviousData, previous_data } = usercommoncontext

    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            active: 0,
            id: 0,
            table: 'category',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (
                dataValue.action === 'active' ||
                dataValue.action === 'deactive' ||
                dataValue.action === 'delete'
            ) {
                dataValue.active =
                    dataValue.action === 'delete' ? 2 : dataValue.action === 'active' ? 1 : 0
                delete dataValue.action
                searchTableAction(values)
            }
        },
    })
    const validationArray = Yup.object({
        description: Yup.string().required('Required!'),
        level_id: Yup.number(),
        avatar: Yup.array().when('level_id', {
            is: (end) => !end,
            then: Yup.array().required('Required!'),
        }),
    })
    const formikView = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            searchterm: '',
            filters: {
                category_id: {
                    value: [],
                    type: 'array',
                    field: 'level_id',
                },
            },
        },
        onSubmit: (values) => {
            let filteredValues = allCategory
            if (values.filters.category_id.value.length) {
                filteredValues = filteredValues.filter(
                    (filter) => values.filters.category_id.value.indexOf(filter.level_id) !== -1,
                )
            }
            if (values.searchterm && values.searchterm != '') {
                filteredValues = filteredValues.filter(
                    (filter) =>
                        filter.description
                            .toLowerCase()
                            .indexOf(values.searchterm.toLowerCase()) !== -1,
                )
            }
            setTableBody(filteredValues.length ? filteredValues : [])
            formikView.values.limit = filteredValues.length
        },
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            description: '',
            level: 0,
            level_id: '',
            avatar: [],
            table: 'category',
            active: 1,
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (!values.level_id) {
                values.level_id = 0
            } else {
                values.level = 1
            }
            searchTableAction(values)
        },
    })

    const changePagination = () => {}

    useEffect(() => {
        if (allCategory) {
            if (allCategory.length) {
                allCategory.map((cat) => {
                    if (cat.level) {
                        let categoryMain = allCategory.filter(
                            (state) => parseInt(state.id, 10) === parseInt(cat.level_id, 10),
                        )
                        if (categoryMain.length) {
                            cat.mainCategory = categoryMain[0].description
                        } else {
                            cat.mainCategory = ''
                        }
                    } else {
                        cat.mainCategory = ''
                    }
                    return cat
                })
            }

            setTableBody(allCategory.length ? allCategory : [])
            formikView.values.limit = allCategory.length
        }
        setIsLoading(false)
    }, [allCategory])

    useEffect(() => {
        if (responseStatusSetting) {
            if (
                responseStatusSetting.from === 'CommunicationSearchTable' &&
                responseStatusSetting.status === 'success'
            ) {
                setChangeStatus(false)
                toggleFullScreenPopup(false)
                setTableBody([])
                getGlobalVariable()
            }
        }
    }, [responseStatusSetting])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    useEffect(() => {
        console.log('manage', manage)
        if (manage.status === 'edit') {
            formik.setFieldValue('id', manage.id)
            const template = allCategory.filter((cat) => cat.id === manage.id)
            formik.setFieldValue('description', template[0].description)
            formik.setFieldValue('level', template[0].level)
            formik.setFieldValue(
                'avatar',
                template[0].avatar ? template[0].avatar.toString().split(',') : [],
            )
            formik.setFieldValue('level_id', template[0].level_id)
            formik.setFieldValue('active', template[0].active)
        } else {
            resetFrom()
        }
    }, [manage])

    const resetFrom = () => {
        console.log('resetForm')
        formik.setFieldValue('id', 0)
        formik.setFieldValue('description', '')
        formik.setFieldValue('level', '')
        formik.setFieldValue('level_id', '')
        formik.setFieldValue('avatar', [])
        formik.setFieldValue('table', 'category')
        formik.handleReset()
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'description',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Category name',
        },
        {
            field: 'level_id',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'level',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Sub Category',
                },
                false: {
                    type: 'text',
                    value: 'Category',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        },
        {
            field: 'mainCategory',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Parent Category',
        },
        {
            field: 'active',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'active',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Active',
                },
                false: {
                    type: 'text',
                    value: 'Deactive',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit category',
        },
    ]
    const details = [
        {
            label: 'Category',
            placeholder: '-- Select Parent --',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter((maincat) => maincat.level === 0)
                .map((maincat) => {
                    let categoryChanged = {}
                    categoryChanged.show = maincat.description
                    categoryChanged.value = maincat.id
                    return categoryChanged
                }),
            name: 'level_id',
            formik: formik,
        },
        {
            label: 'Category name *',
            placeholder: 'Enter the category name',
            type: 'textarea',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'avatar',
            icon: 'publish',
            titleText: 'Upload Avatar',
            innerText: 'Recommended dimensions: 1400px * 440px',
            class: 'col-12',
            folder: 'category',
            multiple: false,
            accept: 'image/*',
        },
        {
            label: 'Active',
            placeholder: 'Make Active',
            type: 'switch',
            class: 'col-6',
            name: 'active',
            formik: formik,
        },
    ]

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.id = data
        if (action === 'active' || action === 'deactive' || action === 'delete') {
            if (action === 'delete') {
                let selectedCategory = allCategory
                let categorySelected = []
                let subCategorySelected = []
                selectedCategory
                    ?.filter((value) => formikSelection.values.id.includes(parseInt(value.id, 10)))
                    .map((buscat) => {
                        if (buscat.level) {
                            subCategorySelected.push(buscat.id)
                        } else {
                            categorySelected.push(buscat.id)
                        }
                        return buscat
                    })
                getPreviousData({
                    page: 1,
                    limit: 20,
                    order: '',
                    orderby: '',
                    primaryTable: 'projects',
                    customTable: 'custom_projects',
                    similars: {
                        category: {
                            value: categorySelected,
                            type: 'array',
                            field: 'it.categoryTypeId',
                        },
                        subCategory: {
                            value: subCategorySelected,
                            type: 'array',
                            field: 'it.subCategoryTypeId',
                        },
                    },
                })
            } else if (action === 'deactive') {
                setChangeStatus(true)
            } else {
                setChangeStatus(true)
            }
        }
    }

    useEffect(() => {
        if (previous_data) {
            if (previous_data.records.length) {
                setAlert('Previous records exists!', 'error')
                formikSelection.values.action = ''
                formikSelection.values.id = 0
                clearPreviousData()
            } else if (formikSelection.values.action) {
                setChangeStatus(true)
            }
        }
    }, [previous_data])

    const tableActions = []
    tableActions.push(
        {
            label: 'Move to Active',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        },
        {
            label: 'Move to Deactive',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactive',
        },
        {
            label: 'Delete Permanently',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'delete',
        },
    )

    const searchInfo = [
        {
            label: 'Category name',
            placeholder: 'Enter category name',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formikView,
        },
        {
            label: 'Category',
            placeholder: 'Select category',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter((maincat) => maincat.level === 0)
                .map((cat) => {
                    let userChanged = {}
                    userChanged.show = cat.description
                    userChanged.value = cat.id
                    return userChanged
                }),
            defaultOptionValue: 'Select a Business Category',
            name: 'category_id',
            formik: formik,
            filter: true,
        },
    ]

    return (
        <Layout>
            <div className="dashboard staticPages">
                <CustomSearch searchInfo={searchInfo} formik={formikView} />
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Category
                    </Button>
                </div>

                <CustomTable
                    formik={formikView.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={{
                        records: tableBody,
                        setDisp: `0-${formikView.values.limit}`,
                        totalRecords: formikView.values.limit,
                    }}
                    changeTableFunction={changePagination}
                />
            </div>
            <FullScreenPopup
                modaltitle={manage.status === 'new' ? 'Add New Category' : 'Edit Category'}
                open={manage.popup}
                handleClose={() => toggleFullScreenPopup(false, 'new', 0)}
            >
                <div className="dashboard addNewListing mt-4">
                    <div className="fspBody">
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(details))}</div>
                            <div className="actionButton d-flex justify-content-center align-items-center">
                                <SecondaryButton
                                    label="Reset"
                                    type="reset"
                                    onClick={() => resetFrom()}
                                />
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </FullScreenPopup>

            <CustomDialog title={'Change status'} open={changeStatus} function={changeChangeStatus}>
                <h5>
                    {formikSelection.values.action === 'delete'
                        ? 'Are you sure you want to delete'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}
export default BusinessCategory
