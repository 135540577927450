import { Button, Fade, Tooltip } from '@material-ui/core'
import React, { useState, useRef, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'

import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import './ProductCard.css'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import Timer from '../../../../product/common/timer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'

const Grid = (props) => {
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    const { setBidHistoryValue } = commonContext
    const { user, isAuthenticated } = authContext

    const [product, setProduct] = useState()
    const history = useHistory()
    useEffect(() => {
        setProduct(props.data)
        console.log('props.data', props.data)
    }, [props.data])

    return (
        <Fade in={true} timeout={600}>
            <div className="productCardGrid">
                {product ? (
                    <>
                        <div className="pcgImg">
                            <img
                                src={`${global.site_url}/uploads/product/${product.avatar}`}
                                alt={product.title}
                            />

                            {product.market_status === 'open' ? (
                                <div className="gridTimer d-flex justify-content-center align-items-center">
                                    <span className="material-icons">timer</span>
                                    <p>
                                        {product.date_closed ? (
                                            <Timer
                                                date_added={product.date_added}
                                                date_closed={product.date_closed}
                                                withText={1}
                                                endText={t('time_left') + ':'}
                                                startText={t('starts_in') + ':'}
                                            ></Timer>
                                        ) : null}
                                    </p>
                                </div>
                            ) : (
                                <div className="gridTimer d-flex justify-content-center align-items-center">
                                    <h6>{t('closed_on')}:</h6>
                                    <p className="d-flex align-items-center">
                                        {dateFormatFront(product.date_closed)}
                                    </p>
                                </div>
                            )}
                        </div>
                        <h2 className="gridProdTitle" onClick={props.drawerHandler}>
                            {product.title}
                        </h2>
                        <div className="gridLotDetails d-flex justify-content-start align-items-center">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.desc_proc,
                                }}
                            ></p>
                        </div>
                        {product.market_status === 'open' ? (
                            <>
                                <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                    <h6>{product.cbidtext}: </h6>
                                    {product.auction ? (
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>{currencyFormat(product.wprice)}</span>
                                            <span className="gridDivider">|</span>
                                            <span
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.bid_count} {t('bids')}
                                            </span>
                                        </p>
                                    ) : (
                                        <p className="notAvailable">
                                            <span>{t('not_available')}</span>
                                        </p>
                                    )}
                                </div>

                                {/* <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                    <h6>{t('buy_now_price')}:</h6>
                                    {product.buynow ? (
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>{product.bprice} AED</span>
                                        </p>
                                    ) : (
                                        <p className="notAvailable">
                                            <span>{t('not_available')}</span>
                                        </p>
                                    )}
                                </div> */}
                            </>
                        ) : (
                            <>
                                <div className="gridBidInfo d-flex justify-content-between align-items-center">
                                    <h6>{product.cbidtext}: </h6>
                                    <p className="d-flex justify-content-between align-items-center">
                                        <span>{currencyFormat(product.buynowamount)}</span>
                                        <span className="gridDivider">|</span>
                                        <span
                                            className="cursorDecoy"
                                            onClick={() => setBidHistoryValue(product.id)}
                                        >
                                            {product.bid_count} {t('bids')}
                                        </span>
                                    </p>
                                </div>
                            </>
                        )}
                        {props.from === 'dashboard' &&
                        (props.action === 'won' || props.action === 'buynow') &&
                        product.buynowed &&
                        product.buynowed === user.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={t('view_invoice')}
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            label={t('make_payment')}
                                            btnSize="small"
                                            onClick={() => handleRedirectInternal(history, 'cart')}
                                        />
                                    </>
                                )}
                            </div>
                        ) : product.market_status === 'open' && !product.future_active ? (
                            <>
                                <div className="gridBidBox">
                                    {product.auction ? (
                                        <Bidding data={product} type="hard" />
                                    ) : null}
                                </div>
                            </>
                        ) : product.buynowed && product.buynowed === user.id ? (
                            <div className="actionAfterWin">
                                {parseInt(product.buynowpaid, 10) === 1 ? (
                                    <>
                                        <SecondaryButton
                                            label={t('view_invoice')}
                                            btnSize="small"
                                            onClick={() =>
                                                handleRedirectInternal(
                                                    history,
                                                    `invoice/${product.common_invoice}`,
                                                )
                                            }
                                        />
                                    </>
                                ) : (
                                    <>
                                        <PrimaryButton
                                            label={t('make_payment')}
                                            btnSize="small"
                                            onClick={() => handleRedirectInternal(history, 'cart')}
                                        />
                                    </>
                                )}
                            </div>
                        ) : null}
                        <div className="moreInfo text-center mt-2">
                            <Button onClick={props.drawerHandler}>
                                {t('view_more_info')}{' '}
                                <span
                                    className={`material-icons ${
                                        document.body.dir === 'rtl' && 'rtl'
                                    }`}
                                >
                                    arrow_right_alt
                                </span>
                            </Button>
                        </div>
                    </>
                ) : null}
            </div>
        </Fade>
    )
}

export default Grid
