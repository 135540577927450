import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SettingContext from '../../product/context/setting/settingContext'

const BidIncrementManage = (props) => {
    const settingContext = useContext(SettingContext)
    const [isLoading, setIsLoading] = useState(false)
    const { single_bid_increment, getSingleBidIncrement, bidIncrementAction } = settingContext

    const [product, setProduct] = useState(null)

    const data = props.data
    const toggleFullScreenPopup = props.function

    let validationArray
    // Add New
    if (data.status === 'new') {
        validationArray = Yup.object({
            increment: Yup.number().integer().min(1).required('Required!'),
            from_amount: Yup.number().integer().min(0).required('Required!'),
            to_amount: Yup.number()
                .integer()
                .moreThan(Yup.ref('from_amount'), 'To Amount must be more than From Amount')
                .max(10000000000)
                .required('Required!'),
        })
    }
    // Update
    else {
        validationArray = Yup.object({
            increment: Yup.number().integer().min(1).required('Required!'),
        })
    }
    const formik = useFormik({
        initialValues: {
            id: 0,
            from_amount: 0,
            to_amount: '',
            increment: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            bidIncrementAction(values)
        },
    })
    useEffect(() => {
        setIsLoading(true)
    }, [])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleBidIncrement(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_bid_increment.record && data.status === 'edit') {
            const template = single_bid_increment.record
            template.variable = `From $${single_bid_increment.record.from_amount} To $${single_bid_increment.record.to_amount}`
            setProduct(template)
            formik.values.id = template.id
            formik.values.increment = template.increment
        } else {
            resetFrom()
        }
        setIsLoading(false)
    }, [single_bid_increment])

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.from_amount = ''
        formik.values.to_amount = ''
        formik.values.increment = ''
        setProduct(null)
        formik.handleReset()
        setIsLoading(true)
    }

    const inputVariables = []

    if (data.status === 'new') {
        inputVariables.push(
            {
                label: 'From Amount *',
                placeholder: 'Enter From Amount',
                class: 'col-12 mb-4',
                type: 'number',
                shrink: true,
                name: 'from_amount',
                formik: formik,
                min: 0,
            },
            {
                label: 'To Amount *',
                placeholder: 'Enter To Amount',
                class: 'col-12 mb-4',
                type: 'number',
                shrink: true,
                name: 'to_amount',
                formik: formik,
            },
        )
    }
    inputVariables.push({
        label: 'Increment Value *',
        placeholder: `Enter Bid Increment Value`,
        class: 'col-12',
        name: 'increment',
        type: 'number',
        formik: formik,
        min: 1,
    })

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD  BID INCREMNET'
                    : `EDIT BID INCREMNET: ${product && product.variable}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">{Object.values(mapData(inputVariables))}</div>
                        {isLoading && <div>Loading...</div>}
                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default BidIncrementManage
