import React, { useState, useContext, useEffect } from 'react'
import Header from '../../molecules/Header'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import './Layout.css'
import { SIDE_PANEL } from '../../../../utils/UI'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Button, Collapse } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import AuthContext from '../../../../product/context/auth/authContext'
import { useMediaQuery } from 'react-responsive'

const drawerWidth = 240

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },
    content: {
        flexGrow: 1,
        padding: '0px 40px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        padding: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}))

const Layout = (props) => {
    const classes = useStyles()

    const location = useLocation()
    const [expanded, setExpanded] = useState(false)
    let history = useHistory()
    const authContext = useContext(AuthContext)
    const { user } = authContext
    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    })
    const [openDrawer, setOpenDrawer] = useState(true)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false)
    }

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            setExpanded(location.pathname.split('/')[1])
        }
        setOpenDrawer(isMobile ? false : true)
    }, [location.pathname, isMobile])

    return (
        <div className="layout">
            <Header />
            <div className={(classes.root, 'drawerMain')}>
                {openDrawer ? (
                    <span className="sliderBackdrop" onClick={handleDrawerClose}></span>
                ) : null}
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={openDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {user &&
                            user.emp_permissions &&
                            SIDE_PANEL.map((data, index) => (
                                <>
                                    {user.emp_permissions.indexOf(data.path.toString()) !== -1 && (
                                        <>
                                            {data.type === 'single' ? (
                                                <ListItem key={`${data.index}_parent`} button>
                                                    <NavLink
                                                        to={data.path}
                                                        exact
                                                        activeClassName="active"
                                                    >
                                                        <ListItemText primary={data.label} />
                                                    </NavLink>
                                                </ListItem>
                                            ) : (
                                                <Accordion
                                                    expanded={expanded === `${data.path}`}
                                                    onChange={handleChange(`${data.path}`)}
                                                >
                                                    <ListItem button>
                                                        <AccordionSummary
                                                            className={`${
                                                                location.pathname.split('/')[1] ===
                                                                    data.path && 'active'
                                                            }`}
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={`panel${index}bh-content`}
                                                            id={`panel${index}bh-header`}
                                                        >
                                                            {data.label}
                                                        </AccordionSummary>
                                                    </ListItem>
                                                    <AccordionDetails>
                                                        <div className="subLinks">
                                                            {data.subLinks.map((d, i) => (
                                                                <ListItem key={`${i}_child`} button>
                                                                    <NavLink
                                                                        to={d.path}
                                                                        exact
                                                                        activeClassName="active"
                                                                    >
                                                                        <ListItemText
                                                                            primary={d.label}
                                                                        />
                                                                    </NavLink>
                                                                </ListItem>
                                                            ))}
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            )}
                                        </>
                                    )}
                                </>
                            ))}
                    </List>
                </Drawer>
                <div className="layoutMain">
                    <main
                        className={clsx(classes.content, 'drawerContent', {
                            [classes.contentShift]: openDrawer,
                        })}
                    >
                        <div className="openDrawer">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(classes.menuButton, openDrawer && classes.hide)}
                            >
                                <MenuIcon />
                            </IconButton>
                        </div>
                        <div className={`layoutBody ${!openDrawer && 'reducePadding'}`}>
                            {SIDE_PANEL.map((data) => (
                                <>
                                    {location.pathname === data.path && (
                                        <div className="layoutTitle d-flex justify-content-between align-items-center">
                                            <h1>{data.label}</h1>
                                            <Button onClick={() => history.goBack()}>
                                                <span className="material-icons">arrow_back</span>
                                                Back
                                            </Button>
                                        </div>
                                    )}
                                    {data.subLinks &&
                                        data.subLinks.map(
                                            (d) =>
                                                location.pathname === d.path && (
                                                    <div className="layoutTitle d-flex justify-content-between align-items-center">
                                                        <h1>{`${data.label} > ${d.label}`}</h1>
                                                        <Button onClick={() => history.goBack()}>
                                                            <span className="material-icons">
                                                                arrow_back
                                                            </span>
                                                            Back
                                                        </Button>
                                                    </div>
                                                ),
                                        )}
                                </>
                            ))}
                            {props.children}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    )
}

export default Layout
