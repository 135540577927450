import {
    GET_ALL_PAYMENT_LOGS,
    GET_ALL_REFUND_LOGS,
    GET_SINGLE_INVOICE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './transactionTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_PAYMENT_LOGS:
            return {
                ...state,
                all_payment: action.payload,
            }
        case GET_ALL_REFUND_LOGS:
            return {
                ...state,
                all_refund: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
