import React, { useReducer } from 'react'
import LanguageManagerContext from './languageManagerContext'
import LanguageManagerReducer from './languageManagerReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_LANGUAGE,
    GET_ALL_PHRASE,
    CLEAR_RESPONSE,
    ADD_LANGUAGE_GROUP,
    ADD_PHRASE,
    PHRASE_SEARCH,
    PHRASE_SEARCH_BY_LANGUAGE,
    LANGUAGE_MANAGER_RESPONSE,
    ALL_LANGUAGE_GROUP,
} from './languageManagerTypes'

const languageManagerState = (props) => {
    const initialState = {
        all_phrase: {
            records: {},
            totalRecords: 0,
            setDisp: '',
        },
        all_language: {
            records: {},
            totalRecords: 0,
            setDisp: '',
        },
        searchPhraseByLanguage: {
            records: {},
            totalRecords: 0,
            setDisp: '',
        },
        searchPhrase: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        allLanguageGroup: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(LanguageManagerReducer, initialState)
    let resp = new response(dispatch, LANGUAGE_MANAGER_RESPONSE)

    const getAllLanguage = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getlanguage', formData, '', 'language'),
            ])
            const from = 'getlanguage'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_LANGUAGE,
                    payload: {
                        records: res.data.data.responseData ? res.data.data.responseData : {},
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: '',
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: LANGUAGE_MANAGER_RESPONSE,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllLanguageWithPhrase = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'phrase_text_lang', formData, '', 'language'),
            ])
            const from = 'phrase_text_lang'
            if (res.data.status === 'success') {
                dispatch({
                    type: PHRASE_SEARCH_BY_LANGUAGE,
                    payload: {
                        records: res.data.data.responseData?.records
                            ? res.data.data.responseData.records
                            : {},
                        totalRecords: res.data.data.responseData?.totalRecords
                            ? res.data.data.responseData.totalRecords
                            : 0,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: LANGUAGE_MANAGER_RESPONSE,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllGroup = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get_group', formData, '', 'language'),
            ])
            const from = 'get_group'
            if (res.data.status === 'success') {
                dispatch({
                    type: ALL_LANGUAGE_GROUP,
                    payload: {
                        records: res.data.data.responseData?.records
                            ? res.data.data.responseData.records
                            : {},
                        totalRecords: res.data.data.responseData?.totalRecords
                            ? res.data.data.responseData.totalRecords
                            : 0,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: LANGUAGE_MANAGER_RESPONSE,
                payload: 'Something went wrong!',
            })
        }
    }

    const addPhrse = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addphrase', formData, '', 'language'),
            ])
            const from = 'addphrase'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: LANGUAGE_MANAGER_RESPONSE,
                payload: 'Something went wrong!',
            })
        }
    }

    const importPhrase = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'languagephrase_import', formData, '', 'language'),
            ])
            const from = 'languagephrase_import'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: LANGUAGE_MANAGER_RESPONSE,
                payload: 'Something went wrong!',
            })
        }
    }

    const addGroup = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addlang_group', formData, '', 'language'),
            ])
            const from = 'addlang_group'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: LANGUAGE_MANAGER_RESPONSE,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <LanguageManagerContext.Provider
            value={{
                all_language: state.all_language,
                responseStatus: state.responseStatus,
                searchPhraseByLanguage: state.searchPhraseByLanguage,
                allLanguageGroup: state.allLanguageGroup,
                clearResponse,
                getAllLanguage,
                getAllLanguageWithPhrase,
                addPhrse,
                addGroup,
                getAllGroup,
                importPhrase,
            }}
        >
            {props.children}
        </LanguageManagerContext.Provider>
    )
}

export default languageManagerState
