import React, { useContext, useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import CommunicationContext from '../../product/context/communication/communicationContext'

import StaticManage from './StaticManage'
const StaticPages = () => {
    const alertContext = useContext(AlertContext)
    const [isLoading, setIsLoading] = useState(true)
    const communicationContext = useContext(CommunicationContext)
    const history = useHistory()
    const { setAlert } = alertContext
    const {
        static_pages,
        getAllStaticPages,
        responseStatus: responseStatusCommuncation,
        clearResponse: clearResponseCommuncation,
    } = communicationContext

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            searchterm: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllStaticPages(values)
        },
    })

    useEffect(() => {
        if (static_pages) {
            setTableBody(static_pages.records.length ? static_pages.records : [])
        }
        setIsLoading(false)
    }, [static_pages])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.page = 1
        formik.values.searchterm = ''
        getAllStaticPages(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [])

    useEffect(() => {
        if (responseStatusCommuncation) {
            if (
                responseStatusCommuncation.from === 'CommunicationStaticPages' &&
                responseStatusCommuncation.status === 'success'
            ) {
                toggleFullScreenPopup(false)
                setTableBody([])
                formik.values.page = 1
                formik.values.searchterm = ''
                getAllStaticPages(formik.values)
            }
        }
    }, [responseStatusCommuncation])

    const onClickEdit = (id, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    const tableValues = [
        {
            field: 'name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Page',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit static page',
        },
    ]

    const searchInfo = [
        {
            label: 'Page',
            placeholder: 'Enter Page',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard staticPages">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Static Page
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={[]}
                    allData={static_pages}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllStaticPages}
                />
            </div>
            <StaticManage data={manage} function={toggleFullScreenPopup} />
        </Layout>
    )
}
export default StaticPages
