import { Button } from '@material-ui/core'
import React, { useState, useContext, useEffect } from 'react'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import '../../molecules/ProductCard/ProductCard.css'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import { useHistory } from 'react-router-dom'
// import Popup from '../../../../product/components/organisms/Popup'
// import Timer from '../../../common/timer'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import BuyerContext from '../../../../product/context/userBuyer/buyerContext'
import AuthContext from '../../../../product/context/auth/authContext'

import UserContext from '../../../../product/context/user/userContext'

import CustomDialog from '../../organisms/Dialog'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import { mapData } from '../../../../product/common/components'

const Bidding = (props) => {
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    const buyerContext = useContext(BuyerContext)
    const authContext = useContext(AuthContext)
    const userContext = useContext(UserContext)

    const { all_bidding_users } = userContext

    let { bidConfirm } = buyerContext
    const { isAuthenticated } = authContext
    const [product, setProduct] = useState()
    const [biddingUsers, setBiddingUsers] = useState([])
    const history = useHistory()

    const [toggleDialog, setToggleDialog] = useState(false)
    const [toggleDialogDeposit, setToggleDialogDeposit] = useState(false)

    const changeDialogStatus = () => {
        setToggleDialog(!toggleDialog)
    }

    const changeDialogStatusDeposit = () => {
        setToggleDialogDeposit(!toggleDialogDeposit)
    }

    useEffect(() => {
        if (all_bidding_users) {
            setBiddingUsers(all_bidding_users.records.length ? all_bidding_users.records : [])
        }
    }, [all_bidding_users])

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    const validationArray = Yup.object({
        user_id: Yup.string().required(t('enter_user_id')),
        wsprice: Yup.number()
            .min(
                product ? product.next_bid : 0,
                `${t('min_bid')} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            )
            .required(t('enter_bid_amount')),
    })

    const formik = useFormik({
        initialValues: {
            user_id: '',
            wsprice: '',
            id: props.data.id,
            bidding_type: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (product.deposit && !product.bid_deposit_id) {
                setToggleDialogDeposit(true)
            } else if (toggleDialog) {
                bidConfirm(values)
                setToggleDialog(false)
            } else {
                setToggleDialog(true)
            }
        },
    })

    useEffect(() => {
        formik.values.bidding_type = props.type
    }, [props.type])
    const bidAmount = [
        {
            label: 'User',
            placeholder: 'Select User',
            type: 'autocomplete',
            class: '',
            options: biddingUsers.map((subcat) => {
                let subCategoryChanged = {}
                subCategoryChanged.show = `${subcat.id}: ${subcat.email}`
                subCategoryChanged.value = subcat.id
                return subCategoryChanged
            }),
            defaultOptionValue: 'Select User',
            name: 'user_id',
            size: 'small',
            formik: formik,
        },
        {
            label: t('enter_bid_amount'),
            name: 'wsprice',
            type: 'number',
            placeholder: `${t('min_bid')} ${
                product && product.next_bid ? currencyFormat(product.next_bid) : currencyFormat(0)
            }`,
            class: '',
            size: 'small',
            autoFocus: false,
            formik: formik,
        },
    ]

    return (
        <>
            {product ? (
                <>
                    {product.market_status === 'open' ? (
                        <form onSubmit={formik.handleSubmit}>
                            <div className="biddingCnt d-flex justify-content-between align-items-start flex-wrap">
                                {Object.values(mapData(bidAmount))}

                                {isAuthenticated ? (
                                    product.bidtopstatus === 'winner' ? (
                                        <SecondaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? t('increase_hard_bid')
                                                    : t('increase_proxy_bid')
                                            }
                                            type="submit"
                                            btnSize="small"
                                        />
                                    ) : (
                                        <PrimaryButton
                                            label={
                                                formik.values.bidding_type === 'hard'
                                                    ? t('place_hard_bid')
                                                    : t('place_proxy_bid')
                                            }
                                            type="submit"
                                            btnSize="small"
                                        />
                                    )
                                ) : (
                                    <PrimaryButton
                                        label={t('login_to_bid')}
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'login')}
                                    />
                                )}
                            </div>
                        </form>
                    ) : null}

                    <CustomDialog
                        title={t('confirm_bid')}
                        open={toggleDialog}
                        function={changeDialogStatus}
                    >
                        <h5>{t('you_are_about_to_place_a_bid')}!</h5>
                        <h5>{t('please_confirm_if_you_want_to_continue_with_this_action')}</h5>
                        <div className="actionWrapper">
                            <Button onClick={() => setToggleDialog(false)}>{t('cancel')}</Button>
                            <PrimaryButton
                                onClick={formik.handleSubmit}
                                type="button"
                                label={t('submit')}
                            />
                        </div>
                    </CustomDialog>
                    <CustomDialog
                        title={t('submit_a_deposit')}
                        open={toggleDialogDeposit}
                        function={changeDialogStatusDeposit}
                    >
                        <h4>{t('deposit_has_to_be_placed_before_placing_bid_on_the_product')}</h4>
                        <h5>
                            {t('deposit_amount')}: {currencyFormat(product.deposit_amount)}
                        </h5>
                        Required deposit
                    </CustomDialog>
                </>
            ) : null}
        </>
    )
}

export default Bidding
