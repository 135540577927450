import { RESPONSE_STATUS, CLEAR_RESPONSE, GET_SINGLE_CART } from './cartTypes'
export default (state, action) => {
    switch (action.type) {
        case GET_SINGLE_CART:
            return {
                ...state,
                search_singlecart: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
