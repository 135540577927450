import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import EmployeeContext from '../../product/context/employee/empContext'
import CommonContext from '../../product/context/common/commonContext'
import AuthContext from '../../product/context/auth/authContext'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import { Country as CSCCountry, State as CSCState } from 'country-state-city'
import * as Yup from 'yup'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../product/components/molecules/Loaders'

const EmployeeManage = (props) => {
    const employeeContext = useContext(EmployeeContext)
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const { user } = authContext
    const {
        single_employee,
        getSingleEmployee,
        employeeAction,
        responseStatus: responseStatusEmployee,
    } = employeeContext
    const { USStates } = commonContext
    const permission = [
        {
            id: '/dashboard',
            description: 'Dashboard',
        },
        {
            id: '/auction/all',
            description: 'Auction Lots',
        },
        {
            id: '/listing/open',
            description: 'Active Listing',
        },
        {
            id: '/listing/sold',
            description: 'Sold Listing',
        },
        {
            id: '/listing/unsold',
            description: 'Unsold Listing',
        },
        {
            id: '/listing/draft',
            description: 'Draft Listing',
        },
        {
            id: '/listing/relisted',
            description: 'Relisted Listing',
        },
        {
            id: '/users/all',
            description: 'All Users',
        },
        {
            id: '/users/active',
            description: 'Active Users',
        },
        {
            id: '/users/deactivate',
            description: 'Deactivated Users',
        },
        {
            id: '/report',
            description: 'Report Management',
        },
        {
            id: '/communications/email',
            description: 'Email Template',
        },
        {
            id: '/communications/sms',
            description: 'SMS Template',
        },
        {
            id: '/logs/emailLogs',
            description: 'Email',
        },
        {
            id: '/logs/smsLogs',
            description: 'SMS',
        },
        {
            id: '/invoice/paid',
            description: 'Invoice Paid',
        },
        {
            id: '/invoice/draft',
            description: 'Invoice Unpaid',
        },
        {
            id: '/videoAuction',
            description: 'Video Auction',
        },
        {
            id: '/languageManager',
            description: 'Language Manager',
        },
        {
            id: '/wallet',
            description: 'Wallet',
        },
        {
            id: '/bid_deposit',
            description: 'Bid Deposit',
        },
        {
            id: '/transaction/payment',
            description: 'Transactions Payment',
        },
    ]
    const [permissionSelect, setPermissionSelect] = useState(permission)
    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        emp_email: Yup.string().email('Invalid email format').required('Required!'),
        confirm_email:
            data.status === 'new'
                ? Yup.string()
                      .email('Invalid email format')
                      .test('equal', 'emails_do_not_match', (item) =>
                          item && formik.values.emp_email
                              ? item.toLowerCase() === formik.values.emp_email.toLowerCase()
                              : true,
                      )
                      .required('Required!')
                : Yup.string(),
        emp_firstname: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        emp_lastname: Yup.string()
            .min(2, 'Mininum 2 characters')
            .max(15, 'Maximum 15 characters')
            .required('Required!'),
        emp_address1: Yup.string().required('Required!'),
        emp_phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
            .required('Required!'),
        emp_type: Yup.string().required('Required!'),
        emp_city: Yup.string().required('Required!'),
        emp_state: Yup.string().required('Required!'),
        emp_country: Yup.string().required('Required!'),
        emp_zip: Yup.number().required('Required!').positive().integer(),
        emp_permissions: Yup.array().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            emp_email: '',
            confirm_email: '',
            password: '',
            confirm_password: '',
            emp_firstname: '',
            emp_lastname: '',
            emp_address1: '',
            emp_phone: '',
            emp_type: 'employee',
            emp_city: '',
            emp_state: '',
            emp_country: '',
            emp_zip: '',
            emp_permissions: [],
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            employeeAction(values)
            setIsLoading(true)
        },
    })

    useEffect(() => {
        if (responseStatusEmployee) {
            if (responseStatusEmployee.status === 'success') {
                if (responseStatusEmployee.from === 'employeeAction') {
                    formik.resetForm()
                }
            }
        }
    }, [responseStatusEmployee])

    // useEffect(() => {
    //     resetFrom()
    // }, [props])
    useEffect(() => {
        setIsLoading(false)
    }, [data])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        if (data.status === 'edit') {
            setIsLoading(true)
            formik.values.id = data.id
            getSingleEmployee(formik.values)
        } else {
            resetFrom()
            setIsLoading(false)
        }
    }, [data.id])

    useEffect(() => {
        if (single_employee.record && data.status === 'edit') {
            const user = single_employee.record
            formik.values.emp_email = user.emp_email
            formik.values.emp_address1 = user.emp_address1
            formik.values.emp_city = user.emp_city
            formik.values.emp_firstname = user.emp_firstname
            formik.values.emp_lastname = user.emp_lastname
            formik.values.emp_phone = user.emp_phone
            formik.values.emp_type = user.emp_type
            formik.values.emp_state = user.emp_state
            formik.values.emp_country = user.emp_country
            formik.values.emp_zip = user.emp_zip
            formik.values.emp_permissions = user.emp_permissions.split(',')
            setReload(!reload)
        } else {
            resetFrom()
        }
        setIsLoading(false)
    }, [single_employee])

    console.log(single_employee, 'single_employee')

    const resetFrom = () => {
        formik.values.emp_email = ''
        formik.values.emp_address1 = ''
        formik.values.emp_city = ''
        formik.values.emp_firstname = ''
        formik.values.emp_lastname = ''
        formik.values.emp_phone = ''
        formik.values.emp_type = 'employee'
        formik.values.emp_state = ''
        formik.values.emp_country = ''
        formik.values.emp_zip = ''
        formik.values.emp_permissions = []
        formik.handleReset()
        setIsLoading(false)
    }

    const personalDetailsNew = [
        {
            label: 'First name *',
            type: 'text',
            placeholder: 'Enter your first name',
            class: 'col-sm-6 col-12',
            name: 'emp_firstname',
            autoFocus: true,
            formik: formik,
        },
        {
            label: 'Last Name *',
            placeholder: 'Enter last name',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'emp_lastname',
            formik: formik,
        },
        {
            label: 'Phone Number *',
            placeholder: 'Enter phone number',
            type: 'phone',
            class: 'col-sm-6 col-12',
            name: 'emp_phone',
            formik: formik,
        },
        {
            label: 'Employee Type *',
            placeholder: 'Enter employee type',
            class: 'col-sm-6 col-12',
            type: 'select',
            options:
                user.emp_type === 'super_admin'
                    ? [
                          { value: 'super_admin', show: 'Super admin' },
                          { value: 'admin', show: 'Admin' },
                          { value: 'employee', show: 'Employee' },
                      ]
                    : user.emp_type === 'admin'
                    ? [{ value: 'employee', show: 'Employee' }]
                    : [{ value: 'employee', show: 'Employee' }],
            name: 'emp_type',
            formik: formik,
        },
    ]
    if (data.status === 'new') {
        personalDetailsNew.push(
            {
                label: 'Email Address *',
                placeholder: 'Enter email address',
                type: 'text',
                class: 'col-sm-6 col-12',
                name: 'emp_email',
                formik: formik,
            },
            {
                label: 'Confirm Email Address',
                placeholder: 'Confirm your email address',
                type: 'text',
                class: 'col-sm-6 col-12',
                name: 'confirm_email',
                formik: formik,
            },
        )
    } else {
        personalDetailsNew.push({
            label: 'Email Address',
            placeholder: 'Enter email address',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'emp_email',
            disabled: true,
            formik: formik,
        })
    }
    const locationDetails = [
        {
            label: 'Street Address *',
            placeholder: 'Enter street address',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'emp_address1',
            formik: formik,
        },
        {
            label: 'Country *',
            placeholder: 'Select country',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: CSCCountry.getAllCountries()
                .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                .map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = country.isoCode
                    return busCategoryChanged
                }),
            name: 'emp_country',
            formik: formik,
        },
        {
            label: 'State *',
            placeholder: 'Select state',
            class: 'col-12 col-sm-6',
            type: 'select',
            options: CSCState.getStatesOfCountry(
                formik.values.emp_country ? formik.values.emp_country : global.defaultCountry,
            )
                .filter((state) => global.ignoreStates.indexOf(state.isoCode) === -1)
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.isoCode
                    return busCategoryChanged
                }),
            name: 'emp_state',
            formik: formik,
        },
        {
            label: 'City *',
            placeholder: 'Enter city',
            type: 'text',
            class: 'col-sm-6 col-12',
            name: 'emp_city',
            formik: formik,
        },
        {
            label: 'ZIP *',
            placeholder: 'Enter ZIP',
            type: 'number',
            class: 'col-sm-6 col-12',
            name: 'emp_zip',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (formik.values.emp_type === 'super_admin') {
            permission.push(
                {
                    id: '/employees/all',
                    description: 'All Employees',
                },
                {
                    id: '/settings/static',
                    description: 'Static Pages',
                },
                {
                    id: '/settings/general',
                    description: 'General Settings',
                },
                {
                    id: '/settings/businesscategory',
                    description: 'Business Category',
                },
                {
                    id: '/settings/vat',
                    description: 'Vat',
                },
                {
                    id: '/settings/category',
                    description: 'Category',
                },
                {
                    id: '/settings/condition',
                    description: 'Condition',
                },
                {
                    id: '/settings/location',
                    description: 'Location',
                },
                {
                    id: '/settings/bid_increments',
                    description: 'Bid Increments',
                },
            )
            setPermissionSelect(permission)
        } else if (formik.values.emp_type === 'admin') {
            permission.push({
                id: '/employees/all',
                description: 'All Employees',
            })
            setPermissionSelect(permission)
        } else {
            setPermissionSelect(permission)
        }
    }, [formik.values.emp_type])

    const permissionDetails = [
        {
            type: 'check',
            class: 'empPermissions d-flex align-items-center flex-wrap',
            name: 'emp_permissions',
            formik: formik,
            options: permissionSelect,
        },
    ]

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW EMPLOYEE'
                    : `EDIT EMPLOYEE: ${single_employee.record.emp_firstname} ${single_employee.record.emp_lastname}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <h2 className="dashTitle">PERSONAL DETAILS</h2>
                            <div className="row">{Object.values(mapData(personalDetailsNew))}</div>

                            <h2 className="dashTitle">LOCATION DETAILS</h2>
                            <div className="row">{Object.values(mapData(locationDetails))}</div>

                            <h2 className="dashTitle">PERMISSIONS</h2>
                            {Object.values(mapData(permissionDetails))}
                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default EmployeeManage
