export const GET_ALL_INVOICE = 'GET_ALL_INVOICE'
export const GET_ALL_SEARCH = 'GET_ALL_SEARCH'
export const GET_ALL_SIMILAR = 'GET_ALL_SIMILAR'
export const GET_ALL_DASHBOARD = 'GET_ALL_DASHBOARD'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_ACTIVE_SORTS = 'GET_ALL_ACTIVE_SORTS'
export const GET_ALL_BID_HISTORY = 'GET_ALL_BID_HISTORY'
export const GET_ALL_USER_INVOICES = 'GET_ALL_USER_INVOICES'
export const GET_ALL_USER_RETURNS = 'GET_ALL_USER_RETURNS'

export const GET_ALL_RETURN_INVOICE = 'GET_ALL_RETURN_INVOICE'
export const GET_SAVED_SEARCHES = 'GET_SAVED_SEARCHES'
