import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import UserContext from '../../product/context/user/userContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import { Button } from '@material-ui/core'
import { LinearProgress } from '@material-ui/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Country as CSCCountry, State as CSCState } from 'country-state-city'
import moment from 'moment'

import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import { dateFormatFrontOnlyDate } from '../../custom/common/components'
import './UserManage.css'
import Loaders from '../../product/components/molecules/Loaders'
import { Divider } from '@material-ui/core'

const UserManage = (props) => {
    const userContext = useContext(UserContext)
    const customCommonContext = useContext(CustomCommonContext)

    const { allBusinessCategory, allVatCategory } = customCommonContext
    const {
        single_user,
        getSingleUser,
        userAction,
        responseStatus: responseStatusUser,
    } = userContext
    const [currentCountry, setCurrentCountry] = useState()
    const [userType, setUserType] = useState(false)
    const [isAvailable, setIsAvailable] = useState({
        contactPerson2: false,
        contactPerson3: false,
        shareholder2: false,
        shareholder3: false,
    })
    let [inc, setInc] = useState(0)
    let [inc1, setInc1] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const data = props.data
    const toggleFullScreenPopup = props.function

    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    // Temporarry array for Business Structure Types will most Likely be replaced with an api call
    const structureTypes = [
        { value: 'Sole Proprietorship', show: 'Sole Proprietorship' },
        { value: 'Partnership', show: 'Partnership' },
        { value: 'Corporation', show: 'Corporation' },
        { value: 'Limited Liabilty Company (LLC)', show: 'Limited Liabilty Company (LLC)' },
    ]
    const validationArray = [
        Yup.object({
            first_name: Yup.string()
                .min(2, 'Minimum 2 characters')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            last_name: Yup.string()
                .min(2, 'Minimum 2 characters')
                .max(250, 'Maximum 250 characters')
                .required('Required!'),
            address1: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 Character')
                .required('Required!'),
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required'),
            confirm_email: Yup.string()
                .email('Invalid email format')
                .test('equal', 'emails_do_not_match', (item) =>
                    item ? item.toLowerCase() === formik.values.email.toLowerCase() : true,
                )
                .required('Required'),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .required('Required!'),
            country: Yup.string().trim().required('Required!'),
            city: Yup.string().required('Required!'),
            state: Yup.string().trim().required('Required!'),
            zip: Yup.string()
                .trim()
                .matches(/^[0-9]*$/g, 'The positive numeber only')
                .max(6, 'maximum 6 characters')
                .min(3, 'minimum 3 characters')
                .required('Required!'),
            emirates_id:
                currentCountry === 'AE'
                    ? Yup.string()
                          .trim()
                          .matches(/^[0-9a-zA-Z-]*$/g, t('the_special_characters_not_allowed'))
                          .required(t('required'))
                    : Yup.string()
                          .trim()
                          .matches(/^[0-9a-zA-Z-]*$/g, t('the_special_characters_not_allowed')),
            passport_number: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z]*$/g, 'The special characters not allowed')
                .max(15, 'Maximum 15 Character')
                .min(5, 'Minimum 5 Character')
                .required('Required!'),
        }),
        Yup.object({
            companyname: Yup.string()
                .trim()
                .max(150, 'Maximum 150 Character')
                .required('Required!'),
            business_structure: Yup.string().trim(),
            phone: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .required('Required!'),
            mobile_num: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'Only numbers are allowed')
                .required('Required!'),
            website: Yup.string().trim().url(),
            state: Yup.string().trim().required('Required!'),
            city: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'The characters only')
                .max(150, 'Maximum 150 Character')
                .required('Required!'),
            email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .required('Required'),
            confirm_email: Yup.string()
                .email('Invalid email format')
                .test('equal', 'emails_do_not_match', (item) =>
                    item ? item.toLowerCase() === formik.values.email.toLowerCase() : true,
                )
                .required('Required'),
            address1: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of special characters not allowed')
                .max(250, 'Maximum 250 Character')
                .required('Required!'),
            po_box: Yup.string()
                .trim()
                .matches(/^[0-9]*$/g, 'The positive number only')
                .max(6, 'Maximum 6 characters')
                .min(3, 'Minimum 3 characters')
                .required('Required!'),
            business_reg: Yup.string()
                .matches(/^[0-9a-zA-Z- ,.#()]*$/g, 'Some kind of spectial characters not allowed ')
                .required('Required'),
            expiry_date: Yup.string().required('Required!'),
            sme: Yup.string(),
            sme_expiry_date: Yup.string(),
            country: Yup.string().required('Required!'),
            trn: Yup.string().matches(/^[0-9]*$/g, 'The positive number only'),
            vat: Yup.string(),
            dubai_customs_code: Yup.string().max(25, 'Maximum 25 characters'),
            trakhees_reg_num: Yup.string().trim().max(25, 'Maximum 25 characters'),
            trakhees_expiry_date: Yup.string(),
            parent_comp: Yup.string().max(150, 'Maximum 150 characters'),
            parent_address: Yup.string()

                .trim()
                .matches(/^[0-9a-zA-Z]*$/g, 'ome kind of characters not allowed')
                .max(250, 'Maximum 25 characters'),
            contact_po_box: Yup.number().positive('The positive number only').integer(),

            is_dpw_staff: Yup.number().required(),
            business_reg_doc: Yup.array().required('Required!'),
            trn_doc: Yup.array(),
            plc_doc: Yup.array(),

            contact_name: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                .max(150, 'Maximum 150 Character')
                .required('Required!'),
            contact_designation: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                .max(150, 'Maximum 150 Character')
                .required('Required!'),
            contact_phone_num: Yup.string()
                .trim()
                .matches(/^[0-9- +()]*$/g, 'The number only')
                .max(15, 'Maximum 15 Character')
                .required('Required!'),
            contact_email: Yup.string()
                .email('Invalid email')
                .max(250, 'Maximum 250 Character')
                .required('Required!'),
            // contact_department: Yup.string()
            //     .trim()
            //     .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
            //     .max(150, 'Maximum 150 Character')
            //     .required('Required!'),
            contact_name2: inc1 === 0 ? Yup.string() : Yup.string().required('Required!'),
            contact_designation2:
                inc1 === 0
                    ? Yup.string()
                          .trim()
                          .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                          .max(150, 'Maximum 150 Character')
                    : Yup.string()
                          .trim()
                          .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                          .max(150, 'Maximum 150 Character')
                          .required('Required!'),
            // contact_department2:
            //     inc1 === 0
            //         ? Yup.string()
            //               .trim()
            //               .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
            //               .max(150, 'Maximum 150 Character')
            //         : Yup.string()
            //               .trim()
            //               .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
            //               .max(150, 'Maximum 150 Character')
            //               .required('Required!'),
            contact_phone_num2:
                inc1 === 0
                    ? Yup.string()
                          .trim()
                          .matches(/^[0-9- +()]*$/g, 'The number only')
                    : Yup.string()
                          .trim()
                          .matches(/^[0-9- +()]*$/g, 'The number only')
                          .max(15, 'Maximum 15 Character')
                          .required('Required!'),
            contact_email2:
                inc1 === 0
                    ? Yup.string().email('Invalid email').max(250, 'Maximum 250 characters')
                    : Yup.string()
                          .email('Invalid email')
                          .max(250, 'Maximum 250 characters')
                          .required('Required!'),
            contact_name3: inc1 === 2 ? Yup.string().required('Required!') : Yup.string(),
            contact_designation3:
                inc1 === 2
                    ? Yup.string()
                          .trim()
                          .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                          .max(150, 'Maximum 150 Character')
                          .required('Required!')
                    : Yup.string()
                          .trim()
                          .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                          .max(150, 'Maximum 150 Character'),
            // contact_department3:
            //     inc1 === 2
            //         ? Yup.string()
            //               .trim()
            //               .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
            //               .max(150, 'Maximum 150 Character')
            //               .required('Required!')
            //         : Yup.string()
            //               .trim()
            //               .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
            //               .max(150, 'Maximum 150 Character'),
            contact_phone_num3:
                inc1 === 2
                    ? Yup.string()
                          .trim()
                          .matches(/^[0-9- +()]*$/g, 'The number only')
                          .max(15, 'Maximum 15 characters')
                          .required('Required!')
                    : Yup.string()
                          .trim()
                          .matches(/^[0-9- +()]*$/g, 'The number only'),
            contact_email3:
                inc1 === 2
                    ? Yup.string()
                          .email('Invalid email')
                          .max(250, 'Maximum 250 characters')
                          .required('Required!')
                    : Yup.string().email('Invalid email').max(250, 'Maximum 250 characters'),
            share_name: Yup.string()
                .trim()
                .matches(/^[0-9a-zA-Z ]*$/g, 'The special characters not allowed')
                .max(150, 'Maximum 150 characters')
                .when(['business_structure'], {
                    is: (business_structure) => business_structure !== 'Sole Proprietorship',
                    then: Yup.string().required('Required!'),
                }),
            share_desgination: Yup.string()
                .max(50, 'Maximum 50 characters')
                .when(['business_structure'], {
                    is: (business_structure) => business_structure !== 'Sole Proprietorship',
                    then: Yup.string().required('Required!'),
                }),
            share_phone_num: Yup.string().when(['business_structure'], {
                is: (business_structure) => business_structure !== 'Sole Proprietorship',
                then: Yup.string().required('Required!'),
            }),
            share_email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters')
                .when(['business_structure'], {
                    is: (business_structure) => business_structure !== 'Sole Proprietorship',
                    then: Yup.string().required('Required!'),
                }),
            second_share_name:
                inc !== 0
                    ? Yup.string().max(150, 'Maximum 150 character').required('Required!')
                    : Yup.string().max(150, 'Maximum 150 character'),
            second_share_desgination:
                inc !== 0
                    ? Yup.string().max(50, 'Maximum 50 character').required('Required!')
                    : Yup.string().max(50, 'Maximum 50 character'),
            second_share_phone_num:
                inc !== 0
                    ? Yup.string().max(15, 'Maximum 15 characters').required('Required!')
                    : Yup.string().max(15, 'Maximum 15 characters'),
            second_share_email:
                inc !== 0
                    ? Yup.string()
                          .email('Invalid email format')
                          .max(250, 'Maximum 250 characters')
                          .required('Required!')
                    : Yup.string().email('Invalid email format').max(250, 'Maximum 250 characters'),
            third_share_name:
                inc > 1 && inc < 3
                    ? Yup.string().max(50, 'Maximum 50 characters').required('Required!')
                    : Yup.string().max(50, 'Maximum 50 characters'),
            third_share_desgination:
                inc > 1 && inc < 3
                    ? Yup.string().max(50, 'Maximum 50 characters').required('Required!')
                    : Yup.string().max(50, 'Maximum 50 characters'),
            third_share_phone_num:
                inc > 1 && inc < 3
                    ? Yup.string().max(15, 'Maximum 15 characters').required('Required!')
                    : Yup.string().max(15, 'Maximum 15 characters'),
            third_share_email: Yup.string()
                .email('Invalid email format')
                .max(250, 'Maximum 250 characters'),
            business_category: Yup.array().required('Required!'),
        }),
    ]

    const formik = useFormik({
        initialValues: {
            id: 0,
            email: '',
            confirm_email: '',
            first_name: '',
            last_name: '',
            address1: '',
            phone: '',
            city: '',
            country: global.defaultCountry,
            state: '',
            zip: '',
            emirates_id: '',
            passport_number: '',
            docs: [],
            trn_doc: [],
            plc_doc: [],
            companyname: '',
            business_structure: '',
            mobile_num: '',
            website: '',
            po_box: '',
            business_reg: '',
            expiry_date: '',
            contact_name: '',
            contact_designation: '',
            contact_department: '',
            contact_phone_num: '',
            contact_email: '',
            contact_name2: '',
            contact_designation2: '',
            contact_department2: '',
            contact_phone_num2: '',
            contact_email2: '',
            contact_name3: '',
            contact_designation3: '',
            contact_department3: '',
            contact_phone_num3: '',
            contact_email3: '',
            parent_comp: '',
            parent_address: '',
            contact_po_box: 0,
            share_name: '',
            share_desgination: '',
            share_phone_num: 0,
            share_email: '',
            second_share_name: '',
            second_share_desgination: '',
            second_share_phone_num: 0,
            second_share_email: '',
            third_share_name: '',
            third_share_desgination: '',
            third_share_phone_num: 0,
            third_share_email: '',
            business_category: [],
            trn: '',
            vat: '',
            dubai_customs_code: '',
            trakhees_reg_num: '',
            trakhees_expiry_date: '',
            sme: '',
            sme_expiry_date: '',
            is_dpw_staff: 2,
            comp_seal: [],
            business_reg_doc: [],
            phone_verified: 0,
            verified_phonenum: '',
            phone_verifysent: 0,
            verify_code: '',
            termCheck: 1,
        },
        validationSchema: validationArray[userType ? 1 : 0],
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            value.expiry_date = value.expiry_date
                ? moment(value.expiry_date, 'MM-DD-YYYY').format('YYYY-MM-DD')
                : ''
            value.trakhees_expiry_date = value.trakhees_expiry_date
                ? moment(value.trakhees_expiry_date, 'MM-DD-YYYY').format('YYYY-MM-DD')
                : ''
            value.sme_expiry_date = value.sme_expiry_date
                ? moment(value.sme_expiry_date, 'MM-DD-YYYY').format('YYYY-MM-DD')
                : ''
            if (userType) {
                value.first_name = value.companyname
                value.last_name = ''
            }
            value.user_type = userType ? 1 : 0
            userAction(value)
        },
    })
    useEffect(() => {
        setIsLoading(false)
    }, [])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        if (data.status === 'edit') {
            setIsLoading(true)
            formik.values.id = data.id
            getSingleUser(formik.values)
        } else {
            resetFrom()
        }
    }, [data])

    useEffect(() => {
        if (single_user.record && data.status === 'edit') {
            const user = single_user.record
            setUserType(user.user_type ? true : false)
            formik.setValues({
                // Here We Iniatalize the fields to show orignal data
                id: user.id,
                first_name: user.first_name,
                last_name: user.last_name,
                passport_number: user.passport_number,
                emirates_id: user.emirates_id ? user.emirates_id : '',
                companyname: user.companyname,
                phone: user.phone,
                mobile_num: user.mobile_num,
                email: user.email,
                confirm_email: user.email,
                website: user.website ? user.website : '',
                business_structure: user.business_structure,
                state: user.state,
                city: user.city,
                address1: user.address1,
                zip: user.zip,
                po_box: user.po_box,
                business_reg: user.business_reg,
                expiry_date: user.expiry_date
                    ? moment(user.expiry_date, 'YYYY-MM-DD').format('MM-DD-YYYY')
                    : '',
                country: user.country,
                contact_name: user.contact_name,
                contact_designation: user.contact_designation,
                contact_phone_num: user.contact_phone_num,
                contact_department: user.contact_department ? user.contact_department : '',
                contact_department2: user.contact_department2 ? user.contact_department2 : '',
                contact_email: user.contact_email,
                contact_name2: user.contact_name2 ? user.contact_name2 : '',
                contact_designation2: user.contact_designation2 ? user.contact_designation2 : '',
                contact_phone_num2: user.contact_phone_num2 ? user.contact_phone_num2 : '',
                contact_email2: user.contact_email2 ? user.contact_email2 : '',
                contact_name3: user.contact_name3 ? user.contact_name3 : '',
                contact_designation3: user.contact_designation3 ? user.contact_designation3 : '',
                contact_phone_num3: user.contact_phone_num3 ? user.contact_phone_num3 : '',
                contact_email3: user.contact_email3 ? user.contact_email3 : '',
                contact_department3: user.contact_department3 ? user.contact_department3 : '',
                parent_comp: user.parent_comp ? user.parent_comp : '',
                parent_address: user.parent_address ? user.parent_address : '',
                contact_po_box: user.contact_po_box ? user.contact_po_box : '',
                share_name: user.share_name,
                share_desgination: user.share_desgination,
                share_phone_num: user.share_phone_num,
                share_email: user.share_email,
                second_share_name: user.second_share_name ? user.second_share_name : '',
                second_share_desgination: user.second_share_desgination
                    ? user.second_share_desgination
                    : '',
                second_share_phone_num: user.second_share_phone_num
                    ? user.second_share_phone_num
                    : '',
                second_share_email: user.second_share_email ? user.second_share_email : '',
                third_share_name: user.third_share_name ? user.third_share_name : '',
                third_share_desgination: user.third_share_desgination
                    ? user.third_share_desgination
                    : '',
                third_share_phone_num: user.third_share_phone_num ? user.third_share_phone_num : '',
                third_share_email: user.third_share_email ? user.third_share_email : '',
                category: user.category,
                trn: user.trn,
                vat: user.vat,
                dubai_customs_code: user.dubai_customs_code ? user.dubai_customs_code : '',
                trakhees_reg_num: user.trakhees_reg_num ? user.trakhees_reg_num : '',
                trakhees_expiry_date: user.trakhees_expiry_date
                    ? moment(user.trakhees_expiry_date, 'YYYY-MM-DD').format('MM-DD-YYYY')
                    : '',
                sme: user.sme ? user.sme : '',
                sme_expiry_date: user.sme_expiry_date
                    ? moment(user.sme_expiry_date, 'YYYY-MM-DD').format('MM-DD-YYYY')
                    : '',
                is_dpw_staff: user.is_dpw_staff,
                business_category: user.business_category
                    ? user.business_category.toString().split(',')
                    : [],
                comp_seal: user.comp_seal ? user.comp_seal.split(',') : [],
                plc_doc: user.plc_doc ? user.plc_doc.split(',') : [],
                trn_doc: user.trn_doc ? user.trn_doc.split(',') : [],
                business_reg_doc: user.business_reg_doc ? user.business_reg_doc.split(',') : [],
                docs: user.docs?.split(','),
            })
        } else {
            resetFrom()
        }
        setIsLoading(false)
    }, [single_user])

    useEffect(() => {
        setCurrentCountry(formik.values.country)
        const user = single_user?.record
        if (user && user.country && formik.values.country === user.country) {
            formik.setFieldValue('state', user.state)
        } else {
            formik.setFieldValue('state', '')
        }
    }, [formik.values.country])

    const resetFrom = () => {
        formik.values.email = ''
        formik.values.address1 = ''
        formik.values.city = ''
        formik.values.first_name = ''
        formik.values.last_name = ''
        formik.values.phone = ''
        formik.values.state = ''
        formik.values.zip = ''
        formik.values.country = global.defaultCountry
        formik.values.emirates_id = ''
        formik.values.passport_number = ''
        formik.values.trn_doc = []
        formik.values.plc_doc = []
        formik.handleReset()
        setInc1(0)
        setInc(0)
        setIsLoading(false)
    }

    const organisationDetails = {
        formik: formik,
        data: [
            {
                label: t('company_name') + '*',
                name: 'companyname',
                type: 'text',
                placeholder: t('enter_your_company_name'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('business_structure'),
                placeholder: t('select_a_business_structure'),
                class: 'col-12 col-sm-6',
                type: 'select',
                options: structureTypes,
                name: 'business_structure',
            },
            {
                label: t('telephone_number') + '*',
                name: 'mobile_num',
                type: 'phone',
                placeholder: '+971 X XXX XXXX',
                class: 'col-12 col-sm-6',
            },
            {
                label: t('mobile_number') + '*',
                name: 'phone',
                type: 'phone',
                placeholder: '+971 XXXXX XXXXX',
                class: 'col-12 col-sm-6',
            },
            {
                label: t('email_address') + '*',
                name: 'email',
                type: 'email',
                placeholder: t('enter_your_email_address'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('confirm_email_address') + '*',
                name: 'confirm_email',
                type: 'email',
                placeholder: t('enter_your_email_address'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('country') + '*',
                placeholder: t('select_your_country'),
                class: 'col-12 col-sm-6',
                type: 'select',
                options: CSCCountry.getAllCountries()
                    .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    }),
                name: 'country',
            },
            {
                label: t('state') + '*',
                placeholder: t('select_your_state'),
                class: 'col-12 col-sm-6',
                type: 'select',
                options: CSCState.getStatesOfCountry(
                    formik.values.country ? formik.values.country : global.defaultCountry,
                )
                    .filter((state) => global.ignoreStates.indexOf(state.isoCode) === -1)
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.name
                        busCategoryChanged.value = buscat.isoCode
                        return busCategoryChanged
                    }),
                name: 'state',
            },
            {
                label: t('city') + '*',
                placeholder: t('enter_your_city'),
                class: 'col-12 col-sm-6',
                type: 'text',
                name: 'city',
            },
            {
                label: t('address') + '*',
                name: 'address1',
                type: 'text',
                placeholder: t('enter_your_address'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('po_box') + '*',
                name: 'po_box',
                type: 'number',
                placeholder: t('enter_your_post_box_number'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('website'),
                name: 'website',
                type: 'text',
                placeholder: t('enter_your_website'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('business_registration_number') + '*',
                name: 'business_reg',
                type: 'text',
                placeholder: t('enter_business_registration_number'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('expiry_date') + '*',
                name: 'expiry_date',
                disablePast: true,
                type: 'date',
                placeholder: t('select_an_expire_date'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('sme_membership_number'),
                name: 'sme',
                type: 'text',
                placeholder: t('enter_sme_membership_number'),
                class: 'col-12 col-sm-6',
            },
            {
                label: 'SME ' + t('expiry_date'),
                name: 'sme_expiry_date',
                disablePast: true,
                type: 'date',
                placeholder: t('select_an_expire_date'),
                class: 'col-12 col-sm-6',
            },
        ],
    }

    const contactPersonCompany = {
        formik: formik,
        data: [
            {
                label: t('name_of_parent_company'),
                name: 'parent_comp',
                type: 'text',
                placeholder: t('enter_name_of_parent_company'),
                class: 'col-12 col-sm-12',
            },
            {
                label: t('address'),
                name: 'parent_address',
                type: 'text',
                placeholder: t('enter_address'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('po_box'),
                name: 'contact_po_box',
                type: 'number',
                placeholder: t('enter_your_post_box_number'),
                class: 'col-12 col-sm-6',
            },
        ],
    }
    const shareholderMisc = {
        formik: formik,
        data: [
            {
                label: t('tax_registration_number'),
                name: 'trn',
                type: 'text',
                placeholder: t('enter_tax_registration_number'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('uae_emirate_of_vat_registration'),
                placeholder: t('select') + ' ' + t('uae_emirate_of_vat_registration'),
                class: 'col-12 col-sm-6',
                type: 'select',
                options: allVatCategory
                    ?.filter((value) => value.active === 1 || formik.values.vat.includes(value.id))
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.description
                        busCategoryChanged.value = buscat.id
                        return busCategoryChanged
                    }),
                name: 'vat',
            },
            // {
            //     label: t('trakhees_registration_number'),
            //     name: 'trakhees_reg_num',
            //     type: 'text',
            //     placeholder: t('enter_trakhees_registration_number'),
            //     class: 'col-12 col-sm-6',
            // },
            // {
            //     label: t('trakhees_expiry_date'),
            //     name: 'trakhees_expiry_date',
            //     disablePast: true,
            //     type: 'date',
            //     placeholder: t('select_an_expire_date'),
            //     class: 'col-12 col-sm-6',
            // },
            // {
            //     label: t('dubai_customs_code'),
            //     name: 'dubai_customs_code',
            //     type: 'text',
            //     placeholder: t('enter_dubai_customs_code'),
            //     class: 'col-12 col-sm-6',
            // },
            {
                title: t(
                    'is_your_company_owner_or_manager_related_to_any_of_dp_world_procurement_staff',
                ),
                type: 'radio',
                int: 1,
                name: 'is_dpw_staff',
                class: 'col-12',
                item: [
                    { id: 1, description: t('yes') },
                    { id: 2, description: t('no') },
                ],
            },
        ],
    }

    const fileUploads = {
        formik: formik,
        data: [
            {
                type: 'uploadDropZone',
                name: 'business_reg_doc',
                icon: 'description',
                titleText: t('upload_Business_registration_or_trade_license_document'),
                class: 'col-12',
                folder: 'product',
                multiple: true,
                accept: 'image/*, application/*',
            },
            {
                type: 'uploadDropZone',
                name: 'plc_doc',
                icon: 'description',
                titleText: t('upload_necessary_permits_licenses_or_certificates'),
                class: 'col-6',
                folder: 'product',
                multiple: true,
                accept: 'image/*, application/*',
            },
            {
                type: 'uploadDropZone',
                name: 'trn_doc',
                icon: 'description',
                titleText: t('upload_tax_registration_number_document'),
                class: 'col-6',
                folder: 'product',
                multiple: true,
                accept: 'image/*, application/*',
            },
            {
                type: 'uploadDropZone',
                name: 'comp_seal',
                icon: 'publish',
                titleText: t('upload_company_seal'),
                class: 'col-6',
                folder: 'product',
                multiple: true,
                accept: 'image/*, application/*',
            },
        ],
    }
    // Array mapped over for contact person details
    let contactPersonDetails = [
        {
            formik: formik,
            data: [
                {
                    label: t('name') + '*',
                    name: 'contact_name',
                    type: 'text',
                    placeholder: t('enter_name'),
                    class: 'col-12 col-sm-6',
                    autoFocus: false,
                },
                {
                    label: t('designation') + '*',
                    name: 'contact_designation',
                    type: 'text',
                    placeholder: t('enter_designation'),
                    class: 'col-12 col-sm-3',
                },
                // {
                //     label: t('department') + '*',
                //     name: 'contact_department',
                //     type: 'text',
                //     placeholder: t('enter_a_department'),
                //     class: 'col-12 col-sm-3',
                // },
                {
                    label: t('phone_number_or_mobile_number') + '*',
                    name: 'contact_phone_num',
                    type: 'phone',
                    placeholder: '+971 XXXXX XXXXX',
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('email_address') + '*',
                    name: 'contact_email',
                    type: 'email',
                    placeholder: t('enter_email_address'),
                    class: 'col-12 col-sm-6',
                },
            ],
        },
        {
            formik: formik,
            data: [
                {
                    type: 'misc',
                    class: 'col-12 col-sm-12',
                    content: (
                        <div className=" regDivider">
                            <Divider />{' '}
                        </div>
                    ),
                },
                {
                    label: t('name') + '*',
                    name: 'contact_name2',
                    type: 'text',
                    placeholder: t('enter_name'),
                    class: 'col-12 col-sm-6',
                    autoFocus: false,
                },
                {
                    label: t('designation') + '*',
                    name: 'contact_designation2',
                    type: 'text',
                    placeholder: t('enter_designation'),
                    class: 'col-12 col-sm-3',
                },
                // {
                //     label: t('department') + '*',
                //     name: 'contact_department2',
                //     type: 'text',
                //     placeholder: t('enter_a_department'),
                //     class: 'col-12 col-sm-3',
                // },
                {
                    label: t('phone_number_or_mobile_number') + '*',
                    name: 'contact_phone_num2',
                    type: 'phone',
                    placeholder: '+971 X XXX XXXX',
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('email_address') + '*',
                    name: 'contact_email2',
                    type: 'email',
                    placeholder: t('enter_email_address'),
                    class: 'col-12 col-sm-6',
                },
            ],
        },
        {
            formik: formik,
            data: [
                {
                    type: 'misc',
                    class: 'col-12 col-sm-12',
                    content: (
                        <div className=" regDivider">
                            <Divider />{' '}
                        </div>
                    ),
                },
                {
                    label: t('name') + '*',
                    name: 'contact_name3',
                    type: 'text',
                    placeholder: t('enter_name'),
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('designation') + '*',
                    name: 'contact_designation3',
                    type: 'text',
                    placeholder: t('enter_designation'),
                    class: 'col-12 col-sm-3',
                },
                // {
                //     label: t('department') + '*',
                //     name: 'contact_department3',
                //     type: 'text',
                //     placeholder: t('enter_a_department'),
                //     class: 'col-12 col-sm-3',
                // },
                {
                    label: t('phone_number_or_mobile_number') + '*',
                    name: 'contact_phone_num3',
                    type: 'phone',
                    placeholder: '+971 XXXXX XXXXX',
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('email_address') + '*',
                    name: 'contact_email3',
                    type: 'email',
                    placeholder: t('enter_email_address'),
                    class: 'col-12 col-sm-6',
                },
            ],
        },
    ]

    // Array mapped over for share holder details
    const shareholderDetails = [
        {
            formik: formik,
            data: [
                {
                    label: t('name') + '*',
                    name: 'share_name',
                    type: 'text',
                    placeholder: t('enter_name'),
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('designation') + '*',
                    name: 'share_desgination',
                    type: 'text',
                    placeholder: t('enter_designation'),
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('phone_number') + '*',
                    name: 'share_phone_num',
                    type: 'phone',
                    placeholder: '+971 XXXXX XXXXX',
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('email_address') + '*',
                    name: 'share_email',
                    type: 'email',
                    placeholder: t('enter_email_address'),
                    class: 'col-12 col-sm-6',
                },
            ],
        },
        {
            formik: formik,
            data: [
                {
                    type: 'misc',
                    class: 'col-12 col-sm-12',
                    content: (
                        <div className=" regDivider">
                            <Divider />{' '}
                        </div>
                    ),
                },
                {
                    label: t('name') + '*',
                    name: 'second_share_name',
                    type: 'text',
                    placeholder: t('enter_name'),
                    class: 'col-12 col-sm-6',
                    autoFocus: false,
                },
                {
                    label: t('designation') + '*',
                    name: 'second_share_desgination',
                    type: 'text',
                    placeholder: t('enter_designation'),
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('phone_number') + '*',
                    name: 'second_share_phone_num',
                    type: 'phone',
                    placeholder: '+971 X XXX XXXX',
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('email_address') + '*',
                    name: 'second_share_email',
                    type: 'email',
                    placeholder: t('enter_email_address'),
                    class: 'col-12 col-sm-6',
                },
            ],
        },
        {
            formik: formik,
            data: [
                {
                    type: 'misc',
                    class: 'col-12 col-sm-12',
                    content: (
                        <div className=" regDivider">
                            <Divider />{' '}
                        </div>
                    ),
                },
                {
                    label: t('name') + '*',
                    name: 'third_share_name',
                    type: 'text',
                    placeholder: t('enter_name'),
                    class: 'col-12 col-sm-6',
                    autoFocus: false,
                },
                {
                    label: t('designation') + '*',
                    name: 'third_share_desgination',
                    type: 'text',
                    placeholder: t('enter_designation'),
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('phone_number') + '*',
                    name: 'third_share_phone_num',
                    type: 'phone',
                    placeholder: '+971 X XXX XXXX',
                    class: 'col-12 col-sm-6',
                },
                {
                    label: t('email_address') + '*',
                    name: 'third_share_email',
                    type: 'email',
                    placeholder: t('enter_email_address'),
                    class: 'col-12 col-sm-6',
                },
            ],
        },
    ]

    // Array mapped over to get prefered category of company
    const categoryDetails = {
        formik: formik,
        data: [
            {
                label: t('categories') + '*',
                type: 'check',
                name: 'business_category',
                class: 'col-12 catgItems',
                options: allBusinessCategory
                    ?.filter(
                        (value) =>
                            value.active === 1 ||
                            formik.values.business_category.includes(value.id.toString()),
                    )
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.description = buscat.description
                        busCategoryChanged.id = buscat.id
                        return busCategoryChanged
                    }),
            },
        ],
    }

    const IndividualRegistration = {
        formik: formik,
        data: [
            // {
            //     title: t('are_you_dpw_group_employee') + '*',
            //     type: 'radio',
            //     name: 'is_dpw_employee',
            //     int: 1,
            //     class: 'col-12',
            //     item: [
            //         { id: 1, description: t('yes') },
            //         { id: 2, description: t('no') },
            //     ],
            // },
            // {
            //     title: t('are_you_related_to_any_dpw') + '*',
            //     type: 'radio',
            //     int: 1,
            //     name: 'is_dpw_staff',
            //     class: 'col-12 mb-3',
            //     item: [
            //         { id: 1, description: t('yes') },
            //         { id: 2, description: t('no') },
            //     ],
            // },
            {
                label: t('first_name') + '*',
                name: 'first_name',
                type: 'text',
                placeholder: t('enter_your_first_name'),
                class: 'col-12 col-sm-6',
                autoFocus: true,
            },
            {
                label: t('last_name') + '*',
                name: 'last_name',
                type: 'text',
                placeholder: t('enter_your_last_name'),
                class: 'col-12 col-sm-6',
            },
        ],
    }

    if (data.status === 'new') {
        IndividualRegistration.data.push(
            {
                label: t('email_address') + '*',
                name: 'email',
                type: 'email',
                placeholder: t('enter_your_email_address'),
                class: 'col-12 col-sm-6',
            },
            {
                label: t('confirm_email_address') + '*',
                name: 'confirm_email',
                type: 'email',
                placeholder: t('enter_your_email_address'),
                class: 'col-12 col-sm-6',
            },
        )
    } else {
        IndividualRegistration.data.push({
            label: t('email_address') + '*',
            name: 'email',
            type: 'email',
            placeholder: t('enter_your_email_address'),
            class: 'col-12 col-sm-6',
        })
    }
    IndividualRegistration.data.push(
        {
            label: t('mobile_number') + '*',
            name: 'phone',
            type: 'phone',
            placeholder: t('mobile_number'),
            class: 'col-12 col-sm-6',
        },
        {
            label: t('emirates_id') + '*',
            name: 'emirates_id',
            type: 'text',
            placeholder: t('enter_your_emirates_id'),
            class: 'col-12 col-sm-6',
        },
        {
            label: t('passport_number') + '*',
            name: 'passport_number',
            type: 'text',
            placeholder: t('enter_your_passport_number'),
            class: 'col-12 col-sm-6',
        },
        {
            label: t('country') + '*',
            placeholder: t('select_your_country'),
            class: 'col-12 col-sm-6',
            type: 'select',
            options: CSCCountry.getAllCountries()
                .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                .map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = country.isoCode
                    return busCategoryChanged
                }),
            name: 'country',
        },
        {
            label: t('state') + '*',
            placeholder: t('select_your_state'),
            class: 'col-12 col-sm-6',
            type: 'select',
            options: CSCState.getStatesOfCountry(
                formik.values.country ? formik.values.country : global.defaultCountry,
            )
                .filter((state) => global.ignoreStates.indexOf(state.isoCode) === -1)
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.isoCode
                    return busCategoryChanged
                }),
            name: 'state',
        },
        {
            label: t('city') + '*',
            name: 'city',
            type: 'text',
            placeholder: t('enter_your_city'),
            class: 'col-12 col-sm-6',
        },
        {
            label: t('address') + '*',
            name: 'address1',
            type: 'text',
            placeholder: t('enter_your_address'),
            class: 'col-12 col-sm-6',
        },
        {
            label: t('po_box') + '*',
            placeholder: t('enter_your_post_box_number'),
            class: 'col-12 col-sm-3',
            type: 'number',
            name: 'zip',
        },
        {
            type: 'uploadDropZone',
            name: 'plc_doc',
            icon: 'description',
            titleText: 'Upload Passport / Emirates ID',
            // innerText: 'Recommended dimensions: 1400px * 440px',
            class: 'col-12',
            folder: 'product',
            multiple: true,
            accept: 'image/*, application/*',
        },
    )

    function addMore(activeStep) {
        if (activeStep === 1) {
            if (inc <= 2) {
                setInc(inc + 1)
            }
        } else if (activeStep === 2) {
            if (inc1 <= 2) {
                setInc1(inc1 + 1)
            }
        }
    }

    const setFeiledValue = (data) => {
        Object.keys(data).forEach((key) => {
            formik.setFieldValue(key, data[key])
        })
    }

    const removeSet = (incInside = '', inc1Inside = '') => {
        const resetContactPerson = [
            {
                contact_name: '',
                contact_designation: '',
                contact_phone_num: '',
                contact_department: '',
                contact_email: '',
            },
            {
                contact_name2: '',
                contact_designation2: '',
                contact_phone_num2: '',
                contact_email2: '',
                contact_department2: '',
            },
            {
                contact_name3: '',
                contact_department3: '',
                contact_designation3: '',
                contact_phone_num3: '',
                contact_email3: '',
            },
        ]

        const resetShareHolder = [
            {
                share_name: '',
                share_desgination: '',
                share_phone_num: '',
                share_email: '',
            },
            {
                second_share_name: '',
                second_share_desgination: '',
                second_share_phone_num: '',
                second_share_email: '',
            },
            {
                third_share_name: '',
                third_share_desgination: '',
                third_share_phone_num: '',
                third_share_email: '',
            },
        ]

        const removeContactPerson = [
            {
                contact_name: formik.values.contact_name2,
                contact_designation: formik.values.contact_designation2,
                contact_phone_num: formik.values.contact_phone_num2,
                contact_department: formik.values.contact_department2,
                contact_email: formik.values.contact_email2,
            },
            {
                contact_name2: formik.values.contact_name3,
                contact_designation2: formik.values.contact_designation3,
                contact_phone_num2: formik.values.contact_phone_num3,
                contact_email2: formik.values.contact_email3,
                contact_department2: formik.values.contact_department3,
            },
        ]

        const removeShareHolder = [
            {
                share_name: formik.values.second_share_name,
                share_desgination: formik.values.second_share_desgination,
                share_phone_num: formik.values.second_share_phone_num,
                share_email: formik.values.second_share_email,
            },
            {
                second_share_name: formik.values.third_share_name,
                second_share_desgination: formik.values.third_share_desgination,
                second_share_phone_num: formik.values.third_share_phone_num,
                second_share_email: formik.values.third_share_email,
            },
        ]

        if (inc1Inside === inc1) {
            if (inc1Inside === 1) {
                setFeiledValue(resetContactPerson[1])
                setInc1(inc1 - 1)
            } else if (inc1Inside === 2) {
                setFeiledValue(resetContactPerson[2])
                setInc1(inc1 - 1)
            }
        } else {
            if (inc1Inside === 0 && inc1 === 1) {
                setFeiledValue(removeContactPerson[0])
                setFeiledValue(resetContactPerson[1])
                setInc1(inc1 - 1)
            } else if (inc1Inside === 0 && inc1 === 2) {
                setFeiledValue(removeContactPerson[0])
                setFeiledValue(removeContactPerson[1])
                setFeiledValue(resetContactPerson[2])
                setInc1(inc1 - 1)
            } else if (inc1Inside === 1 && inc1 === 2) {
                setFeiledValue(removeContactPerson[1])
                setFeiledValue(resetContactPerson[2])
                setInc1(inc1 - 1)
            }
        }

        if (incInside === inc) {
            if (incInside === 1) {
                setFeiledValue(resetShareHolder[1])
                setInc(inc - 1)
            } else if (incInside === 2) {
                setFeiledValue(resetShareHolder[2])
                setInc(inc - 1)
            }
        } else {
            if (incInside === 0 && inc === 1) {
                setFeiledValue(removeShareHolder[0])
                setFeiledValue(resetShareHolder[1])
                setInc(inc - 1)
            } else if (incInside === 0 && inc === 2) {
                setFeiledValue(removeShareHolder[0])
                setFeiledValue(removeShareHolder[1])
                setFeiledValue(resetShareHolder[2])
                setInc(inc - 1)
            } else if (incInside === 1 && inc === 2) {
                setFeiledValue(removeShareHolder[1])
                setFeiledValue(resetShareHolder[2])
                setInc(inc - 1)
            }
        }
    }

    useEffect(() => {
        if (single_user.record && data.status === 'edit') {
            const user = single_user.record
            if (user?.contact_name2 && user.contact_name2 != '') setInc1(1)
            if (user?.contact_name3 && user.contact_name3 !== '') setInc1(2)
            if (user?.second_share_name && user.second_share_name !== '') setInc(1)
            if (user?.third_share_name && user.third_share_name !== '') setInc(2)
        }
    }, [single_user])

    useEffect(() => {
        setUserType(data.user_type === 1)
    }, [data.user_type])

    const formSubmit = (e) => {
        formik.handleSubmit(e)
        console.log(formik.errors)
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'userAction') {
                formik.setSubmitting(false)
                setIsLoading(false)
            }
        }
    }, [responseStatusUser])

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW USER'
                    : `EDIT USER: ${single_user.record.first_name || ''} ${
                          single_user.record.last_name || ''
                      }`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <form onSubmit={formSubmit} autoComplete="nofill">
                            {userType || data.user_type === 1 ? (
                                <div>
                                    <h2 className="dashTitle">Organization Details</h2>
                                    <div className="row">
                                        {mapData(organisationDetails)}
                                        {/* {mapData(contactPersonCompany)} */}
                                        {mapData(shareholderMisc)}
                                    </div>
                                    {/* <h2 className="dashTitle">Share Holder Misc</h2> */}
                                    <h2 className="dashTitle">Contact Person Details</h2>
                                    <div className="row">
                                        {inc1 >= 0 && mapData(contactPersonDetails[0])}
                                        {inc1 >= 1 && (
                                            <div className="col-12 mb-3">
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSet('', 0)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        remove_circle
                                                    </span>
                                                    {t('remove_person')}
                                                </Button>
                                            </div>
                                        )}
                                        {inc1 >= 1 ? mapData(contactPersonDetails[1]) : null}
                                        {inc1 >= 1 && (
                                            <div className="col-12 mb-3">
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSet('', 1)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        remove_circle
                                                    </span>
                                                    {t('remove_person')}
                                                </Button>
                                            </div>
                                        )}
                                        {inc1 >= 2 ? mapData(contactPersonDetails[2]) : null}
                                        <div className="col-12 mb-3">
                                            {inc1 <= 1 && (
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        addMore(2)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        add_circle
                                                    </span>
                                                    {t('add_person')}
                                                </Button>
                                            )}
                                            {inc1 >= 2 ? (
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSet('', 2)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        remove_circle
                                                    </span>
                                                    {t('remove_person')}
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>

                                    <h2 className="dashTitle">Shareholder Details</h2>
                                    <div className="row">
                                        {inc >= 0 && mapData(shareholderDetails[0])}
                                        <div className="col-12 mb-3">
                                            {inc >= 1 && (
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSet(0)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        remove_circle
                                                    </span>
                                                    {t('remove_shareholder')}
                                                </Button>
                                            )}
                                        </div>
                                        {inc >= 1 ? mapData(shareholderDetails[1]) : null}
                                        {inc >= 1 && (
                                            <div className="col-12 mb-3">
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSet(1)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        remove_circle
                                                    </span>
                                                    {t('remove_shareholder')}
                                                </Button>
                                            </div>
                                        )}
                                        {inc >= 2 ? mapData(shareholderDetails[2]) : null}

                                        <div className="col-12 mb-3">
                                            {inc <= 1 && (
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        addMore(1)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        add_circle
                                                    </span>
                                                    {t('add_shareholder')}
                                                </Button>
                                            )}
                                            {inc >= 2 ? (
                                                <Button
                                                    className="addMore"
                                                    type="button"
                                                    onClick={() => {
                                                        removeSet(2)
                                                    }}
                                                >
                                                    <span className="material-icons">
                                                        remove_circle
                                                    </span>
                                                    {t('remove_shareholder')}
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                    <h2 className="dashTitle">Category Details</h2>
                                    <div className="row">{mapData(categoryDetails)}</div>

                                    <h2 className="dashTitle">Documents</h2>
                                    <div className="row">{mapData(fileUploads)}</div>
                                </div>
                            ) : (
                                <div>
                                    <h2 className="dashTitle">INDIVIDUAL</h2>
                                    <div className="row">
                                        {Object.values(mapData(IndividualRegistration))}
                                    </div>
                                </div>
                            )}
                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton
                                    type="submit"
                                    label={data.status === 'new' ? 'Submit' : 'Update'}
                                />
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
