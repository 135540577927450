import React, { useReducer } from 'react'
import VideoContext from './videoContext'
import videoReducer from './videoReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { VIDEO_INFORMATION, RESPONSE_STATUS, CLEAR_RESPONSE } from './videoTypes'

const VideoState = (props) => {
    const initialState = {
        video_information: null,
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(videoReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const startVideoStreaming = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'videoBroadcast', formData, '', 'user/video'),
            ])
            const from = 'videoBroadCasst'
            if (res.data.status === 'success') {
                dispatch({
                    type: VIDEO_INFORMATION,
                    payload: {
                        sdp: res.data.data.responseData.sdp ? res.data.data.responseData.sdp : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <VideoContext.Provider
            value={{
                responseStatus: state.responseStatus,
                video_information: state.video_information,
                clearResponse,
                startVideoStreaming,
            }}
        >
            {props.children}
        </VideoContext.Provider>
    )
}

export default VideoState
