import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import SettingContext from '../../product/context/setting/settingContext'
import AlertContext from '../../product/context/alert/alertContext'
import BidIncrementManage from './BidIncrementManage'

const BidIncrementView = () => {
    const settingContext = useContext(SettingContext)
    const [isLoading, setIsLoading] = useState(true)
    const {
        bid_increments,
        getAllBidIncrements,
        deleteBidIncrement,
        responseStatus: responseStatusCommuncation,
    } = settingContext
    const { setAlert } = useContext(AlertContext)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            searchterm: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllBidIncrements(values)
        },
    })

    useEffect(() => {
        if (bid_increments) {
            setTableBody(bid_increments.records.length ? bid_increments.records : [])
        }
        setIsLoading(false)
    }, [bid_increments])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.page = 1
        formik.values.searchterm = ''
        getAllBidIncrements(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [])

    useEffect(() => {
        if (responseStatusCommuncation) {
            if (
                responseStatusCommuncation.from === 'CommunicationStaticPages' &&
                responseStatusCommuncation.status === 'success'
            ) {
                toggleFullScreenPopup(false)
                setTableBody([])
                formik.values.page = 1
                formik.values.searchterm = ''
                getAllBidIncrements(formik.values)
                setAlert(responseStatusCommuncation.message, responseStatusCommuncation.status)
            }
        }
    }, [responseStatusCommuncation])

    const onClickEdit = (id) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    const deleteOnClick = (id) => {
        if (typeof id !== 'undefined' && id != 0) {
            let formData = { id: id }
            deleteBidIncrement(formData)
        }
    }

    const tableValues = [
        {
            field: 'from_amount',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'From Amount',
        },
        {
            field: 'to_amount',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'To Amount',
        },
        {
            field: 'increment',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Bid Increment',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            deleteLink: true,
            deleteOnClick: deleteOnClick,
        },
    ]

    const searchInfo = [
        {
            label: 'Page',
            placeholder: 'Enter Page',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
    ]

    return (
        <Layout>
            <div className="dashboard staticPages">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Bid Increment Range
                    </Button>
                </div>
                {isLoading ? (
                    <div>Loading..</div>
                ) : (
                    <CustomTable
                        formik={formik.values}
                        tableBody={tableBody}
                        tableValues={tableValues}
                        tableActions={[]}
                        allData={bid_increments}
                        changeTableFunction={getAllBidIncrements}
                    />
                )}
            </div>
            <BidIncrementManage data={manage} function={toggleFullScreenPopup} />
        </Layout>
    )
}
export default BidIncrementView
