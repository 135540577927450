import React, { useState, useRef, useEffect, useContext } from 'react'
import { Button, Fade, Tooltip } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import CustomInput from '../../../../product/components/atoms/Inputs/CustomInput'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../../../product/components/atoms/SecondaryButton'
import './ProductCard.css'
// import Popup from '../../../../custom/organisms/Popup'
import Timer from '../../../../product/common/timer'
import { dateFormatFront } from '../../../common/components'
import { handleRedirectInternal, currencyFormat } from '../../../common/components'
import CommonContext from '../../../../product/context/common/commonContext'
import AuthContext from '../../../../product/context/auth/authContext'
import Bidding from '../Bidding/BiddingItem'
const ListView = (props) => {
    const [addCard, setAddCard] = React.useState(false)
    const product = props.data
    const commonContext = useContext(CommonContext)
    const authContext = useContext(AuthContext)
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }
    const { user, isAuthenticated } = authContext
    const { setBidHistoryValue } = commonContext
    const history = useHistory()

    const toggleModal = (state, action) => {
        setAddCard(!addCard)
    }
    return (
        <Fade in={true} timeout={600}>
            <div className="productCardList d-flex justify-content-between align-items-start">
                <div className="psListLt d-flex">
                    <div className="pclImg">
                        <img
                            src={`${global.site_url}/uploads/product/${product.avatar}`}
                            alt={product.title}
                        />
                        <div className="listBidStatus"></div>
                    </div>

                    <div className="listContent">
                        <h2 onClick={props.drawerHandler} className="listProdTitle">
                            {product.title}
                        </h2>
                        <div className="listLotDetails">
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.desc_proc,
                                }}
                            ></p>
                        </div>

                        {product.market_status === 'open' ? (
                            <>
                                {product.auction ? (
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>{product.cbidtext}:</h6>
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>{currencyFormat(product.wprice)}</span>
                                            <span className="listDivider">|</span>
                                            <span
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.bid_count}
                                                {t('bids')}
                                            </span>
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}
                                {product.buynow ? (
                                    <div className="listBidInfo d-flex justify-content-start align-items-center">
                                        <h6>{t('buy_now_price')}:</h6>
                                        <p className="d-flex justify-content-between align-items-center">
                                            <span>{product.bprice} AED</span>
                                        </p>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </>
                        ) : (
                            <div className="listBidInfo d-flex justify-content-start align-items-center">
                                <h6>{product.cbidtext}:</h6>
                                <p className="d-flex justify-content-between align-items-center">
                                    <span>{currencyFormat(product.buynowamount)}</span>
                                    {product.market_status === 'open' ? (
                                        <>
                                            <span className="listDivider">|</span>
                                            <span
                                                className="cursorDecoy"
                                                onClick={() => setBidHistoryValue(product.id)}
                                            >
                                                {product.bid_count} {t('bids')}
                                            </span>
                                        </>
                                    ) : null}
                                </p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="listActBtn">
                    {product.market_status === 'open' ? (
                        <>
                            <div className="listTimer d-flex justify-content-center align-items-center flex-wrap">
                                <p className="d-flex align-items-center">
                                    <span className="material-icons">timer</span>
                                    {product.date_closed ? (
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            withText={1}
                                            endText={t('time_left') + ':'}
                                            startText={t('starts_in') + ':'}
                                        ></Timer>
                                    ) : null}
                                </p>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="listTimer d-flex justify-content-between align-items-center flex-wrap">
                                <h6 className="w-100">Closed on:</h6>
                                <p className="d-flex justify-content-center align-items-center w-100">
                                    {dateFormatFront(product.date_closed)}
                                </p>
                            </div>
                        </>
                    )}
                    {props.from === 'dashboard' &&
                    (props.action === 'won' || props.action === 'buynow') &&
                    product.buynowed &&
                    product.buynowed === user.id ? (
                        <div className="actionAfterWin">
                            {parseInt(product.buynowpaid, 10) === 1 ? (
                                <>
                                    <SecondaryButton
                                        label={t('view_invoice')}
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <PrimaryButton
                                        label={t('make_payment')}
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    />
                                </>
                            )}
                        </div>
                    ) : product.market_status === 'open' && !product.future_active ? (
                        <>
                            <div className="listBidBox">
                                {product.auction ? <Bidding data={product} type="hard" /> : null}
                            </div>
                        </>
                    ) : product.buynowed && product.buynowed === user.id ? (
                        <div className="actionAfterWin">
                            {parseInt(product.buynowpaid, 10) === 1 ? (
                                <>
                                    <SecondaryButton
                                        label={t('view_invoice')}
                                        btnSize="small"
                                        onClick={() =>
                                            handleRedirectInternal(
                                                history,
                                                `invoice/${product.common_invoice}`,
                                            )
                                        }
                                    />
                                </>
                            ) : (
                                <>
                                    <PrimaryButton
                                        label={t('make_payment')}
                                        btnSize="small"
                                        onClick={() => handleRedirectInternal(history, 'cart')}
                                    />
                                </>
                            )}
                        </div>
                    ) : null}
                    <div className="moreInfo">
                        <Button onClick={props.drawerHandler}>
                            {t('view_more_info')}{' '}
                            <span className="material-icons">arrow_right_alt</span>
                        </Button>
                    </div>
                </div>
            </div>
        </Fade>
    )
}
export default ListView
