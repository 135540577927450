// for common data
export const UPLOADED_IMAGE = 'UPLOADED_IMAGE'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_MAIN_DASHBOARD_DATA = 'GET_MAIN_DASHBOARD_DATA'
export const BID_HISTORY_VALUE = 'BID_HISTORY_VALUE'
export const CLEAR_BID_HISTORY = 'CLEAR_BID_HISTORY'
export const TTW_BID_HISTORY_VALUE = 'TTW_BID_HISTORY_VALUE'
export const TTW_CLEAR_BID_HISTORY = 'TTW_CLEAR_BID_HISTORY'

export const GET_ALL_COUNTRIES = 'GET_ALL_COUNTRIES'
export const GET_ALL_STATES = 'GET_ALL_STATES'
export const GET_ALL_CITIES = 'GET_ALL_CITIES'
