import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../product/components/templates/Layout'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import '../Dashboard/Dashboard.css'
import './invoice.css'
import CustomTable from '../../product/components/molecules/CustomTable'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import CartContext from '../../product/context/cart/cartContext'

import CustomSearch from '../../product/components/molecules/CustomSearch'
import ReportContext from '../../product/context/report/reportContext'
import CustomDialog from '../../product/components/organisms/Dialog'
import InvoiceView from './view'
import { Button } from '@material-ui/core'
import { useFormik } from 'formik'
import PaymentManage from './PaymentManage'
const Invoice = (props) => {
    const reportContext = useContext(ReportContext)
    const invoiceContext = useContext(InvoiceContext)
    const cartContext = useContext(CartContext)
    const [isLoading, setIsLoading] = useState(true)
    const { getAllExportDownload } = reportContext

    const {
        all_invoices,
        getAllInvoices,
        changeProductStatus,
        responseStatus: responseStatusInvoice,
    } = invoiceContext
    const { responseStatus: responseStatusCart } = cartContext

    const [changeStatus, setChangeStatus] = React.useState(false)

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        sid: 0,
    })

    const [manageCredits, setManageCredits] = useState({
        popup: false,
        status: 'new',
        id: 0,
        sid: 0,
    })

    const toggleFullScreenPopup = (popup, status, id, sid) => {
        if (popup) {
            setManage({ popup, status, id, sid })
        } else {
            setManage({ popup, status: 'new', id: 0, sid: 0 })
        }
    }

    const toggleCreditsPopup = (popup, status, id, sid) => {
        if (popup) {
            setManageCredits({ popup, status, id, sid })
        } else {
            setManageCredits({ popup, status: 'new', id: 0, sid: 0 })
        }
    }

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    let action = props.match.params.action

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? (action.toString() !== 'all' ? action.toString() : '') : null,
                    type: 'in',
                    field: 'b.paid',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'b.common_invoice',
                },
                fromdate: {
                    value: '',
                    type: 'like',
                    field: 'b.paid_date',
                },
                todate: {
                    value: '',
                    type: 'like',
                    field: 'b.paid_date',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllInvoices(values)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            project_id: '',
            auction_id: '',
        },
        onSubmit: (values) => {
            if (values.action === 'paid' || values.action === 'draft') {
                changeProductStatus(values)
            } else {
                assignAuctionLot(values)
            }
        },
    })

    useEffect(() => {
        if (all_invoices) {
            setTableBody(all_invoices.records.length ? all_invoices.records : [])
            setIsLoading(false)
        }
    }, [all_invoices])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.filters.fromdate.value = ''
        formik.values.filters.todate.value = ''
        formik.values.filters.searchterm.value = ''
        getAllInvoices(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    useEffect(() => {
        console.log('formikSelection.values', formikSelection.values)
    }, [formikSelection.values])

    const onClickView = (id, sid, type) => {
        console.log('sid', sid)
        console.log('typpeee', type)
        toggleFullScreenPopup(true, type, id, sid)
    }

    const onClickEdit = (id, secondaryID, type) => {
        toggleCreditsPopup(true, type, id, secondaryID)
    }

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.project_id = data
        if (action === 'paid' || action === 'draft') {
            setChangeStatus(true)
        }
    }

    const getMultipleAction = () => {
        let actionPush = [
            {
                buttonType: 'visibility',
                clickType: 'view',
                onclick: onClickView,
                tooltipTitle: 'View invoice',
            },
        ]
        if (action === 'unpaid' || action === 'draft') {
            actionPush.push({
                buttonType: 'monetization_on',
                clickType: 'edit',
                onclick: onClickEdit,
                tooltipTitle: 'Make payment',
            })
        }
        return actionPush
    }

    const tableValues = [
        {
            field: 'common_invoice',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Invoice ID',
        },
        {
            field: 'total_items',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Items',
        },
        {
            field: 'total_invoice',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
    ]
    if (action === 'unpaid' || action === 'draft') {
        tableValues.push({
            field: 'paid_amount',
            type: 'price',
            firstChild: false,
            disablePadding: false,
            label: 'Paid Amount',
        })
    }

    tableValues.push(
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'User/Company',
        },
        {
            field: 'users_email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        //date_added
        {
            field: 'date_added',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Created Date',
        },
    )

    if (action === 'paid') {
        tableValues.push({
            field: 'paid_date',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Paid Date',
        })
    }

    tableValues.push({
        field: 'action',
        type: 'button',
        isMultiple: true,
        clickID: 'common_invoice',
        secondaryClickID: 'user_id',
        sort: false,
        firstChild: false,
        disablePadding: false,
        label: 'Action',
        isMultiple: getMultipleAction(),
    })

    const tableActions = []

    if (action === 'paid') {
        tableActions.push({
            label: 'Move to Unpaid',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'draft',
        })
    } else if (action === 'unpaid' || action === 'draft') {
        tableActions.push({
            label: 'Move to Paid',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'paid',
        })
    } else {
        tableActions.push(
            {
                label: 'Move to Unpaid',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'draft',
            },
            {
                label: 'Move to Paid',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'paid',
            },
        )
    }

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Invoice Number',
                placeholder: 'Enter invoice number',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Created From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Created To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusInvoice) {
            if (
                responseStatusInvoice.status === 'success' &&
                responseStatusInvoice.from === 'productchange'
            ) {
                setChangeStatus(false)
                getAllInvoices(formik.values)
            }
        }
    }, [responseStatusInvoice])

    useEffect(() => {
        if (responseStatusCart) {
            if (responseStatusCart.status === 'success') {
                if (responseStatusCart.from === 'payCartWallet') {
                    toggleCreditsPopup(false)
                    getAllInvoices(formik.values)
                } else if (responseStatusCart.from === 'payCartOffline') {
                    toggleCreditsPopup(false)
                    getAllInvoices(formik.values)
                }
            }
        }
    }, [responseStatusCart])

    return (
        <Layout>
            <div className="dashboard invoice">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="d-flex justify-content-end">
                    <Button
                        onClick={() =>
                            getAllExportDownload({
                                action: 'export',
                                order: 'asc',
                                orderby: '',
                                search: 'all',
                                report: `${action}_invoice_management`,
                            })
                        }
                    >
                        <span className="material-icons">get_app</span>Export All Data
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_invoices}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllInvoices}
                />
            </div>
            <InvoiceView data={manage} function={toggleFullScreenPopup} />
            <PaymentManage data={manageCredits} function={toggleCreditsPopup} />
            <CustomDialog
                title={'Change product status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default Invoice
