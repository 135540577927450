import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import ProductContext from '../../product/context/product/productContext'
import CustomCommonContext from '../../custom/context/common/commonContext'
import ProductCommonContext from '../../product/context/common/commonContext'
import UserContext from '../../product/context/user/userContext'

import {
    mapData,
    dateFormatFunction,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LinearProgress } from '@material-ui/core'
import Loaders from '../../product/components/molecules/Loaders'

const SingleListing = (props) => {
    const { projectAction, single_project, getSingleProject } = useContext(ProductContext)
    const [isLoading, setIsLoading] = useState(false)
    const { resetDropZone } = useContext(ProductCommonContext)
    const [reload, setReload] = useState(false)

    const { allCategory, allBusinessCategory, allLocations, allCondition } = useContext(
        CustomCommonContext,
    )

    const { getAllUsers, all_users } = useContext(UserContext)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        location_id: Yup.string().required('Required!'),
        categoryTypeId: Yup.string().required('Required!'),
        subCategoryTypeId: Yup.string(),
        conditionTypeId: Yup.string().required('Required!'),
        selltype: Yup.boolean(),
        business_categoryid: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array().required('Required!'),
        }),
        selectedbuyer: Yup.array().when('selltype', {
            is: (avatar) => avatar,
            then: Yup.array().required('Required!'),
        }),
        date_added: Yup.string().required('Required!').nullable(),
        date_closed: Yup.string().required('Required!').nullable(),
        qty: Yup.number().required('Required!').positive('Should be greater than 1'),
        desc_proc: Yup.string().required('Required!'),
        auction: Yup.boolean().when('buynow', {
            is: false,
            then: Yup.boolean().oneOf([true], 'Enable Auction or Buy Now'),
        }),
        sprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number().required('Required!').positive('Should be greater than 1'),
        }),
        rprice: Yup.number().when('auction', {
            is: true,
            then: Yup.number()
                .positive('Should be greater than 1')
                .min(Yup.ref('sprice'), 'Should be equal or greater than the starting price')
                .required('Required!'),
        }),
        buynow: Yup.boolean(),
        bprice: Yup.number().when('buynow', {
            is: true,
            then: Yup.number()
                .required('Required!')
                .positive('Should be greater than 1')
                .when('auction', {
                    is: true,
                    then: Yup.number().moreThan(
                        Yup.ref('rprice'),
                        'Should be greater than the reserve price',
                    ),
                }),
        }),
        deposit: Yup.boolean(),
        deposit_amount: Yup.number().when('deposit', {
            is: true,
            then: Yup.number()
                .required('Required!')
                .positive('Should be greater than 1')
                .when('auction', {
                    is: true,
                    then: Yup.number().lessThan(
                        Yup.ref('rprice'),
                        'Should be lesser than the reserve price',
                    ),
                }),
        }),
        uploadimages: Yup.array().required('Required!'),
        company: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            location_id: '',
            categoryTypeId: '',
            subCategoryTypeId: '',
            business_categoryid: [],
            conditionTypeId: '',
            selltype: false,
            selectedbuyer: [],
            date_added: null,
            date_closed: null,
            qty: 0,
            desc_proc: '',
            auction: false,
            sprice: 0,
            rprice: 0,
            buynow: false,
            bprice: 0,
            deposit: false,
            deposit_amount: 0,
            uploadimages: [],
            market_status: 'open',
            auctiontype: 'live',
            group_carts: 1,
            local_pickup_check: 1,
            shipping_check: 0,
            company: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            const value = values
            value.date_added = moment(value.date_added, 'MM-DD-YYYY h:mm a').format()
            value.date_closed = moment(value.date_closed, 'MM-DD-YYYY h:mm a').format()
            projectAction(value)
        },
    })

    const formSubmit = (data) => {
        formik.values.market_status = data
        formik.submitForm().then(setIsLoading(false)).catch(setIsLoading(false))
    }

    useEffect(() => {
        if (data.status === 'edit') {
            setIsLoading(true)
            formik.values.id = data.id
            getSingleProject(formik.values)
        } else {
            setIsLoading(false)
            formik.values.id = 0
            resetFrom()
        }
    }, [data])
    useEffect(() => {
        if (data.status == 'new') {
            console.log(data.status, 'data.status')
            setIsLoading(false)
        }
    }, [data.status])
    useEffect(() => {
        setIsLoading(false)
    }, [])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    useEffect(() => {
        if (formik.values.business_categoryid.length) {
            let allBuyers = []
            all_users.records.map((user) => {
                allBuyers.push(parseInt(user.id, 10))
                return true
            })
            if (data.status !== 'edit') {
                formik.values.selectedbuyer = allBuyers
            }
            setReload(!reload)
        }
    }, [all_users])

    useEffect(() => {
        if (formik.values.business_categoryid) {
            console.log('formik.values.business_categoryid', formik.values.business_categoryid)
            getAllUsers({
                email: '',
                first_name: '',
                last_name: '',
                page: 1,
                limit: 1000,
                order: '',
                orderby: '',
                filters: {
                    action: {
                        value: 'active',
                        type: 'in',
                        field: 'u.status',
                    },
                    business_category: {
                        value: formik.values.business_categoryid
                            ? formik.values.business_categoryid
                            : [],
                        type: 'findinarray',
                        field: 'cu.business_category',
                    },
                },

                business_categoryid: formik.values.business_categoryid,
            })
        }
    }, [formik.values.business_categoryid])

    useEffect(() => {
        if (single_project.record && data.status === 'edit') {
            const project = single_project.record
            formik.values.title = project.title
            formik.values.location_id = project.location_id
            formik.values.categoryTypeId = project.categoryTypeId
            formik.values.subCategoryTypeId = project.subCategoryTypeId
                ? project.subCategoryTypeId
                : ''
            formik.values.business_categoryid = project.business_categoryid
                ? project.business_categoryid.toString().split(',').map(Number)
                : []
            formik.values.conditionTypeId = project.conditionTypeId
            formik.values.selltype = project.selltype === '1' ? true : false
            formik.values.selectedbuyer = project.selectedbuyer
                ? project.selectedbuyer.split(',').map(Number)
                : []
            formik.values.date_added = dateTimeFormatFunction(project.date_added)
            formik.values.date_closed = dateTimeFormatFunction(project.date_closed)
            formik.values.qty = project.qty
            formik.values.desc_proc = project.desc_proc
            formik.values.auction = project.auction
            formik.values.sprice = project.sprice
            formik.values.rprice = project.rprice
            formik.values.buynow = project.buynow
            formik.values.bprice = project.bprice
            formik.values.deposit = project.deposit
            formik.values.deposit_amount = project.deposit_amount
            formik.values.company = project.company

            formik.values.uploadimages = project.uploadimages
                ? project.uploadimages.toString().split(',')
                : []
            formik.values.market_status = project.market_status
            setReload(!reload)
            setIsLoading(false)
        }
    }, [single_project])

    // useEffect(() => {
    //     console.log('formik.values', formik.values)
    // }, [formik.values])

    const resetFrom = () => {
        console.log('reset form')
        formik.values.id = 0
        formik.values.title = ''
        formik.values.location_id = ''
        formik.values.categoryTypeId = ''
        formik.values.subCategoryTypeId = ''
        formik.values.business_categoryid = []
        formik.values.conditionTypeId = ''
        formik.values.selltype = false
        formik.values.selectedbuyer = []
        formik.values.date_added = null
        formik.values.date_closed = null
        formik.values.qty = 0
        formik.values.desc_proc = ''
        formik.values.auction = false
        formik.values.sprice = 0
        formik.values.rprice = 0
        formik.values.buynow = false
        formik.values.bprice = 0
        formik.values.deposit = false
        formik.values.deposit_amount = 0
        formik.values.company = ''
        formik.values.uploadimages = []
        formik.values.market_status = 'open'
        formik.handleReset()
        resetDropZone('uploadimages')
        setReload(!reload)
    }

    const productListing = [
        {
            label: 'Lisiting Title *',
            type: 'text',
            placeholder: 'Enter your Listing Title',
            class: 'col-sm-6 col-12',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Location *',
            placeholder: 'Select location',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allLocations
                .filter((value) => value.active === 1 || formik.values.location_id === value.id)
                .map((location) => {
                    let locationChanged = {}
                    locationChanged.show = location.name
                    locationChanged.value = location.id
                    return locationChanged
                }),
            defaultOptionValue: 'Select a Location',
            name: 'location_id',
            formik: formik,
        },
        {
            label: 'Category *',
            placeholder: 'Select category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter(
                    (maincat) =>
                        (maincat.active === 1 ||
                            (formik.values.categoryTypeId &&
                                formik.values.categoryTypeId === maincat.id)) &&
                        maincat.level === 0,
                )
                .map((maincat) => {
                    let categoryChanged = {}
                    categoryChanged.show = maincat.description
                    categoryChanged.value = maincat.id
                    return categoryChanged
                }),
            defaultOptionValue: 'Select a Category',
            name: 'categoryTypeId',
            formik: formik,
        },
        {
            label: 'Sub-Category',
            placeholder: 'Select sub-category',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCategory
                .filter((subcat) => {
                    if (formik.values.categoryTypeId && parseInt(subcat.level, 10) === 1) {
                        if (
                            parseInt(subcat.active, 10) === 1 ||
                            (formik.values.subCategoryTypeId &&
                                formik.values.subCategoryTypeId === subcat.id)
                        ) {
                            return (
                                parseInt(formik.values.categoryTypeId, 10) ===
                                parseInt(subcat.level_id, 10)
                            )
                        } else {
                            return false
                        }
                    } else {
                        return false
                    }
                })
                .map((subcat) => {
                    let subCategoryChanged = {}
                    subCategoryChanged.show = subcat.description
                    subCategoryChanged.value = subcat.id
                    return subCategoryChanged
                }),
            defaultOptionValue: 'Select a Sub Category',
            name: 'subCategoryTypeId',
            formik: formik,
        },
        {
            label: 'Condition *',
            placeholder: 'Select condition',
            type: 'select',
            class: 'col-sm-6 col-12',
            options: allCondition
                .filter(
                    (maincat) =>
                        maincat.active === 1 ||
                        (formik.values.conditionTypeId &&
                            formik.values.conditionTypeId === maincat.id),
                )
                .map((location) => {
                    let conditionChanged = {}
                    conditionChanged.show = location.description
                    conditionChanged.value = location.id
                    return conditionChanged
                }),
            defaultOptionValue: 'Select a Condition',
            name: 'conditionTypeId',
            formik: formik,
        },
        {
            label: 'Company *',
            type: 'text',
            placeholder: 'Enter company name',
            class: 'col-sm-6 col-12',
            name: 'company',
            formik: formik,
        },
        {
            label: 'Only Invited',
            type: 'checkbox',
            class: 'col-12',
            name: 'selltype',
            formik: formik,
        },
        {
            label: 'Business Category',
            placeholder: 'Select condition',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: allBusinessCategory
                .filter(
                    (value) =>
                        value.active === 1 || formik.values.business_categoryid.includes(value.id),
                )
                .map((user) => {
                    let userChanged = {}
                    userChanged.show = user.description
                    userChanged.value = user.id
                    return userChanged
                }),
            disabled: formik.values.selltype ? false : true,
            defaultOptionValue: 'Select a Business Category',
            name: 'business_categoryid',
            formik: formik,
        },
        {
            label: 'Invited Company',
            placeholder: 'Select condition',
            type: 'multiselect',
            class: 'col-sm-6 col-12',
            options: formik.values.business_categoryid.length
                ? all_users.records.map((user) => {
                      let userChanged = {}
                      userChanged.show = user.companyname + ' (' + user.email + ')'
                      userChanged.value = user.id
                      return userChanged
                  })
                : [],
            disabled: formik.values.selltype ? false : true,
            defaultOptionValue: 'Select a Condition',
            name: 'selectedbuyer',
            formik: formik,
        },

        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            disablePast: true,
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_added',
            formik: formik,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',
            disablePast: true,
            type: 'datetime',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_closed',
            minDate: dateTimeFormatFunction(formik.values.date_added),
            minDateMessage: `Date should not be before minimal date ${dateTimeFormatFunction(
                formik.values.date_added,
            )}`,

            formik: formik,
        },
        {
            label: 'Quantity *',
            type: 'number',
            placeholder: 'Enter Quantity',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'qty',
            formik: formik,
        },
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'ckeditor',
            class: 'col-12',
            name: 'desc_proc',
            formik: formik,
        },
        {
            label: 'Enable Auction',
            type: 'checkbox',
            class: 'col-6',
            name: 'auction',
            formik: formik,
        },
        {
            label: 'Auction start price',
            placeholder: 'Enter starting price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'sprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Auction reserve price',
            placeholder: 'Enter reserve price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'rprice',
            disabled: formik.values.auction ? false : true,
            formik: formik,
        },
        {
            label: 'Enable Buy Now',
            type: 'checkbox',
            class: 'col-6',
            name: 'buynow',
            formik: formik,
        },
        {
            label: 'Buy Now price',
            placeholder: 'Enter buy now price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'bprice',
            disabled: formik.values.buynow ? false : true,
            formik: formik,
        },
        {
            content: '(Per Quantity)',
            type: 'misc',
        },
        {
            label: 'Enable Deposit',
            type: 'checkbox',
            class: 'col-6',
            name: 'deposit',
            formik: formik,
        },
        {
            label: 'Deposit price',
            placeholder: 'Enter Deposit price',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'deposit_amount',
            disabled: formik.values.deposit ? false : true,
            formik: formik,
        },
        {
            type: 'uploadDropZone',
            formik: formik,
            name: 'uploadimages',
            titleText: 'Upload product images *',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'product',
            multiple: true,
            accept: 'image/*',
        },
        // {
        //     label: 'Tax rate type',
        //     placeholder: 'Enter tax rate type',
        //     type: 'select',
        //     options: [],
        //     class: 'col-3',
        //     name: 'tax_rate_type',
        //     formik: formik,
        // },
        // {
        //     label: 'Tax rate code',
        //     placeholder: 'Enter tax rate code',
        //     type: 'text',
        //     class: 'col-3',
        //     name: 'tax_rate_code',
        //     formik: formik,
        // },

        // {
        //     label: 'Tax rate',
        //     placeholder: 'Enter tax rate',
        //     type: 'number',
        //     class: 'col-3',
        //     name: 'tax_rate',
        //     formik: formik,
        //     endAdornment: '%',
        // },
    ]

    useEffect(() => {
        if (formik.values.date_closed && formik.values.date_added) {
            if (formik.values.date_closed <= formik.values.date_added) {
                formik.values.date_closed = null
                setReload(!reload)
            }
        }
    }, [formik.values.date_added, formik.values.date_closed])

    useEffect(() => {
        if (!formik.values.selltype) {
            formik.values.business_categoryid = []
            formik.values.selectedbuyer = []
            setReload(!reload)
        }
    }, [formik.values.selltype])

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW LISTING'
                    : `EDIT LISTING: ${single_project.record.title} `
            }
            open={data.popup}
            handleClose={() => {
                setIsLoading(false)
                toggleFullScreenPopup(false)
            }}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    {formik.isSubmitting ? <LinearProgress /> : null}
                    {isLoading ? (
                        <div className="fspLoader">
                            <Loaders isLoading={isLoading} />
                        </div>
                    ) : (
                        <>
                            {data.status === 'edit' && (
                                <div className="listingInfo">
                                    <span className="material-icons">info</span>
                                    <h6>
                                        Created at:
                                        <span>
                                            {dateTimeFormatFunction(
                                                single_project.record.created_at,
                                            )}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>
                                    <h6>
                                        Last update at:
                                        <span>
                                            {dateTimeFormatFunction(
                                                single_project.record.updated_at,
                                            )}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>

                                    <h6>
                                        Posted by:
                                        <span>
                                            {single_project.record.as_employees_emp_firstname}{' '}
                                            {single_project.record.as_employees_emp_lastname}{' '}
                                        </span>
                                    </h6>
                                    <span className="liDivider">|</span>
                                    <h6>
                                        Bid Count:
                                        <span>{single_project.record.bidscount}</span>
                                    </h6>
                                </div>
                            )}
                            {/* <form onSubmit={formik.handleSubmit} autoComplete="nofill"> */}
                            <div className="row">{Object.values(mapData(productListing))}</div>
                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                <SecondaryButton
                                    label="Cancel"
                                    onClick={() => {
                                        setIsLoading(false), toggleFullScreenPopup(false)
                                    }}
                                />
                                {formik.values.id ? (
                                    <>
                                        {formik.values.market_status === 'draft' && (
                                            <>
                                                <SecondaryButton
                                                    onClick={() => {
                                                        formSubmit('draft')
                                                    }}
                                                    type="button"
                                                    label="Save as Draft"
                                                />
                                                <PrimaryButton
                                                    onClick={() => {
                                                        formSubmit('open')
                                                    }}
                                                    type="button"
                                                    label="Submit"
                                                />
                                            </>
                                        )}
                                        {formik.values.market_status === 'open' && (
                                            <>
                                                {single_project?.record?.bidscount === 0 &&
                                                    single_project?.record?.sold <= 0 && (
                                                        <>
                                                            <PrimaryButton
                                                                onClick={() => {
                                                                    formSubmit('open')
                                                                }}
                                                                type="button"
                                                                label="Update"
                                                            />
                                                        </>
                                                    )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <SecondaryButton
                                            onClick={() => {
                                                formSubmit('draft')
                                            }}
                                            type="button"
                                            label="Save as Draft"
                                        />
                                        <PrimaryButton
                                            onClick={() => {
                                                formSubmit('open')
                                            }}
                                            type="button"
                                            label="Submit"
                                        />
                                    </>
                                )}
                            </div>
                            {/* </form> */}
                        </>
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default SingleListing
