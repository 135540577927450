import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../FullScreenPopup'
import { useFormik } from 'formik'
import { Button } from '@material-ui/core'
import CustomTable from '../../molecules/CustomTable'
import ProductContext from '../../../context/product/productContext'
import CommonContext from '../../../context/common/commonContext'
import ReportContext from '../../../context/report/reportContext'

const BidHistory = () => {
    const productContext = useContext(ProductContext)
    const {
        search_allttwbidhistory,
        getAllTtwBidHistory,
        bidHistoryUpdate,
        responseStatus: ttwBidUpdateStatus,
    } = productContext

    const commonContext = useContext(CommonContext)
    const { ttwBidHistoryValue, clearTtwBidHistoryValue } = commonContext

    const { getAllExportDownload } = useContext(ReportContext)

    const [updateData, setUpdate] = useState({})
    const [isInputHide, setInputHide] = useState(false)

    const [tableBody, setTableBody] = useState([])
    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            get_proxy: false,
            ttwOffers: false,
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
            },
            id: '',
        },
        onSubmit: (values) => {
            getAllTtwBidHistory(values)
        },
    })

    useEffect(() => {
        if (ttwBidHistoryValue) {
            if (ttwBidHistoryValue.id) {
                formikData.values.filters.project_id.value = ttwBidHistoryValue.id
                formikData.values.id = ttwBidHistoryValue.id
                formikData.values.ttwOffers = ttwBidHistoryValue.ttwOffers
                setInputHide(ttwBidHistoryValue.secondaryID ? true : false)
            } else {
                formikData.values.ttwOffers = false
                formikData.values.filters.project_id.value = ttwBidHistoryValue
                formikData.values.id = ttwBidHistoryValue
                formikData.setFieldValue('filters', formikData.values.filters)
            }
            setData({ popup: true, id: ttwBidHistoryValue })
            clearTtwBidHistoryValue()
            getAllTtwBidHistory(formikData.values)
            setUpdate({})
        }
    }, [ttwBidHistoryValue])

    useEffect(() => {
        return () => {
            clearTtwBidHistoryValue()
        }
    }, [])

    useEffect(() => {
        if (formikData.values.filters.project_id.value !== 0) {
            getAllTtwBidHistory(formikData.values)
            setUpdate({})
        }
    }, [formikData.values.filters.project_id.value])

    useEffect(() => {
        if (ttwBidUpdateStatus && ttwBidUpdateStatus.from === 'bidHistoryUpdate') {
            getAllTtwBidHistory(formikData.values)
            setUpdate({})
        }
    }, [ttwBidUpdateStatus])

    useEffect(() => {
        if (search_allttwbidhistory && search_allttwbidhistory.records) {
            search_allttwbidhistory.totalRecords = search_allttwbidhistory.totalRecords
            search_allttwbidhistory.records.map((data) => {
                data.proposed_amount =
                    data.update_proposed_amount > 0
                        ? data.update_proposed_amount
                        : data.proposed_amount

                return data
            })
            setTableBody(
                search_allttwbidhistory.records.length ? search_allttwbidhistory.records : [],
            )
        }
    }, [search_allttwbidhistory])

    const tableValues = [
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'first_name,last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
    ]

    if (formikData.values.get_proxy) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Max',
                },
                false: {
                    type: 'value',
                    value: 'bidText',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        })
    }

    const onClickEdit = (event, id) => {
        if (event.keyCode === 13) {
            bidHistoryUpdate({
                id: id,
                proposed_amount: event.target.value,
            })
        } else {
            setUpdate({ ['proposed_amount' + id]: event.target.value })
        }
    }

    if (formikData.values.ttwOffers) {
        tableValues.push({
            field: 'proposed_amount',
            type: 'input',
            clickID: 'bidid',
            sort: true,
            firstChild: false,
            disablePadding: false,
            value: updateData,
            onChange: onClickEdit,
            onClick: onClickEdit,
            label: 'Share',
            disabled: isInputHide,
        })
    } else {
        tableValues.push(
            {
                field: 'init_proposed_amount',
                type: '',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'Initial Qty',
            },
            {
                field: 'proposed_amount',
                type: '',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'Qty',
            },
            {
                field: 'currentbid_increment',
                type: 'price',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'Amount',
            },
        )
    }

    const tableActions = []

    return (
        <FullScreenPopup
            modaltitle={`Bid History`}
            open={data.popup}
            handleClose={() => {
                setData({ ...data, popup: false })
                clearTtwBidHistoryValue()
            }}
        >
            <div className="wallet">
                {/* <Button
                    onClick={() =>
                        getAllExportDownload({
                            action: 'export',
                            order: 'asc',
                            orderby: '',
                            search: `b.project_id=${formikData.values.filters.project_id.value}`,
                            report: `bid_history`,
                        })
                    }
                >
                    {' '}
                    <span className="material-icons">get_app</span>Export All Data
                </Button> */}
                <div className="fspBody">
                    <CustomTable
                        formik={formikData.values}
                        tableBody={tableBody}
                        tableValues={tableValues}
                        tableActions={tableActions}
                        allData={search_allttwbidhistory}
                        changeTableFunction={getAllTtwBidHistory}
                    />
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default BidHistory
