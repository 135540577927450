import React, { useState, useEffect, useContext, useRef } from 'react'
import Layout from '../../product/components/templates/Layout'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { mapData } from '../../product/common/components'
import './LanguageManager.css'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import CustomTable from '../../product/components/molecules/CustomTable'
import EmployeeContext from '../../product/context/employee/empContext'
import LanguageManagerContext from '../../product/context/languageManager/languageManagerContext'
import AlertContext from '../../product/context/alert/alertContext'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { Launch } from '@material-ui/icons'

function TabPanel(props) {
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    )
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    }
}

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
    },
}))

function LanguageManger() {
    const languageManagerContext = useContext(LanguageManagerContext)
    const employeeContext = useContext(EmployeeContext)
    const alertContext = useContext(AlertContext)
    const classes = useStyles()
    const [value, setValue] = useState(0)
    const [tableBody, setTableBody] = useState([])
    const [group, setGroup] = useState([])
    const [groupTableList, setGroupTableList] = useState([])
    const [totalGroup, setTotalGroup] = useState([])
    const [languages, setLanguage] = useState([])
    const [phrases, setPhrases] = useState([])
    const [phrasesTotalPage, setPhrasesTotalPage] = useState(0)
    const [tempVar, setTempVar] = useState('')
    const [modalOpen, setModalOpen] = useState(false)
    const [isEdit, setIsEdit] = useState(false)

    const {
        getAllLanguage,
        addGroup,
        addPhrse,
        responseStatus: responseStatusLanguageManager,
        all_language,
        getAllLanguageWithPhrase,
        searchPhraseByLanguage,
        getAllGroup,
        allLanguageGroup,
        importPhrase,
    } = languageManagerContext
    const { setAlert } = alertContext
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const { all_employees, getAllEmployees } = employeeContext

    const validationArray = [
        Yup.object({
            group_id: Yup.string().required('Required!'),
            phrase: Yup.string().required('Required!'),
            temp_var: Yup.string().required('Required!'),
        }),
        Yup.object({
            group_name: Yup.string().required('Required!'),
            group_desc: Yup.string().required('Required!'),
        }),
        Yup.object({
            lang_list: Yup.string(),
        }),
        Yup.object({
            uploadFiles: Yup.array().required('Required!'),
            activeLang: Yup.string().required('Required!'),
        }),
    ]

    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            group_id: '',
            phrase: '',
            temp_var: '',
        },
        validationSchema: validationArray[0],
        onSubmit: async (values) => {
            values.sellang = searchFormik.values.lang_list
            values.selgroup = values.group_id
            await addPhrse(values)
            await getAllLanguage()
            searchFormik.handleSubmit()
        },
    })

    const csvImportFormik = useFormik({
        initialValues: {
            activeLang: '',
            uploadFiles: [],
        },
        validationSchema: validationArray[3],
        onSubmit: (values) => {
            importPhrase(values)
        },
    })

    const searchFormik = useFormik({
        initialValues: {
            searchkey: '',
            lang_list: 'for_english',
            selgroup: '',
            limit: 5,
            page: 1,
        },
        validationSchema: validationArray[2],
        onSubmit: (values) => {
            getAllLanguageWithPhrase(values)
        },
    })

    const Gropuformik = useFormik({
        initialValues: {
            group_name: '',
            group_desc: '',
            limit: 5,
            page: 1,
        },
        validationSchema: validationArray[1],
        onSubmit: async (values) => {
            await addGroup(values)
            getAllGroup(values)
            getAllLanguage()
        },
    })

    useEffect(() => {
        getAllLanguage()
        getAllGroup(Gropuformik.values)
        searchFormik.handleSubmit()
    }, [])

    useEffect(() => {
        if (responseStatusLanguageManager) {
            if (responseStatusLanguageManager.status === 'success') {
                if (responseStatusLanguageManager.from === 'addphrase') {
                    toggleFullScreenPopup(false)
                }
            }
        }
    }, [responseStatusLanguageManager])

    useEffect(() => {
        if (Object.keys(all_language.records).length > 0) {
            setGroup(all_language.records.grops)
            setLanguage(all_language.records.languages)
            setPhrases(all_language.records.phraseslanguage)
        }
    }, [all_language])

    useEffect(() => {
        if (searchPhraseByLanguage) {
            setPhrases(searchPhraseByLanguage.records)
            setPhrasesTotalPage(searchPhraseByLanguage.totalRecords)
        }
    }, [searchPhraseByLanguage])

    useEffect(() => {
        if (allLanguageGroup) {
            setGroupTableList(allLanguageGroup.records)
            setTotalGroup(allLanguageGroup.totalRecords)
        }
    }, [allLanguageGroup])

    const optionMaker = (data, key, value) =>
        data.map((data) => {
            return { show: data[key], value: data[value] }
        })

    const converTemplateVar = (value) => {
        let tempVar = formik.values.phrase
            .toString()
            .trim()
            .toLowerCase()
            .replace(/ /g, '_')
            .replace(/  /g, '_')
        setTempVar(tempVar)
        formik.setValues({
            ...formik.values,
            temp_var: tempVar,
        })
    }

    const toggleFullScreenPopup = (value) => {
        formik.setValues({
            group_id: '',
            phrase: '',
            temp_var: '',
        })
        setModalOpen(value)
        setIsEdit(false)
    }

    const onClickEdit = (id) => {
        let phrase = phrases.find((phrase) => phrase.id == id)
        let group_id = group.find((group) => (group.groupname = phrase.groupname))
        formik.setValues({
            group_id: group_id.id,
            phrase: phrase[searchFormik.values.lang_list],
            temp_var: phrase.phrase,
            id: phrase.id,
        })
        setModalOpen(true)
        setIsEdit(true)
    }

    const downloadSample = () => window.open(`${global.site_url}/samples/phrases.csv`)

    const tableValuesGroup = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Group Id',
        },
        {
            field: 'groupname',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Group Name',
        },
    ]

    const tableValuesPhraseList = [
        {
            field: 'phrase',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Phrase Identifier',
        },
        {
            field: `${searchFormik.values.lang_list || 'for_english'}`,
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Phrases Text',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit language phrase',
        },
    ]

    const tableActions = []

    const instructions = [
        'Create a variable from selecting the phrase tab.',
        'In phrase tab create a variable with the appropriate english word.',
        'To create a group select the Group Panel.',
        'Create variable and match with the group for reference.',
        'Once variable is created you can add other language words by selecting the phrase list.',
    ]

    const csvInstructions = [
        'Download the language phrase List.',
        'Add a new column for the language phrases you want to add in the format, language name in small letter preceded by `for_`(no inverted commas). For Eg: If you want to add Arabic, add a column named for_arabic in your csv.',
        'Add respective language phrase to the phrase words in the left column.',
        'Save CSV.',
        'Select the language from the list.',
        'Upload CSV.',
        'Click Save.',
    ]

    const createPhrase = [
        {
            label: 'Select language Group',
            placeholder: 'Select language Group',
            name: 'group_id',
            type: 'select',
            options: optionMaker(group, 'groupname', 'id'),
            class: 'col-12',
            formik: formik,
        },
        {
            label: 'Create new phrase',
            name: 'phrase',
            type: 'textarea',
            placeholder: 'Create a new phrase',
            class: 'col-12',
            formik: formik,
        },
    ]

    const templateVariable = [
        {
            label: 'Template variable',
            name: 'temp_var',
            type: 'text',
            placeholder: 'Enter template variable',
            class: `col-12 ${isEdit ? 'd-none' : ''}`,
            formik: formik,
            value: tempVar,
        },
    ]

    const langGroup = [
        {
            label: 'Group name',
            name: 'group_name',
            type: 'text',
            placeholder: 'Enter group name',
            class: 'col-12',
            formik: Gropuformik,
        },
        {
            label: 'Description',
            name: 'group_desc',
            type: 'textarea',
            placeholder: 'Enter description',
            class: 'col-12',
            formik: Gropuformik,
        },
    ]

    const csvImport = [
        {
            label: 'Select Language to Import',
            placeholder: 'Select Language to Import',
            name: 'activeLang',
            type: 'select',
            options: optionMaker(languages, 'language', 'language_slug'),
            class: 'col-12',
            formik: csvImportFormik,
        },
        {
            type: 'uploadDropZone',
            formik: csvImportFormik,
            name: 'uploadFiles',
            titleText: 'Upload the csv file',
            innerText: 'Recommended dimensions: 500px * 500px',
            class: 'col-12',
            folder: 'temp',
            multiple: false,
            accept: '.csv',
        },
    ]

    const phraseList = [
        {
            label: 'Search',
            name: 'searchkey',
            type: 'search',
            placeholder: 'Enter keyword here',
            class: 'col-12 col-sm-4',
            formik: searchFormik,
        },
        {
            label: 'Language',
            placeholder: 'Select Language',
            name: 'lang_list',
            type: 'select',
            options: optionMaker(languages, 'language', 'language_slug'),
            class: 'col-12 col-sm-4',
            formik: searchFormik,
        },
        {
            label: 'Select Group',
            placeholder: 'Select Group',
            name: 'selgroup',
            type: 'select',
            options: optionMaker(group, 'groupname', 'id'),
            class: 'col-12 col-sm-4',
            formik: searchFormik,
        },
    ]

    return (
        <Layout>
            <div className="languageManager">
                <div className="langTabs">
                    <AppBar position="static">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="Language Manage Tabs"
                        >
                            <Tab label="Instructions" {...a11yProps(0)} />
                            <Tab label="Groups" {...a11yProps(1)} />
                            <Tab label="CSV Import" {...a11yProps(2)} />
                            <Tab label="Phrase List" {...a11yProps(3)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={value} index={0}>
                        <div className="langHead">
                            <h3>Instructions for Language Manager</h3>
                        </div>
                        <ol className="langInstructions">
                            {instructions.map((data, index) => (
                                <li key={index}>{data}</li>
                            ))}
                        </ol>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <div className="row">
                            {Object.values(mapData(langGroup))}
                            <div className="col-12 d-flex justify-content-end mb-3">
                                <PrimaryButton
                                    label="Submit"
                                    onClick={Gropuformik.handleSubmit}
                                    type="submit"
                                />
                            </div>
                            <div className="col-12">
                                <CustomTable
                                    formik={Gropuformik.values}
                                    tableBody={groupTableList}
                                    tableValues={tableValuesGroup}
                                    tableActions={tableActions}
                                    allData={allLanguageGroup}
                                    changeTableFunction={getAllGroup}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                        <div className="row">
                            {Object.values(mapData(csvImport))}
                            <div className="col-12 csvActBtn d-flex justify-content-end">
                                <SecondaryButton
                                    onClick={csvImportFormik.handleSubmit}
                                    label="Save"
                                    type="button"
                                />
                                <SecondaryButton
                                    onClick={downloadSample}
                                    label="Download Phrase List"
                                />
                            </div>
                            <div className="col-12">
                                <ol className="langInstructions">
                                    {csvInstructions.map((data, index) => (
                                        <li key={index}>{data}</li>
                                    ))}
                                </ol>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={3}>
                        <div className="row">
                            <CustomSearch searchInfo={phraseList} formik={searchFormik} />
                        </div>
                        <div className="row">
                            <div className="col-auto">
                                <PrimaryButton
                                    type="button"
                                    label="Add New Phrase"
                                    onClick={() => toggleFullScreenPopup(true)}
                                ></PrimaryButton>
                            </div>
                            <div className="col-12">
                                <CustomTable
                                    formik={searchFormik.values}
                                    tableBody={searchPhraseByLanguage.records || []}
                                    tableValues={tableValuesPhraseList}
                                    tableActions={tableActions}
                                    allData={searchPhraseByLanguage}
                                    changeTableFunction={getAllLanguageWithPhrase}
                                />
                            </div>
                        </div>
                    </TabPanel>
                    <FullScreenPopup
                        modaltitle={!isEdit ? 'ADD NEW PHRASE' : `EDIT PHRASE`}
                        open={modalOpen}
                        handleClose={() => toggleFullScreenPopup(false)}
                    >
                        <div className="container">
                            <div className="langHead">
                                <h3>{isEdit ? 'Edit' : 'Create a New'} Language Phrase</h3>
                            </div>
                            <div className="langPhrase">
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <h5>
                                            You can create new language phrases and call them as
                                            (variables) from your html templates below
                                        </h5>

                                        <div className="phraseSelected">
                                            <div className="psInner">
                                                <label>
                                                    {isEdit ? 'Selected' : 'Default'} language:
                                                </label>
                                                <h6>
                                                    {isEdit
                                                        ? searchFormik.values.lang_list
                                                        : 'English'}
                                                </h6>
                                            </div>
                                            <span className="vertDivider"></span>
                                            {isEdit && (
                                                <div className="psInner">
                                                    <label>Phrase:</label>
                                                    <h6>{formik.values.temp_var}</h6>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {Object.values(mapData(createPhrase))}
                                    <div className={`col-auto mb-4 ${isEdit ? 'd-none' : ''}`}>
                                        <SecondaryButton
                                            label="Convert into template variable"
                                            type="submit"
                                            onClick={converTemplateVar}
                                        />
                                    </div>
                                    {Object.values(mapData(templateVariable))}
                                    <div className="col-auto d-flex justify-content-end">
                                        <PrimaryButton
                                            label="Submit"
                                            type="button"
                                            onClick={formik.handleSubmit}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </FullScreenPopup>
                </div>
            </div>
        </Layout>
    )
}

export default LanguageManger
