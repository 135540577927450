import React, { useContext, useState, useEffect } from 'react'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import { Country as CSCCountry, State as CSCState } from 'country-state-city'
import { mapData, handleRedirectInternal } from '../../product/common/components'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import AuthContext from '../../product/context/auth/authContext'
import Layout from '../../product/components/templates/Layout'
import Loaders from '../../product/components/molecules/Loaders'

const MyAccount = () => {
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }
    const authContext = useContext(AuthContext)
    const history = useHistory()

    const { user, loadUser, updateProfile, responseStatus: responseStatusUser } = authContext

    const [currentCountry, setCurrentCountry] = useState()

    const validationArray = Yup.object({
        first_name: Yup.string()
            .min(2, t('minimum_2_characters'))
            .max(15, t('maximum_15_characters'))
            .required(t('required')),
        last_name: Yup.string()
            .min(1, t('mininum_1_characters'))
            .max(15, t('maximum_15_characters'))
            .required(t('required')),
        address1: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z- ,.#()]*$/g, t('some_kind_of_special_characters_not_allowed'))
            .max(250, t('maximum_250_characters'))
            .required(t('required')),
        phone: Yup.string()
            .trim()
            .matches(/^[0-9- +()]*$/g, t('only_numbers_are_allowed'))
            .required(t('required')),
        country: Yup.string().trim().required(t('required')),
        city: Yup.string().required(t('required')),
        state: Yup.string().required(t('required')),
        zip: Yup.string()
            .trim()
            .matches(/^[0-9]*$/g, t('the_positive_number_only'))
            .max(6, t('maximum_6_characters'))
            .required(t('required')),
        emirates_id:
            currentCountry === 'AE'
                ? Yup.string()
                      .trim()
                      .matches(/^[0-9a-zA-Z-]*$/g, t('the_special_characters_not_allowed'))
                      .required(t('required'))
                : Yup.string()
                      .trim()
                      .matches(/^[0-9a-zA-Z-]*$/g, t('the_special_characters_not_allowed')),
        passport_number: Yup.string()
            .trim()
            .matches(/^[0-9a-zA-Z]*$/g, t('the_special_characters_not_allowed'))
            .max(15, t('maximum_15_characters'))
            .min(5, t('minimum_5_characters'))
            .required(t('required')),
    })

    const validationPassword = Yup.object({
        change_password: Yup.boolean(),
        current_password: Yup.string().required(t('required')),
        new_password: Yup.string().min(8, t('minimum_8_characters')).required(t('required')),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], t('passwords_not_match'))
            .required(t('required')),
    })

    const formik = useFormik({
        initialValues: {
            first_name: '',
            last_name: '',
            address1: '',
            phone: '',
            email: '',
            city: '',
            state: '',
            zip: '',
            country: global.defaultCountry,
            phone_verified: 0,
            verified_phonenum: '',
            phone_verifysent: 0,
            verify_code: '',
            emirates_id: '',
            passport_number: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            delete values.email
            updateProfile(values)
        },
    })

    useEffect(() => {
        if (user) {
            formik.values.first_name = user.first_name
            formik.values.last_name = user.last_name
            formik.values.address1 = user.address1
            formik.values.phone = user.phone
            formik.values.email = user.email
            formik.values.city = user.city
            formik.values.country = user.country
            formik.values.state = user.state
            formik.values.zip = user.zip
            formik.values.emirates_id = user.emirates_id ? user.emirates_id : ''
            formik.values.passport_number = user.passport_number ? user.passport_number : ''
        }
    }, [user])

    const formikChange = useFormik({
        initialValues: {
            change_password: 1,
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationPassword,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    const changePassword = [
        {
            label: t('current_password'),
            name: 'current_password',
            type: 'password',
            placeholder: t('enter_your_current_password'),
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: t('password'),
            name: 'new_password',
            type: 'password',
            placeholder: t('enter_your_password'),
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
        {
            label: t('confirm_password'),
            name: 'confirm_password',
            type: 'password',
            placeholder: t('re-enter_your_password'),
            class: 'col-md-6 col-sm-12',
            formik: formikChange,
        },
    ]

    const profile = [
        {
            label: t('first_name'),
            type: 'text',
            placeholder: t('enter_your_first_name'),
            class: 'col-md-6 col-sm-12',
            name: 'first_name',
            formik: formik,
        },
        {
            label: t('last_name'),
            placeholder: t('enter_your_last_name'),
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'last_name',
            formik: formik,
        },
        {
            label: t('email_address'),
            placeholder: t('enter_your_email_address'),
            class: 'col-md-6 col-sm-12',
            type: 'text',
            disabled: true,
            name: 'email',
            formik: formik,
        },
        {
            label: t('phone_number'),
            placeholder: t('phone_number'),
            class: 'col-md-6 col-sm-12',
            type: 'phone',
            name: 'phone',
            formik: formik,
            disabled: true,
        },
        {
            label: t('address'),
            placeholder: t('enter_your_address'),
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'address1',
            formik: formik,
        },
        {
            label: t('country'),
            placeholder: t('enter_your_country'),
            class: 'col-md-6 col-sm-12',
            type: 'select',
            options: CSCCountry.getAllCountries()
                .filter((country) => global.ignoreCountry.indexOf(country.isoCode) === -1)
                .map((country) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = country.name
                    busCategoryChanged.value = country.isoCode
                    return busCategoryChanged
                }),
            name: 'country',
            formik: formik,
        },
        {
            label: t('state'),
            placeholder: t('select_your_state'),
            class: 'col-md-6 col-sm-12',
            type: 'select',
            options: CSCState.getStatesOfCountry(
                formik.values.country ? formik.values.country : global.defaultCountry,
            )
                .filter((state) => global.ignoreStates.indexOf(state.isoCode) === -1)
                .map((buscat) => {
                    let busCategoryChanged = {}
                    busCategoryChanged.show = buscat.name
                    busCategoryChanged.value = buscat.isoCode
                    return busCategoryChanged
                }),
            name: 'state',
            formik: formik,
        },
        {
            label: t('city'),
            placeholder: t('enter_your_city'),
            class: 'col-md-6 col-sm-12',
            type: 'text',
            name: 'city',
            formik: formik,
        },
        {
            label: t('emirates_id'),
            name: 'emirates_id',
            type: 'text',
            placeholder: t('enter_your_emirates_id'),
            class: 'col-md-6 col-sm-12',
            formik: formik,
        },
        {
            label: t('passport_number'),
            name: 'passport_number',
            type: 'text',
            placeholder: t('enter_your_passport_number'),
            class: 'col-md-6 col-sm-12',
            formik: formik,
        },
        {
            label: t('po_box'),
            placeholder: t('enter_your_post_box_number'),
            class: 'col-md-3 col-sm-12',
            type: 'text',
            name: 'zip',
            formik: formik,
        },
    ]

    useEffect(() => {
        if (responseStatusUser) {
            if (
                responseStatusUser.from === 'updateProfile' ||
                responseStatusUser.from === 'updatePreference'
            ) {
                if (responseStatusUser.status === 'success') {
                    loadUser()
                    handleRedirectInternal(history, ``)
                }
            }
        }
    }, [responseStatusUser])

    useEffect(() => {
        setCurrentCountry(formik.values.country)
        if (user && user.country && formik.values.country === user.country) {
            formik.setFieldValue('state', user.state)
        } else {
            formik.setFieldValue('state', '')
        }
    }, [formik.values.country])

    return (
        <Layout>
            <form onSubmit={formikChange.handleSubmit} autoComplete="nofill">
                <div className="accChild">
                    <h4>{t('profile_details')}</h4>

                    <div className="row">{Object.values(mapData(changePassword))}</div>
                </div>
                <PrimaryButton type="submit" label={t('save')} />
            </form>
        </Layout>
    )
}

export default MyAccount
