import React, { useEffect, useContext, useState } from 'react'
import '../Dashboard/Dashboard.css'
import RadioBox from '../../product/components/atoms/RadioBox'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
    mapData,
    dateFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import ReportDisplay from './display'
import { useHistory } from 'react-router-dom'

import CheckBox from '../../product/components/atoms/CheckBox'
import Layout from '../../product/components/templates/Layout'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import ReportContext from '../../product/context/report/reportContext'

const ReportManagement = () => {
    const history = useHistory()
    const reportContext = useContext(ReportContext)
    const [resetForm, setResetForm] = React.useState(false)
    const [reportsData, setReportsData] = React.useState(null)
    const [isLoading, setIsLoading] = useState(false)

    const [manage, setManage] = React.useState({
        popup: false,
    })

    const toggleFullScreenPopup = (popup) => {
        if (popup) {
            setManage({ popup })
        } else {
            setManage({ popup })
        }
    }

    const {
        reports_data,
        getAllReportDisplay,
        getAllReportDownload,
        responseStatus: responseStatusReport,
        clearResponse: clearResponseReport,
    } = reportContext

    const allSortOptions = [
        { id: 'asc', description: 'Ascending' },
        { id: 'desc', description: 'Descending' },
    ]

    const allSearchOptions = [
        { id: 'within', description: 'Within' },
        { id: 'customSelection', description: 'Custom Selection' },
    ]

    const validation = Yup.object({
        order: Yup.string().required('Required!'),
        action: Yup.string().required('Required!'),
        report: Yup.string().required('Required!'),
        search: Yup.string().required('Required!'),
        start_date: Yup.date(),
        end_date: Yup.date().min(Yup.ref('start_date'), 'Should be greater than from date'),
    })

    const formik = useFormik({
        initialValues: {
            action: 'display',
            order: 'asc',
            orderby: '',
            search: 'within',
            report: 'active_user_management',
            within: 'day',
            page: 1,
            limit: 20,
            start_date: dateFormatFunction(new Date()),
            end_date: dateFormatFunction(new Date()),
            limit_results: '',
            searchterm: '',
            fields: [],
        },
        validateOnBlur: false,
        validationSchema: validation,
        onSubmit: (values) => {
            setIsLoading(true)

            formik.setSubmitting(true)
            if (values.action === 'display') {
                getAllReportDisplay(values)
            } else {
                getAllReportDownload(values)
            }
        },
    })

    useEffect(() => {
        if (reports_data && formik.isSubmitting) {
            setReportsData(reports_data)
            console.log('reports_data', reports_data)
            setIsLoading(false)
            toggleFullScreenPopup(true)
        }
    }, [reports_data])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    })
    const action = [
        {
            label: 'Action',
            placeholder: 'Enter action',
            class: 'col-12',
            type: 'select',
            options: [
                { value: 'display', show: 'Display' },
                { value: 'download', show: 'Download' },
            ],
            name: 'action',
            formik: formik,
        },
    ]

    const withinRange = [
        {
            label: 'Within',
            placeholder: 'Enter Within',
            class: 'col-12',
            type: 'select',
            options: [
                { value: 'day', show: 'The Past Day' },
                { value: 'week', show: 'The Past Week' },
                { value: 'month', show: 'The Past Month' },
                { value: 'year', show: 'The Past Year' },
            ],
            name: 'within',
            formik: formik,
        },
    ]

    const report = [
        {
            label: 'Report',
            placeholder: 'Enter Report',
            class: 'col-12',
            type: 'select',
            groupoptions: [
                {
                    head: 'User management',
                    options: [
                        { value: 'active_user_management', show: 'Active' },
                        { value: 'deactivate_user_management', show: 'Deactive' },
                        { value: 'unverified_user_management', show: 'Unverified' },
                    ],
                },
                {
                    head: 'Product management',
                    options: [
                        { value: 'open_product_management', show: 'Open' },
                        { value: 'ending_product_management', show: 'Ending' },
                        { value: 'closed_product_management', show: 'Closed' },
                        { value: 'sold_product_management', show: 'Sold' },
                    ],
                },
                {
                    head: 'Auction management',
                    options: [
                        { value: 'open_auction_management', show: 'Open' },
                        { value: 'ending_auction_management', show: 'Ending' },
                        { value: 'closed_auction_management', show: 'Closed' },
                        { value: 'sold_auction_management', show: 'Sold' },
                    ],
                },
                {
                    head: 'Sold Order management',
                    options: [{ value: 'sold_order', show: 'Sold' }],
                },
                {
                    head: 'Invoice management',
                    options: [
                        { value: 'paid_invoice_management', show: 'Paid' },
                        { value: 'unpaid_invoice_management', show: 'Unpaid' },
                    ],
                },
                {
                    head: 'Employee management',
                    options: [{ value: 'employee_list', show: 'Employee Details' }],
                },
                {
                    head: 'Wallet management',
                    options: [{ value: 'deposit_balance', show: 'Wallet Details' }],
                },
                {
                    head: 'Transaction management',
                    options: [{ value: 'transaction_list', show: 'Transaction Details' }],
                },
                {
                    head: 'all Paid Product Report',
                    options: [{ value: 'all_paid_reports', show: 'All Paid Product Details' }],
                },
            ],
            name: 'report',
            formik: formik,
        },
    ]
    const dateRange = [
        {
            label: 'From date',
            placeholder: 'Enter From date',
            type: 'date',
            class: 'col-6',
            name: 'start_date',
            formik: formik,
        },
        {
            label: 'End date',
            placeholder: 'Enter End date',
            type: 'date',
            class: 'col-6',
            name: 'end_date',
            formik: formik,
        },
    ]
    const limitResult = [
        {
            label: 'Limit results',
            placeholder: 'Enter Limit results',
            type: '',
            class: 'col-12',
            name: 'limit_results',
            formik: formik,
        },
    ]

    let fieldsToShow = []

    fieldsToShow = [
        {
            field: 'id',
            label: 'User ID',
            sort: true,
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
                'deposit_balance',
            ],
        },
        {
            field: 'user_fullname',
            label: 'User Name',
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
                'deposit_balance',
            ],
        },
        {
            field: 'email',
            label: 'User Email',
            sort: true,
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
                'deposit_balance',
            ],
        },
        {
            field: 'phone',
            label: 'Phone Number',
            type: '',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
                'deposit_balance',
            ],
        },
        {
            field: 'created_at',
            label: 'Created Date',
            sort: true,
            type: 'datetime',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'last_login',
            label: 'Last Login',
            sort: true,
            type: 'datetime',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
            ],
        },
        {
            field: 'status',
            label: 'Status',
            type: 'capitalize',
            group: [
                'active_user_management',
                'deactivate_user_management',
                'unverified_user_management',
                'deposit_balance',
            ],
        },
        {
            field: 'deposit_amount',
            label: 'Wallet Balance',
            type: 'price',
            group: ['deposit_balance'],
        },
        {
            field: 'id',
            label: 'ID',
            sort: true,
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
            ],
        },
        {
            field: 'title',
            label: 'Title',
            sort: true,
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
            ],
        },
        {
            field: 'date_added',
            label: 'Starting Date',
            sort: true,
            type: 'datetime',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
            ],
        },
        {
            field: 'date_closed',
            label: 'End Date',
            sort: true,
            type: 'datetime',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
            ],
        },
        {
            field: 'auctionid',
            label: 'Auction ID',
            sort: true,
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_title',
            label: 'Auction Title',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
                'all_paid_reports',
            ],
        },
        {
            field: 'auction_description',
            label: 'Auction Description',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_date_added',
            label: 'Auction Starting Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_date_closed',
            label: 'Auction End Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'auction_status',
            label: 'Auction Status',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_id',
            label: 'Lot ID',
            sort: true,
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_title',
            label: 'Lot Title',
            sort: true,
            type: 'capitalize',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_date_added',
            label: 'Lot Starting Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'lot_date_closed',
            label: 'Lot End Date',
            sort: true,
            type: 'date',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'sprice',
            label: 'Starting Price',
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'bprice',
            label: 'Buynow Price',
            type: 'price',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'qty',
            label: 'Total Quantity',
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'sold',
            label: 'Sold Quantity',
            type: '',
            group: [
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'buynowamount',
            label: 'Sold Price',
            type: 'price',
            group: [
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
                'paid_invoice_management',
                'unpaid_invoice_management',
            ],
        },
        {
            field: 'wprice',
            label: 'Current Price',
            sort: true,
            type: 'price',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'market_status',
            label: 'Status',
            sort: true,
            type: 'capitalize',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
            ],
        },
        {
            field: 'totalbids',
            label: 'No. of Bids',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
                'open_auction_management',
                'ending_auction_management',
                'closed_auction_management',
                'sold_auction_management',
            ],
        },
        {
            field: 'numDiffBidders',
            label: 'No. of Different Bidders',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
            ],
        },
        {
            field: 'winnerName',
            label: 'Buyer Name',
            type: '',
            group: [
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
                'paid_invoice_management',
                'unpaid_invoice_management',
            ],
        },
        {
            field: 'winemail',
            label: 'Winner Email',
            type: '',
            group: [
                'open_product_management',
                'ending_product_management',
                'closed_product_management',
            ],
        },
        {
            field: 'winnerEmail',
            label: 'Winner Email',
            type: 'capitalize',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'winnerCompany',
            label: 'Buyer Company',
            type: 'capitalize',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'buynowemail',
            label: 'Buyer Email',
            type: '',
            group: ['sold_product_management', 'all_paid_reports', 'sold_order'],
        },
        {
            field: 'common_invoice',
            label: 'Invoice ID',
            sort: true,
            type: '',
            group: [
                'sold_product_management',
                'all_paid_reports',
                'sold_order',
                'paid_invoice_management',
                'unpaid_invoice_management',
                'transaction_list',
            ],
        },
        {
            field: 'paid_date',
            label: 'Paid Date',
            sort: true,
            type: 'datetime',
            group: ['sold_product_management', 'all_paid_reports'],
        },
        {
            field: 'shipping_amount',
            label: 'Shipping Price',
            sort: true,
            type: 'price',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'total_invoice',
            label: 'Total Price',
            sort: true,
            type: 'price',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'date_added',
            label: 'Sold Date',
            sort: true,
            type: 'date',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'paid_date',
            label: 'Paid Date',
            sort: true,
            type: 'date',
            group: ['paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'project_id',
            label: 'Product ID',
            sort: true,
            type: '',
            group: ['sold_order'],
        },
        {
            field: 'sold_qty',
            label: 'Purchased Qty',
            sort: true,
            type: '',
            group: ['sold_order', 'paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'date_added',
            label: 'Sale Date',
            sort: true,
            type: 'date',
            group: ['sold_order'],
        },
        {
            field: 'paid',
            label: 'Paid Status',
            sort: true,
            type: 'paid_status',
            group: ['sold_order', 'paid_invoice_management', 'unpaid_invoice_management'],
        },
        {
            field: 'emp_firstname',
            label: 'First Name',
            sort: true,
            type: 'capitalize',
            group: ['employee_list'],
        },
        {
            field: 'emp_lastname',
            label: 'Last Name',
            sort: true,
            type: 'capitalize',
            group: ['employee_list'],
        },
        {
            field: 'emp_email',
            label: 'Email',
            sort: true,
            type: '',
            group: ['employee_list'],
        },
        {
            field: 'emp_phone',
            label: 'Phone Number',
            sort: true,
            type: '',
            group: ['employee_list'],
        },
        {
            field: 'emp_address1',
            label: 'Address',
            sort: true,
            type: 'capitalize',
            group: ['employee_list'],
        },
        {
            field: 'emp_city',
            label: 'City',
            sort: true,
            type: '',
            group: ['employee_list'],
        },
        {
            field: 'emp_zip',
            label: 'Zip Code',
            sort: true,
            type: '',
            group: ['employee_list'],
        },
        {
            field: 'emp_state',
            label: 'State',
            sort: true,
            type: '',
            group: ['employee_list'],
        },
        {
            field: 'emp_country',
            label: 'Country',
            sort: true,
            type: '',
            group: ['employee_list'],
        },
        {
            field: 'emp_type',
            label: 'Employee Type',
            sort: true,
            type: 'em_type',
            group: ['employee_list'],
        },
        {
            field: 'trans_id',
            label: 'Transaction ID',
            sort: true,
            type: '',
            group: ['transaction_list'],
        },
        {
            field: 'amount',
            label: 'Amount',
            sort: true,
            type: 'price',
            group: ['transaction_list'],
        },
        {
            field: 'user_fullname',
            label: 'Buyer Name',
            sort: true,
            type: 'capitalize',
            group: ['transaction_list'],
        },
        {
            field: 'email',
            label: 'Buyer Email',
            sort: true,
            type: '',
            group: ['transaction_list'],
        },
        {
            field: 'payment_method',
            label: 'Payment Type',
            sort: true,
            type: 'capitalize',
            group: ['transaction_list'],
        },
        {
            field: 'card_type',
            label: 'Gateway',
            sort: true,
            type: 'capitalize',
            group: ['transaction_list'],
        },
        {
            field: 'created_at',
            label: 'Paid Date',
            sort: true,
            type: 'datetime',
            group: ['transaction_list'],
        },
        {
            field: 'totalbids',
            label: 'No. of Bids',
            type: '',
            group: ['all_paid_reports'],
        },
        {
            field: 'bid_deposit_amount',
            label: 'Bid Deposit Amount',
            sort: true,
            type: '',
            group: ['all_paid_reports'],
        },
        {
            field: 'bid_deposit_status',
            label: 'Bid Deposit Status',
            sort: true,
            type: '',
            group: ['all_paid_reports'],
        },
        {
            field: 'bid_deposit_date_added',
            label: 'Bid Deposit Date Added',
            sort: true,
            type: 'datetime',
            group: ['all_paid_reports'],
        },
        {
            field: 'bid_deposit_gateway',
            label: 'Bid Deposit Gate Way',
            sort: true,
            type: '',
            group: ['all_paid_reports'],
        },
        {
            field: 'bid_refunded',
            label: 'Bid refended',
            sort: true,
            type: '',
            group: ['all_paid_reports'],
        },
    ]

    useEffect(() => {
        if (formik.values.report) {
            formik.values.page = 1
            formik.values.order = 'asc'
            formik.values.orderby = ''
            formik.values.searchterm = ''
            let valueSelected = []
            let fieldsToSort = fieldsToShow
            fieldsToSort
                .filter((field) => field.group.indexOf(formik.values.report.toString()) !== -1)
                .map((d, i) => valueSelected.push(d.field))
            formik.values.fields = valueSelected
            setResetForm(!resetForm)
            setReportsData(null)
        }
        return () => {
            setReportsData(null)
        }
    }, [formik.values.report])

    return (
        <Layout>
            <div className="dashboard reportManagement">
                <ReportDisplay
                    formik={formik}
                    fieldsToShow={fieldsToShow}
                    data={manage}
                    function={toggleFullScreenPopup}
                />
                <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                    <h6 className="dashSubtitle">
                        Report management gives you direct access of accounting overview based on
                        the reporting you wish to formulate.
                    </h6>
                    <div className="reportAction d-flex align-items-center">
                        <div className="row">{Object.values(mapData(action))}</div>
                    </div>

                    <div className="reportAction d-flex align-items-center">
                        <div className="row">{Object.values(mapData(report))}</div>
                    </div>

                    <h6 className="dashSubtitle">
                        Customize your report using a wide variety of date range options.
                    </h6>

                    <div className="reportAction d-flex align-items-center flex-wrap">
                        <div className="reportLabel">
                            <h6>Fields</h6>
                        </div>

                        <div className="reportFields d-flex justify-content-start align-items-center flex-wrap">
                            {fieldsToShow
                                .filter(
                                    (field) =>
                                        field.group.indexOf(formik.values.report.toString()) !== -1,
                                )
                                .map((d, i) => (
                                    <>
                                        <CheckBox
                                            name="fields"
                                            label={d.label}
                                            checked={
                                                formik.values.fields.indexOf(d.field.toString()) !==
                                                -1
                                                    ? true
                                                    : false
                                            }
                                            value={d.field.toString()}
                                            onChange={formik.handleChange}
                                        />
                                    </>
                                ))}
                        </div>
                    </div>

                    <div className="reportAction d-flex align-items-center flex-wrap">
                        <div className="reportLabel">
                            <h6>Search</h6>
                        </div>
                        <div className="d-flex flex-wrap">
                            <div className="d-flex justify-content-center align-items-center">
                                <RadioBox
                                    items={allSearchOptions}
                                    value={formik.values.search}
                                    onChange={formik.handleChange}
                                    name="search"
                                />
                            </div>
                            <div className="reportRange datePicker d-flex align-items-center">
                                {formik.values.search === 'within' ? (
                                    <>
                                        <div className="row">
                                            {Object.values(mapData(withinRange))}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="row">
                                            {Object.values(mapData(dateRange))}
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="reportAction d-flex align-items-center flex-wrap">
                        <div className="reportLabel">
                            <h6>Order Results</h6>
                        </div>
                        <div className="orderResults d-flex justify-content-center align-items-center">
                            <RadioBox
                                items={allSortOptions}
                                value={formik.values.order}
                                onChange={formik.handleChange}
                                name="order"
                            />
                        </div>
                    </div>

                    {/* <div className="reportAction d-flex align-items-center">
                        <div className="reportLabel">
                            <h6>Limit Results</h6>
                        </div>
                        <div className="limitResults">
                            <div className="row">{Object.values(mapData(limitResult))}</div>
                        </div>
                    </div> */}
                    {isLoading && <div>Loading...</div>}
                    <div className="actionButton">
                        <PrimaryButton type="submit" label="Generate Report" />
                    </div>
                </form>
            </div>
        </Layout>
    )
}

export default ReportManagement
