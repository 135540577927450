import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import UserContext from '../../product/context/user/userContext'
import ReportContext from '../../product/context/report/reportContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CustomDialog from '../../product/components/organisms/Dialog'
import CustomCommonContext from '../../custom/context/common/commonContext'
import { mapData } from '../../product/common/components'
import '../Dashboard/Dashboard.css'
import { Button } from '@material-ui/core'
import * as Yup from 'yup'
import UserManage from './UserManage'

const UserView = (props) => {
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const reportContext = useContext(ReportContext)

    const customCommonContext = useContext(CustomCommonContext)

    const { allBusinessCategory } = customCommonContext

    const [isLoading, setIsLoading] = useState(true)
    const { getAllExportDownload } = reportContext
    const history = useHistory()
    const { setAlert } = alertContext
    const {
        all_users,
        changeUserStatus,
        getAllUsers,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = userContext
    const [changeStatus, setChangeStatus] = React.useState(false)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        user_type: 0,
    })
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const toggleFullScreenPopup = (popup, status, id, user_type) => {
        if (popup) {
            setManage({ popup, status, id, user_type })
        } else {
            setManage({ popup, status: 'new', id: 0, user_type: 0 })
        }
    }

    let action = props.match.params.action !== 'all' ? props.match.params.action : null

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? action.toString() : null,
                    type: 'in',
                    field: 'u.status',
                },
                email: {
                    value: '',
                    type: 'like',
                    field: 'u.email',
                },
                first_name: {
                    value: '',
                    type: 'like',
                    field: 'u.first_name',
                },
                last_name: {
                    value: '',
                    type: 'like',
                    field: 'u.last_name',
                },
                companyname: {
                    value: '',
                    type: 'like',
                    field: 'u.companyname',
                },
                trakhees_reg_num: {
                    value: '',
                    type: 'like',
                    field: 'cu.trakhees_reg_num',
                },
                dubai_customs_code: {
                    value: '',
                    type: 'like',
                    field: 'cu.dubai_customs_code',
                },
                business_category: {
                    value: [],
                    type: 'array',
                    field: 'cu.business_category',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllUsers(values)
        },
    })

    useEffect(() => {
        setIsLoading(false)
        if (all_users) {
            setTableBody(all_users.records.length ? all_users.records : [])
        }
    }, [all_users])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.filters.email.value = ''
        formik.values.filters.first_name.value = ''
        formik.values.filters.last_name.value = ''
        formik.values.filters.companyname.value = ''
        formik.values.filters.trakhees_reg_num.value = ''

        getAllUsers(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    const onClickEdit = (id, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    const validationArray = Yup.object({
        reject_notes: Yup.string().when(['additionalUpdate'], {
            is: (a) => a,
            then: Yup.string().required('Required'),
        }),
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            user_id: '',
            reject_notes: '',
            additionalUpdate: true,
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            if (
                values.action === 'active' ||
                values.action === 'deactivate' ||
                values.action === 'rejected'
            ) {
                changeUserStatus(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.user_id = data
        formikSelection.values.additionalUpdate =
            action === 'deactivate' || action === 'rejected' ? true : false
        formikSelection.values.reject_notes = ''
        if (action === 'active' || action === 'deactivate' || action === 'rejected') {
            setChangeStatus(true)
        }
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'first_name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'First Name',
        },
        {
            field: 'last_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            field: 'email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'companyname',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Company',
        },
        {
            field: 'last_login',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Last Login',
        },
        {
            field: 'status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'deposit_amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Wallet Balance',
        },
    ]
    if (action === 'deactivate' || action === 'rejected') {
        tableValues.push({
            field: 'reject_notes',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Notes',
        })
    }

    tableValues.push({
        field: 'action',
        type: 'button',
        clickType: 'edit',
        onclick: onClickEdit,
        sort: false,
        firstChild: false,
        disablePadding: false,
        label: 'Action',
        tooltipTitle: 'Edit user',
    })

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Email ',
                placeholder: 'Enter Email',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'email',
                filter: true,
            },
            {
                label: 'First Name',
                placeholder: 'Enter First Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'first_name',
                filter: true,
            },
            {
                label: 'Last Name',
                placeholder: 'Enter Last Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'last_name',
                filter: true,
            },
            {
                label: 'Company Name',
                placeholder: 'Enter Company Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'companyname',
                filter: true,
            },
            {
                label: 'Trakhees Registration Number',
                placeholder: 'Enter Trakhees Registration Number',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'trakhees_reg_num',
                filter: true,
            },
            {
                label: 'Dubai Customs Code',
                placeholder: 'Enter Dubai Customs Code',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'dubai_customs_code',
                filter: true,
            },
            {
                label: 'Categories',
                placeholder: 'Enter Business Category',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'multiselect',
                name: 'business_category',
                options: allBusinessCategory
                    .filter((value) => value.active === 1)
                    .map((buscat) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = buscat.description
                        busCategoryChanged.value = buscat.id
                        return busCategoryChanged
                    }),
                filter: true,
            },
        ],
    }

    const tableActions = []

    if (action === 'active') {
        tableActions.push({
            label: 'Move to De-activate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactivate',
        })
    }
    if (action === 'deactivate' || action === 'unverified') {
        tableActions.push({
            label: 'Move to Activate',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        })
    }

    if (action === 'unverified') {
        tableActions.push({
            label: 'Move to Rejected',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'rejected',
        })
    }

    if (action === 'all' || !action) {
        tableActions.push(
            {
                label: 'Move to De-activate',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
            {
                label: 'Move to Activate',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            },
            {
                label: 'Move to Rejected',
                icon: <span className="material-icons">cancel</span>,
                onclick: onSelectMultiProducts,
                type: 'rejected',
            },
        )
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'changeStatus') {
                    setChangeStatus(false)
                    getAllUsers(formik.values)
                }
                if (responseStatusUser.from === 'userAction') {
                    toggleFullScreenPopup(false)
                    getAllUsers(formik.values)
                }
            }
        }
    }, [responseStatusUser])

    const rejectNotes = {
        formik: formikSelection,
        data: [
            {
                label: 'Justification Notes',
                placeholder: 'Enter Justification Notes',
                class: `col-12 ${formikSelection.values.additionalUpdate ? '' : 'd-none'}`,
                type: 'textarea',
                name: 'reject_notes',
                filter: true,
            },
        ],
    }

    return (
        <Layout>
            <div className="dashboard userManagement">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-between align-items-center mt-4 flex-wrap">
                    <div>
                        <Button
                            variant="outlined"
                            className="btnOutlined mr-1"
                            onClick={() => toggleFullScreenPopup(true, 'new', 0, 0)}
                        >
                            <span className="material-icons">person_add_alt_1</span>Add Individual
                            User
                        </Button>
                        <Button
                            variant="outlined"
                            className="btnOutlined"
                            onClick={() => toggleFullScreenPopup(true, 'new', 0, 1)}
                        >
                            <span className="material-icons">business</span>Add Company User
                        </Button>
                    </div>
                    <Button
                        onClick={() =>
                            getAllExportDownload({
                                action: 'export',
                                order: 'asc',
                                orderby: '',
                                search: 'all',
                                report: `${action}_user_management`,
                            })
                        }
                    >
                        <span className="material-icons">get_app</span>Export All Data
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_users}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllUsers}
                />
            </div>

            <UserManage data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change user status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="row">{mapData(rejectNotes)}</div>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default UserView
