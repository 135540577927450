import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            width: '100%',
            height: '60px',
        },
    },
}))

const ColorButton = withStyles((theme) => ({
    root: {
        color: '#fff',
        borderRadius: '0',
        color: 'var(--primColor)',
        boxShadow: '0px 4px 12px rgba(var(--primColor), 20%)',
        backgroundColor: '#fff',
        border: '1px solid var(--primColor)',
        transition: '200ms all ease-in-out',
        '&:hover': {
            backgroundColor: 'var(--primColor)',
            border: '1px solid var(--primColor)',
            color: '#fff',
            boxShadow: '0px 4px 12px rgba(var(--primColor), 20%)',
        },
    },
}))(Button)

const SecondaryButton = (props) => {
    const classes = useStyles()

    return (
        <div className={`${classes.root} ${props.btnSize} secButton`}>
            <ColorButton
                variant="outlined"
                color="primary"
                onClick={props.onClick}
                type={props.type}
                disabled={props.disabled || false}
            >
                {props.iconLeft}
                {props.label}
                {props.children}
            </ColorButton>
        </div>
    )
}

export default SecondaryButton
