import {
    GET_ALL_LANGUAGE,
    GET_ALL_PHRASE,
    CLEAR_RESPONSE,
    ADD_LANGUAGE_GROUP,
    ADD_PHRASE,
    PHRASE_SEARCH,
    PHRASE_SEARCH_BY_LANGUAGE,
    LANGUAGE_MANAGER_RESPONSE,
    ALL_LANGUAGE_GROUP,
} from './languageManagerTypes'

export default (state, action) => {
    switch (action.type) {
        case LANGUAGE_MANAGER_RESPONSE:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_LANGUAGE:
            return {
                ...state,
                all_language: action.payload,
            }
        case GET_ALL_PHRASE:
            return {
                ...state,
                all_phrase: action.payload,
            }
        case ADD_LANGUAGE_GROUP:
            return {
                ...state,
                add_language_group: action.payload,
            }
        case ADD_PHRASE:
            return {
                ...state,
                add_phrase: action.payload,
            }
        case PHRASE_SEARCH:
            return {
                ...state,
                searchPhrase: action.payload,
            }
        case PHRASE_SEARCH_BY_LANGUAGE:
            return {
                ...state,
                searchPhraseByLanguage: action.payload,
            }
        case ALL_LANGUAGE_GROUP:
            return {
                ...state,
                allLanguageGroup: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
