import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import UserContext from '../../product/context/user/userContext'
import ReportContext from '../../product/context/report/reportContext'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CustomDialog from '../../product/components/organisms/Dialog'
import '../Dashboard/Dashboard.css'
import { Button } from '@material-ui/core'

import WalletManage from './WalletManage'

const statusTypes = [
    { value: 'active', show: 'Active' },
    { value: 'deactivate', show: 'Deactivated' },
]

const Wallet = (props) => {
    const alertContext = useContext(AlertContext)
    const userContext = useContext(UserContext)
    const reportContext = useContext(ReportContext)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const { setAlert } = alertContext
    const {
        all_users,
        changeUserStatus,
        getAllUsers,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = userContext
    const [changeStatus, setChangeStatus] = React.useState(false)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        secondaryID: 0,
    })

    const { getAllExportDownload } = reportContext

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const toggleFullScreenPopup = (popup, status, id, secondaryID) => {
        if (popup) {
            setManage({ popup, status, id, secondaryID })
        } else {
            setManage({ popup, status: 'new', id: 0, secondaryID: 0 })
        }
    }

    let action = props.match.params.action !== 'all' ? props.match.params.action : null

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? action.toString() : null,
                    type: 'in',
                    field: 'u.status',
                },
                email: {
                    value: '',
                    type: 'like',
                    field: 'u.email',
                },
                first_name: {
                    value: '',
                    type: 'like',
                    field: 'u.first_name',
                },
                last_name: {
                    value: '',
                    type: 'like',
                    field: 'u.last_name',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllUsers(values)
        },
    })

    useEffect(() => {
        if (all_users) {
            setTableBody(all_users.records.length ? all_users.records : [])
        }
        setIsLoading(false)
    }, [all_users])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.filters.email.value = ''
        formik.values.filters.first_name.value = ''
        formik.values.filters.last_name.value = ''

        getAllUsers(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, type, id, secondaryID)
    }
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            user_id: '',
        },
        onSubmit: (values) => {
            setIsLoading(true)
            if (values.action === 'active' || values.action === 'deactivate') {
                changeUserStatus(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.user_id = data
        if (action === 'active' || action === 'deactivate') {
            setChangeStatus(true)
        }
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'first_name',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'First Name',
        },
        {
            field: 'last_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            field: 'email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'deposit_amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Wallet Balance',
        },
        {
            field: 'action',
            type: 'button',
            buttonType: 'monetization_on',
            clickType: 'edit',
            secondaryClickID: 'user_id',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Manage credits',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Email',
                placeholder: 'Enter Email',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'email',
                filter: true,
            },
            {
                label: 'First Name',
                placeholder: 'Enter First Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'first_name',
                filter: true,
            },
            {
                label: 'Last Name',
                placeholder: 'Enter Last Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'last_name',
                filter: true,
            },
            {
                label: 'Status',
                placeholder: 'Select User Status',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'select',
                options: statusTypes,
                name: 'action',
                filter: true,
            },
        ],
    }

    const tableActions = []

    if (action === 'active') {
        tableActions.push({
            label: 'Move to De-activate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactivate',
        })
    }
    if (action === 'deactivate' || action === 'unverified') {
        tableActions.push({
            label: 'Move to Activate',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        })
    }

    if (action === 'unverified') {
        tableActions.push({
            label: 'Move to Rejected',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'rejected',
        })
    }

    if (action === 'all' || !action) {
        tableActions.push(
            {
                label: 'Move to De-activate',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
            {
                label: 'Move to Activate',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            },
            {
                label: 'Move to Rejected',
                icon: <span className="material-icons">cancel</span>,
                onclick: onSelectMultiProducts,
                type: 'rejected',
            },
        )
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'changeStatus') {
                    setChangeStatus(false)
                    getAllUsers(formik.values)
                }
                if (responseStatusUser.from === 'userAction') {
                    toggleFullScreenPopup(false)
                    getAllUsers(formik.values)
                }
            }
        }
    }, [responseStatusUser])

    return (
        <Layout>
            <div className="dashboard userManagement">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-between align-items-center mt-4 flex-wrap">
                    <Button
                        onClick={() =>
                            getAllExportDownload({
                                action: 'export',
                                order: 'asc',
                                orderby: '',
                                search: 'all',
                                report: 'deposit_balance',
                            })
                        }
                    >
                        <span className="material-icons">get_app</span>Export All Data
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    allData={all_users}
                    changeTableFunction={getAllUsers}
                />
            </div>

            <WalletManage data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change user status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default Wallet
