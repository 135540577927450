import React, { useReducer } from 'react'
import BidDepositContext from './bidDepositContext'
import BidDepositReducer from './bidDepositReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_PRODUCTS,
    GET_SINGLE_PROJECT,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './bidDepositTypes'

const BidDepositState = (props) => {
    const initialState = {
        all_products: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        search_allprojectdeposit: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(BidDepositReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'listings', formData, '', 'bid_deposit'),
            ])
            const from = 'listings'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getDepositHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getDepositHistory', formData, '', 'bid_deposit'),
            ])
            const from = 'getDepositHistory'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_PROJECT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('getCreditHistory')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <BidDepositContext.Provider
            value={{
                all_products: state.all_products,
                responseStatus: state.responseStatus,
                search_allprojectdeposit: state.search_allprojectdeposit,
                clearResponse,
                getAllProducts,
                getDepositHistory,
            }}
        >
            {props.children}
        </BidDepositContext.Provider>
    )
}

export default BidDepositState
