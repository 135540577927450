import {
    GET_ALL_TEMPLATES,
    GET_SINGLE_TEMPLATE,
    GET_ALL_STATIC_PAGES,
    GET_SINGLE_STATIC_PAGE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './communicationTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_TEMPLATES:
            return {
                ...state,
                all_templates: action.payload,
            }
        case GET_SINGLE_TEMPLATE:
            return {
                ...state,
                single_template: action.payload,
            }

        case GET_ALL_STATIC_PAGES:
            return {
                ...state,
                static_pages: action.payload,
            }
        case GET_SINGLE_STATIC_PAGE:
            return {
                ...state,
                single_static_page: action.payload,
            }

        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
