import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import InvoiceContext from '../../product/context/invoice/invoiceContext'
import CustomCommonContext from '../../custom/context/common/commonContext'

import {
    dateFormatFunction,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
    paymentTypeRosoom,
} from '../../custom/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button } from '@material-ui/core'
import CartItem from './cartItem'
import ReactToPdf from 'react-to-pdf'
import { BRAND_LOGO, SITE_NAME } from '../../utils/UI/index'

const InvoiceView = (props) => {
    const customCommonContext = useContext(CustomCommonContext)
    const { allConfigurationVariables } = customCommonContext

    const invoiceContext = useContext(InvoiceContext)
    const { single_invoice, getSingleInvoice, all_invoices } = invoiceContext

    const [viewContent, setViewContent] = React.useState(null)
    const [print, setPrint] = useState(false)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const formik = useFormik({
        initialValues: {
            cart_id: 0,
        },
        validateOnBlur: false,
    })

    // temp lang Manager
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }

    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit' || data.status === 'view') {
            formik.values.invoice_id = data.id
            formik.values.user_id = data.sid
            getSingleInvoice(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])
    let [invoiceDetails, setInvoiceDetails] = useState({
        invoiceProducts: [],
        cartValues: {},
        appointmentDetail: {},
        locationDetail: {},
        invoiceTransactions: [],
        invoiceUser: [],
        invoiceAddress: [],
    })

    useEffect(() => {
        if (single_invoice && (data.status === 'edit' || data.status === 'view')) {
            setInvoiceDetails({
                invoiceProducts: single_invoice.invoicerecords.length
                    ? single_invoice.invoicerecords
                    : [],
                cartValues: single_invoice.cartvalues,
                appointmentDetail: single_invoice.appointmentrecord,
                locationDetail: single_invoice.locationrecord,
                invoiceTransactions: single_invoice.transactionrecords,
                invoiceUser: single_invoice.invoiceuserrecord,
                invoiceAddress: single_invoice.invoiceaddressrecord,
            })
        }
    }, [single_invoice])

    useEffect(() => {
        setInvoiceDetails({
            invoiceProducts: [],
            cartValues: {},
            appointmentDetail: {},
            locationDetail: {},
            invoiceTransactions: [],
            invoiceUser: [],
            invoiceAddress: [],
        })
        return () => {
            setInvoiceDetails({
                invoiceProducts: [],
                cartValues: {},
                appointmentDetail: {},
                locationDetail: {},
                invoiceTransactions: [],
                invoiceUser: [],
                invoiceAddress: [],
            })
        }
    }, [])
    const resetFrom = () => {
        setViewContent(null)
    }

    const ref = React.createRef()
    const options = {}

    const generatePDF = (toPdf) => {
        setPrint(true)
        toPdf()
    }
    const completedPDF = () => {
        setPrint(false)
    }
    return (
        <FullScreenPopup
            modaltitle={data.status === 'view' ? `VIEW INVOICE` : `EDIT INVOICE`}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="container-lg productInvoice">
                <h3 className="invoiceTitle">{t('tax_invoice')}</h3>
                <div className="d-flex justify-content-end align-items-center mb-2 noPrint">
                    {/* <Breadcrumbs aria-label="breadcrumb" className="naBreadcrumbs">
                        <Link to="/dashboard/won">Dashboard</Link>
                        <Typography color="textPrimary">Invoice</Typography>
                    </Breadcrumbs> */}
                    <Button onClick={() => window.print()}>
                        <span className="material-icons">print</span>
                        {t('print')}
                    </Button>
                </div>
                <div
                    className={`invoiceView ${print ? 'invoiceViewPrint' : ''}`}
                    ref={ref}
                    style={{ height: '100%', width: '100%' }}
                >
                    <div className="card-header d-flex justify-content-between align-items-center p-4">
                        <a className="pt-2 d-inline-block" data-abc="true">
                            <img
                                src={BRAND_LOGO}
                                className="invLogo"
                                alt={SITE_NAME}
                                style={{ width: '160px' }}
                            />
                        </a>
                        <div className="float-right invDate">
                            <h5 className="mb-0">
                                {t('invoice')} #{invoiceDetails.cartValues.common_invoice}{' '}
                            </h5>
                            <h5 className="mb-0">
                                {t('date')}:{' '}
                                {dateFormatFunction(invoiceDetails.cartValues.cart_paiddate)}
                            </h5>
                            <h5 className="mb-0">
                                {t('status')}:{' '}
                                {invoiceDetails.cartValues.cart_paid ? t('paid') : t('unpaid')}
                            </h5>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="row mb-4">
                            <div className="col-sm-6 invAddr">
                                <h5 className="mb-3">{t('from')}:</h5>
                                <h3 className="text-dark mb-1">{t('DP_WORLD_UAE_REGION_FZE')}</h3>
                                <div>
                                    T1 Engineering Building, 1st Floor <br />
                                    Near Round About 5 Jebel Ali
                                </div>
                                {/* <div>{invoiceDetails.locationDetail.address}</div>
                            <div>
                                {invoiceDetails.locationDetail.city},{' '}
                                {invoiceDetails.locationDetail.state}{' '}
                                {invoiceDetails.locationDetail.zipCode}, United Arab Emirates
                            </div> */}
                                <div>
                                    {t('phone')} ({t('call_within_uae')}): 04 889 7585
                                </div>
                                <div>
                                    {t('phone')} ({t('international')}): +971 4 889 7585
                                </div>
                                <div>
                                    {t('TRN No')}: {allConfigurationVariables?.site_trn_no}
                                </div>
                            </div>
                            {invoiceDetails.invoiceUser?.length ? (
                                <div className="col-sm-6 invAddr text-right">
                                    <h5 className="mb-3">To:</h5>
                                    <h3 className="text-dark mb-1">
                                        {invoiceDetails.invoiceUser[0].first_name}{' '}
                                        {invoiceDetails.invoiceUser[0].last_name}
                                    </h3>
                                    <>
                                        <div>
                                            {invoiceDetails.invoiceUser[0].address1 &&
                                                invoiceDetails.invoiceUser[0].address1}
                                        </div>
                                        <div>
                                            {invoiceDetails.invoiceUser[0].address2 &&
                                                invoiceDetails.invoiceUser[0].address2}
                                        </div>
                                        {invoiceDetails.invoiceUser[0].city &&
                                            invoiceDetails.invoiceUser[0].state &&
                                            invoiceDetails.invoiceUser[0].country && (
                                                <div>
                                                    {invoiceDetails.invoiceUser[0].city},{' '}
                                                    {invoiceDetails.invoiceUser[0].state},{' '}
                                                    {invoiceDetails.invoiceUser[0].country} -{' '}
                                                    {invoiceDetails.invoiceUser[0].zip}
                                                </div>
                                            )}
                                    </>

                                    <div>Email: {invoiceDetails.invoiceUser[0].email}</div>
                                    <div>Phone: {invoiceDetails.invoiceUser[0].phone}</div>
                                    {invoiceDetails.invoiceUser[0].trn ? (
                                        <div>TRN No: {invoiceDetails.invoiceUser[0].trn}</div>
                                    ) : null}
                                </div>
                            ) : null}
                        </div>
                        {/* {invoiceDetails.cartValues.cart_payment ? (
                        <>
                            <h4 className="invoiceSubTitle">
                                Payment Type:{' '}
                                {invoiceDetails.cartValues.cart_payment === 'online' ? (
                                    <>{t('online_payment')}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'offline' ? (
                                    <>{t('offline_payment')}</>
                                ) : invoiceDetails.cartValues.cart_payment === 'deposit' ? (
                                    <>{t('deposit')}</>
                                ) : null}
                            </h4>
                        </>
                    ) : null} */}
                        {invoiceDetails.appointmentDetail ? (
                            <>
                                <h4 className="invoiceSubTitle">
                                    {t('appointment_scheduled_at')}:{' '}
                                    {dateTimeFormatFunction(
                                        invoiceDetails.appointmentDetail.appointmentTime,
                                    )}
                                </h4>
                            </>
                        ) : null}
                        {invoiceDetails.invoiceTransactions.length ? (
                            <>
                                <h4 className="invoiceSubTitle">{t('transaction_details')}</h4>
                                <div className="transDet d-flex justify-content-between flex-wrap">
                                    <>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    {/* <th scope="col">S.No</th> */}
                                                    <th scope="col">{t('transaction_id')}</th>
                                                    <th scope="col">{t('paid_on')}</th>
                                                    <th scope="col">{t('method')}</th>
                                                    <th scope="col">{t('amount')}</th>
                                                    <th scope="col">{t('status')}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {invoiceDetails.invoiceTransactions.map(
                                                    (data, index) => (
                                                        <tr key={index}>
                                                            <td data-title={t('transaction_id')}>
                                                                {data.trans_id
                                                                    ? `# ${data.trans_id}`
                                                                    : '-'}
                                                            </td>
                                                            <td data-title={t('paid_on')}>
                                                                {dateTimeFormatFunction(
                                                                    data.created_at,
                                                                )}
                                                            </td>
                                                            <td data-title={t('card')}>
                                                                {data.payment_method === 'cash' ? (
                                                                    <>{t('cash')}</>
                                                                ) : (
                                                                    <>
                                                                        {/* {data.mk_card_number} (
                                                                    {data.card_type}) */}
                                                                        {paymentTypeRosoom(
                                                                            data.card_type,
                                                                            t,
                                                                        )}
                                                                    </>
                                                                )}
                                                            </td>
                                                            <td data-title={t('amount')}>
                                                                {currencyFormat(data.amount)}
                                                            </td>
                                                            <td
                                                                data-title={t('payment_status')}
                                                                style={{
                                                                    color:
                                                                        data.status === 'success'
                                                                            ? 'green'
                                                                            : 'red',
                                                                }}
                                                            >
                                                                {capitalize(data.status)}
                                                            </td>
                                                        </tr>
                                                    ),
                                                )}
                                            </tbody>
                                        </table>
                                    </>
                                </div>
                            </>
                        ) : null}
                        <h4 className="invoiceSubTitle mt-5">{t('product_details')}</h4>

                        <div className="table-responsive">
                            {invoiceDetails.invoiceProducts.length ? (
                                <div className="cartProducts">
                                    <div>
                                        {invoiceDetails.invoiceProducts.map((data, index) => (
                                            <CartItem data={data} from="invoice" />
                                        ))}
                                    </div>
                                </div>
                            ) : null}
                        </div>
                        <div className="row mt-5">
                            <div className="col-12">
                                <div className="table-responsive">
                                    <table className="table table-clear nellisInvTot">
                                        <tbody>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">{t('items')}</span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {invoiceDetails.invoiceProducts.length}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {t('subtotal')}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.per_total,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {t('VAT')}
                                                        {invoiceDetails.invoiceProducts.length ? (
                                                            <>
                                                                {' '}
                                                                (
                                                                {
                                                                    invoiceDetails
                                                                        .invoiceProducts[0]
                                                                        .tax_percent
                                                                }
                                                                % )
                                                            </>
                                                        ) : null}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_tax,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="left" align="right">
                                                    <span className="text-dark">
                                                        {t('total_amount')}
                                                    </span>
                                                </td>
                                                <td className="right">
                                                    <span className="text-dark">
                                                        {currencyFormat(
                                                            invoiceDetails.cartValues.total_amount,
                                                        )}
                                                    </span>
                                                </td>
                                            </tr>
                                            {invoiceDetails.cartValues.total_deposit ? (
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('bid_deposit')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            -{' '}
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_deposit,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : null}

                                            {invoiceDetails.cartValues.total_paid ? (
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('total_paid')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_paid,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : null}

                                            {invoiceDetails.cartValues.total_due_amount ? (
                                                <tr>
                                                    <td className="left" align="right">
                                                        <span className="text-dark">
                                                            {t('pending_amount')}
                                                        </span>
                                                    </td>
                                                    <td className="right">
                                                        <span className="text-dark">
                                                            {currencyFormat(
                                                                invoiceDetails.cartValues
                                                                    .total_due_amount,
                                                            )}
                                                        </span>
                                                    </td>
                                                </tr>
                                            ) : null}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h4 className="thankText">{t('thank_you_for_shopping_with_us')}</h4>
            </div>
        </FullScreenPopup>
    )
}

export default InvoiceView
