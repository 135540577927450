import React, { useContext, useState, useEffect, useRef } from 'react'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { Button } from '@material-ui/core'
import { mapData } from '../../product/common/components'
import { useFormik } from 'formik'
import CustomCommonContext from '../../custom/context/common/commonContext'
import CustomDialog from '../../product/components/organisms/Dialog'

import * as Yup from 'yup'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SettingContext from '../../product/context/setting/settingContext'

const VatCategory = () => {
    const settingcontext = useContext(SettingContext)
    const { allVatCategory, getGlobalVariable } = useContext(CustomCommonContext)

    const {
        searchTableAction,
        responseStatus: responseStatusSetting,
        clearResponse: clearResponseCommuncation,
    } = settingcontext

    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            active: 0,
            id: 0,
            table: 'vat_registration',
        },
        onSubmit: (values) => {
            const dataValue = values
            if (
                dataValue.action === 'active' ||
                dataValue.action === 'deactive' ||
                dataValue.action === 'delete'
            ) {
                dataValue.active =
                    dataValue.action === 'delete' ? 2 : dataValue.action === 'active' ? 1 : 0
                delete dataValue.action
                searchTableAction(values)
            }
        },
    })
    const validationArray = Yup.object({
        description: Yup.string().required('Required!'),
    })

    const formikView = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            searchterm: '',
        },
        onSubmit: (values) => {
            let filteredValues = allVatCategory
            if (values.searchterm && values.searchterm != '') {
                filteredValues = filteredValues.filter(
                    (filter) =>
                        filter.description
                            .toLowerCase()
                            .indexOf(values.searchterm.toLowerCase()) !== -1,
                )
            }
            setTableBody(filteredValues.length ? filteredValues : [])
            formikView.values.limit = filteredValues.length
        },
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            description: '',
            table: 'vat_registration',
            active: 1,
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            console.log('+++++++++++++', values)
            searchTableAction(values)
        },
    })

    const changePagination = () => {}

    useEffect(() => {
        if (allVatCategory) {
            setTableBody(allVatCategory.length ? allVatCategory : [])
            formikView.values.limit = allVatCategory.length
        }
    }, [allVatCategory])

    useEffect(() => {
        if (responseStatusSetting) {
            if (
                responseStatusSetting.from === 'CommunicationSearchTable' &&
                responseStatusSetting.status === 'success'
            ) {
                setChangeStatus(false)
                toggleFullScreenPopup(false)
                setTableBody([])
                getGlobalVariable()
            }
        }
    }, [responseStatusSetting])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    useEffect(() => {
        console.log('manage', manage)
        if (manage.status === 'edit') {
            formik.setFieldValue('id', manage.id)
            const template = allVatCategory.filter((cat) => cat.id === manage.id)
            formik.setFieldValue('description', template[0].description)
            formik.setFieldValue('active', template[0].active)
        } else {
            resetFrom()
        }
    }, [manage])

    const resetFrom = () => {
        console.log('resetForm')
        formik.setFieldValue('id', 0)
        formik.setFieldValue('description', '')
        formik.setFieldValue('table', 'vat_registration')
        formik.handleReset()
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'description',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Vat name',
        },
        {
            field: 'active',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'active',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Active',
                },
                false: {
                    type: 'text',
                    value: 'Deactive',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit vat registration value',
        },
    ]
    const details = [
        {
            label: 'Vat name *',
            placeholder: 'Enter the vat name',
            type: 'textarea',
            class: 'col-6',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Active',
            placeholder: 'Make Active',
            type: 'switch',
            class: 'col-6',
            name: 'active',
            formik: formik,
        },
    ]

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.id = data
        if (action === 'active' || action === 'deactive' || action === 'delete') {
            setChangeStatus(true)
        }
    }

    const tableActions = []
    tableActions.push(
        {
            label: 'Move to Active',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        },
        {
            label: 'Move to Deactive',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactive',
        },
        {
            label: 'Delete Permanently',
            icon: <span className="material-icons">cancel</span>,
            onclick: onSelectMultiProducts,
            type: 'delete',
        },
    )

    const searchInfo = [
        {
            label: 'Vat name',
            placeholder: 'Enter vat name',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formikView,
        },
    ]

    return (
        <Layout>
            <div className="dashboard staticPages">
                <CustomSearch searchInfo={searchInfo} formik={formikView} />
                <div className="d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New Vat
                    </Button>
                </div>

                <CustomTable
                    formik={formikView.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={{
                        records: allVatCategory,
                        setDisp: `0-${allVatCategory.length}`,
                        totalRecords: allVatCategory.length,
                    }}
                    changeTableFunction={changePagination}
                />
            </div>
            <FullScreenPopup
                modaltitle={manage.status === 'new' ? 'Add New Vat' : 'Edit Vat'}
                open={manage.popup}
                handleClose={() => toggleFullScreenPopup(false, 'new', 0)}
            >
                <div className="dashboard addNewListing mt-4">
                    <div className="fspBody">
                        <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                            <div className="row">{Object.values(mapData(details))}</div>
                            <div className="actionButton d-flex justify-content-center align-items-center">
                                <SecondaryButton
                                    label="Reset"
                                    type="reset"
                                    onClick={() => resetFrom()}
                                />
                                <PrimaryButton type="submit" label="Submit" />
                            </div>
                        </form>
                    </div>
                </div>
            </FullScreenPopup>

            <CustomDialog title={'Change status'} open={changeStatus} function={changeChangeStatus}>
                <h5>
                    {formikSelection.values.action === 'delete'
                        ? 'Are you sure you want to delete'
                        : 'Are you sure you want to change the status'}
                </h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form onSubmit={formikSelection.handleSubmit} autoComplete="nofill">
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}
export default VatCategory
